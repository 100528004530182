import request from "../utils/request";

const getSelectorColumnSortId = (sortId) => {
  switch (sortId) {
    default:
      return sortId;
  }
};

export const fetchCampaignsByType = async ({
  campaignType: type,
  limit = 20,
  pageIndex = 0,
  sortBy,
}) => {
  const offset = limit * pageIndex;

  let params = {
    limit,
    offset,
  };

  if (sortBy && sortBy.length > 0) {
    params = {
      ...params,
      ordering: `${sortBy[0].desc ? "-" : ""}${getSelectorColumnSortId(
        sortBy[0].id
      )}`,
    };
  }

  const campaigns = await request({
    url: `campaigns/${type}/`,
    params,
  });

  let demoCampaigns = { data: [] };
  if (pageIndex === 0) {
    demoCampaigns = await request({
      url: `campaigns/demo-${type}/`,
    });
  }

  return {
    data: {
      ...campaigns.data,
      pageIndex,
      sortBy,
      results: [...demoCampaigns.data, ...campaigns.data.results],
    },
  };
};

export const fetchCampaigns = () =>
  request({
    url: "/api/v1/campaigns/get/",
    method: "get",
  });

export const fetchCampaign = (id) =>
  request({
    url: `campaigns/home-sellers/${id}/`,
    method: "get",
  });

export const fetchDemoCampaign = (id) =>
  request({
    url: `campaigns/demo-home-sellers/${id}/`,
    method: "get",
  });

export const createCampaign = (data) =>
  request({
    url: "/campaigns/home-sellers/",
    method: "post",
    data,
  });

export const addValue = (data) =>
  request({
    url: "/api/v1/home-sellers/add-value",
    method: "post",
    data,
  });

export const updateCampaign = ({ id, ...formData }) =>
  request({
    url: formData.isDemo
      ? `campaigns/demo-home-sellers/${id}/`
      : `campaigns/home-sellers/${id}/`,
    method: "put",
    data: formData,
  });

export const updateCampaignStatus = ({ status, id }) =>
  request({
    url: `campaigns/home-sellers/${id}/`,
    method: "patch",
    data: status,
  });

export const fetchZipCodes = (city, state) =>
  request({
    url: `zip-codes/?city=${city}&state=${state}`,
    method: "get",
  });
