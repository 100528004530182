/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useState, useEffect, useMemo } from "react";
import {
  Button,
  Layout,
  Form,
  Input,
  Typography,
  Space,
  Collapse,
  Alert,
  Spin,
} from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";
import PhoneNumberInput from "../../../component/PhoneNumberInput";

import styles from "./SignUp.m.less";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignupMutation } from "@queries";
import { setToken } from "@store/authSlice";
import { ErrorAlert } from "@components";

const { Text, Link } = Typography;
const { Panel } = Collapse;

interface SignupExtraErrorContext {
  phonenumberAlreadyAssociated: boolean;
  emailAlreadyAssociated: boolean;
}

const SignUp: FC = () => {
  const { Content } = Layout;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get referral code from url search params
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const referralCodeFromLink = searchParams.get("code");

  const [
    signUpV2,
    { data: signUpResponse, isLoading, isSuccess, isError, error },
  ] = useSignupMutation();

  const [alreadyAssociatedError, setAlreadyAssociatedError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorField, setErrorField] = useState("");

  const handleSubmit = useCallback((values) => {
    setErrorField("");
    setErrorMsg("");
    setAlreadyAssociatedError("");
    const [firstName, lastName] = values["name"].split(/\s/);

    signUpV2({
      firstName: firstName,
      lastName: lastName,
      email: values.email,
      password: values.password,
      phonenumber: values["phonenumber"],
      referralCode: referralCodeFromLink || values["referralCode"],
      fromLink: !!referralCodeFromLink,
    })
      .unwrap()
      .then((payload) => {
        dispatch(setToken({ token: payload?.access }));
        navigate("/signup/verify-phonenumber");
      });
  }, []);

  useEffect(() => {
    if (isError) {
      console.log("Signup Error response: ", error);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error?.data?.password) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setErrorMsg(error?.data?.password?.[0]);
        setErrorField("password");
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error?.data?.nonFieldErrors) {
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error?.data?.extraContext?.emailAlreadyAssociated
        ) {
          setAlreadyAssociatedError("email");
        }
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error?.data?.extraContext?.phonenumberAlreadyAssociated
        ) {
          setAlreadyAssociatedError("phonenumber");
        }
      }
    }
  }, [isError, error]);

  return (
    <OnboardingLayout title={"Create Your Account"} step={1}>
      <Content className={styles.viewContainer}>
        <SignUpForm
          form={form}
          errorMsg={errorMsg}
          errorField={errorField}
          typeAlreadyAssociated={alreadyAssociatedError}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          referralCodeFromLink={referralCodeFromLink}
        />
      </Content>
    </OnboardingLayout>
  );
};

interface SignUpFormProps {
  form: any;
  errorField: string;
  errorMsg: string;
  typeAlreadyAssociated: string;
  isLoading: boolean;
  onSubmit: (values: any) => void;
  referralCodeFromLink: string | null;
}

const SignUpForm = ({
  form,
  errorField,
  errorMsg,
  typeAlreadyAssociated,
  isLoading,
  onSubmit,
  referralCodeFromLink,
}: SignUpFormProps) => {
  const alreadyAssociatedMessage = `Sorry, this ${typeAlreadyAssociated} is already being used.`;
  return (
    <>
      <Form
        key="a"
        form={form}
        layout="vertical"
        name="signUpForm"
        requiredMark={"optional"}
        onFinish={onSubmit}
        className={"form"}
      >
        {typeAlreadyAssociated && (
          <Alert
            message={alreadyAssociatedMessage}
            closable
            type="error"
            action={
              <Link style={{ color: "black" }} href="/login" underline>
                Sign In
              </Link>
            }
            banner
            className="align-items-start"
          />
        )}
        {errorMsg && <ErrorAlert message={errorMsg} />}
        <Form.Item
          key="a"
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              type: "string",
              validator: (_, value) => {
                const names = value?.split(/\s/);
                if (value && names.length === 2 && names[1] !== "") {
                  return value.match(/^[\w\s'-]+$/)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "Only letters, numbers, dashes, and apostraphes are permitted"
                        )
                      );
                }
                return Promise.reject(
                  new Error("Please enter your first and last name")
                );
              },
            },
          ]}
        >
          <Input placeholder={"Alex Smith"} />
        </Form.Item>
        <Form.Item
          key="b"
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email" },
          ]}
          {...(errorField == "email" && {
            validateStatus: "error",
          })}
        >
          <Input placeholder={"example@email.com"} />
        </Form.Item>
        <Form.Item
          key="c"
          name="phonenumber"
          label="Phone Number"
          rules={[
            { required: true, message: "Please input your phone number" },
          ]}
          {...(errorField == "phone number" && {
            validateStatus: "error",
          })}
        >
          <PhoneNumberInput
            style={{ width: "100%" }}
            placeholder={"( - - - ) - - -  - - - -"}
          />
        </Form.Item>
        <Form.Item
          key="d"
          name="password"
          label="Password"
          rules={[{ required: true }]}
          {...(errorField == "password" && {
            validateStatus: "error",
          })}
        >
          <Input.Password />
        </Form.Item>
        {!referralCodeFromLink && (
          <Collapse ghost expandIcon={() => null}>
            <Panel
              header="Have a referral code?"
              key="1"
              className="text-right referralCode"
            >
              <Form.Item key="d" name="referralCode" label="Referral Code">
                <Input />
              </Form.Item>
            </Panel>
          </Collapse>
        )}
        <Form.Item key="e" className={styles.controlContainer}>
          <Spin spinning={isLoading}>
            <Button type="primary" htmlType="submit" block>
              Continue
            </Button>
          </Spin>
        </Form.Item>
      </Form>
      <Space key="b" direction="vertical" size="small" align="center" wrap>
        <Text strong>
          Already have an account? <Link href="/login">Sign In instead</Link>
        </Text>
        <Text strong>
          By continuing you agree to our{" "}
          <Link href="/terms-of-use" target="_blank">
            Terms and Conditions
          </Link>
          {" and "}
          <Link href="/privacy-policy" target="_blank">
            Privacy Policies
          </Link>
        </Text>
      </Space>
    </>
  );
};

export default SignUp;
