import React, { FC } from "react";

const PrivacyPolicy: FC = () => {
  return (
    <div className="max-w-5xl p-4 space-y-4">
      <h1 className="pb-4">Privacy Policy</h1>
      <p className="c0">
        <span className="c7 c10">Last Updated: September 12, 2019</span>
      </p>
      <p className="c0">
        <span className="c7">
          AgentsCorner takes your privacy seriously. Please read the policy
          below to learn more about our privacy policy. By visiting{" "}
        </span>
        <span className="c21">
          <a
            className="c5"
            href="https://www.google.com/url?q=http://www.agentscorner.com&amp;sa=D&amp;ust=1571850702187000"
          >
            www.agentscorner.com
          </a>
        </span>
        <span className="c7 c10">
          , affiliated pages or downloading the mobile application, you are
          accepting the practices described in this privacy notice. What this
          privacy notice covers.{" "}
        </span>
      </p>
      <ul className="c15 lst-kix_list_4-0 start">
        <li className="c0 c3">
          <span className="c7">
            This policy covers how we treat personal information that the
            website collects and receives, including information related to
            you&rsquo;re past use of our products and services. Personal
            information about you that is personally identifiable like your
            name, address, email address,IP address or phone number, and that is
            not otherwise publicly available.{" "}
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            This policy does not apply to the practices of companies that we do
            not own or control, or people that we do not employ or manage.{" "}
          </span>
        </li>
      </ul>
      <h2 className="mt-4" id="h.gjdgxs">
        <span className="c9">Information Collection and use </span>
      </h2>
      <p className="c0 c2">
        <span className="c10 c18"></span>
      </p>
      <ul className="space-y-2">
        <li className="c3 c20">
          <span className="c4">
            The website collects personal information when you register with us.
            We may combine information about you that we have with information
            we obtain from business partners or other companies.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c4">
            When you register, we ask for information such as your name,
            address, phone number, email address and zip code. Once you register
            with us and sign in to our services, you are not anonymous to us.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c4">
            We collect information about your transactions with us and with some
            of our business partners, including information about your use of
            financial products and services that we offer.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c4">
            The website automatically receives and records information on our
            server logs from your browser, including your IP address, cookie
            information, and the page you requested.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c4">
            We use information for the following general purposes: to fulfill
            your requests for products and services, improve our services,
            contact you, conduct research, and provide anonymous reporting for
            internal and external clients.
          </span>
        </li>
        <li className="c3 c13">
          <span className="c4">
            We reserve the right to send you certain communications relating to
            our services, such as service announcements, administrative
            messages, special offers and Newsletters.
          </span>
        </li>
      </ul>

      <p className="c0">
        <span className="c6">Confidentiality and Security</span>
      </p>

      <p className="c0">
        <span className="c7 c10">
          We collect and store the information you provide directly to us, such
          as when you use the Services to compare the relevance of agents in
          your area of interest, request that we contact you to match you with
          an agent, provide feedback and rate your experiences using the
          Services, sign up for our mailing list, or when you call, email or
          otherwise communicate with us. The types of information we may collect
          include:
        </span>
      </p>
      <ul className="space-y-2">
        <li className="c0 c3">
          <span className="c7">
            Basic user information, such as your name, email address, phone
            number, whether you are buying or selling a home, and the best time
            to contact you; and
          </span>
        </li>
        <li className="c12 c3">
          <span className="c7">
            Home information, such as the address, type of home, and price range
            of the home you are interested in buying or selling.
          </span>
        </li>
      </ul>
      <h2 className="mt-4" id="h.30j0zll">
        <span className="c9">Information We Collect Automatically</span>
      </h2>
      <p className="c0">
        <span className="c7 c10">
          When you access or use our Services, AgentsCorner may automatically
          collect information about you, including:
        </span>
      </p>
      <ul className="space-y-2">
        <li className="c0 c3">
          <span className="c7">
            Log Information. We automatically log information when you visit our
            websites, including your IP address, access times, operating system,
            browser type and language, Internet service provider (ISP), and the
            web pages that you visit.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            We may combine this automatically collected log information with
            other information we collect about you. We do this to improve the
            services we offer you, to improve marketing, analytics, or site
            functionality.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            Technologies such as: cookies, beacons, tags and scripts are used by
            AgentsCorner and our marketing partners and analytics or service
            providers. These technologies are used in analyzing trends,
            administering the site, tracking users&rsquo; movements around the
            site anonymously and to gather demographic information about our
            user base as a whole. We may receive reports based on the use of
            these technologies by these companies on an aggregated basis.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            We use cookies to remember user preferences and originating partner
            source. Users can control the use of cookies at the individual
            browser level. If you reject cookies, you may still use our site,
            but your ability to use some features or areas of our site may be
            limited.
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            LSOs. We use local storage objects such as HTML5 to store content
            information and preferences to make web pages render more quickly
            and speed up your website experience.
          </span>
        </li>
        <li className="c3 c12">
          <span className="c7">
            Third Parties, with whom we partner to provide certain features on
            our site or to display advertising based upon your Web browsing
            activity, use LSOs such as HTML5 to collect and store information.
          </span>
        </li>
      </ul>
      <h2 className="mt-4" id="h.1fob9te">
        <span className="c9">Use of Information</span>
      </h2>
      <p className="c0">
        <span className="c7 c10">
          We use information collected through our Services for the purposes
          described in this Policy or as otherwise disclosed to you in
          connection with our Services. For example, we may use your information
          to:
        </span>
      </p>
      <ul className="space-y-2">
        <li className="c0 c3">
          <span className="c7">
            Provide, maintain and improve our Services, and deliver the
            information you request;
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            Respond to your comments and questions, and provide customer
            service;
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            Communicate with you about our Services and any other events, news
            or information about AgentsCorner and our select partners;
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            Monitor and analyze trends, usage, and activities in connection with
            our Services;
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            Personalize and improve the Services and provide content, features,
            or advertisements that match your interests and preferences; and
          </span>
        </li>
        <li className="c12 c3">
          <span className="c7">
            Link or combine with other information we get from third parties to
            help understand your needs and provide you with better service.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c7 c10">
          AgentsCorner may store and process your information in the United
          States and in other countries.
        </span>
      </p>
      <h2 className="mt-4" id="h.3znysh7">
        <span className="c9">Disclosure of Information</span>
      </h2>
      <p className="c0">
        <span className="c7">
          We will share your personal information with third parties only in the
          ways that are described in this privacy policy.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c7 c10">
          We may share your information as follows:
        </span>
      </p>
      <ul className="space-y-2">
        <li className="c0 c3">
          <span className="c7">
            With third party vendors, consultants and other service providers
            who need access to information about you in order to carry out their
            work for us. These companies are authorized to use your personal
            information only as necessary to provide these services to us;
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            If we believe that disclosure is reasonably necessary to comply with
            any applicable law, regulation, legal process or governmental
            request; to enforce our agreements, policies and Terms of Use; to
            protect the security and integrity of our Services; or to protect
            AgentsCorner, our users or the public from harm or illegal
            activities;
          </span>
        </li>
        <li className="c0 c3">
          <span className="c7">
            In connection with, or during negotiations of, any merger, sale of
            company assets, financing or acquisition of some or all of our
            business to another company. You will be notified via email and/or
            notice through the website of any such change in ownership or uses
            of your personal information, as well as any choices you may have
            regarding your personal information; and
          </span>
        </li>
        <li className="c12 c3">
          <span className="c7">
            With your consent, including when you direct AgentsCorner to share
            information about you with agents or other third parties.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c7 c10">
          We may also share aggregated or anonymized information that does not
          directly identify you.
        </span>
      </p>
      <h2 className="mt-4" id="h.2et92p0">
        <span className="c9">Behavioral Targeting</span>
      </h2>
      <p className="c0">
        <span className="c7">
          We partner with a third party to either display advertising on our web
          site or to manage our advertising on other sites. Our third party
          partners may use technologies such as cookies to gather information
          about your activities on this site and other sites in order to provide
          you advertising based upon your browsing activities and interests. If
          you wish to not have this information used for the purpose of serving
          you interest-based ads, you may opt-out by clicking{" "}
        </span>
        <span className="c19">Here (We need to add a link)</span>
        <span className="c7 c10">
          . Please note this does not opt you out of being served ads. You will
          continue to receive generic ads.
        </span>
      </p>
      <h2 className="mt-4" id="h.tyjcwt">
        <span className="c9">Links to 3rd Party Sites</span>
      </h2>
      <p className="c0">
        <span className="c7 c10">
          Our Site includes links to other Web sites whose privacy practices may
          differ from those of AgentsCorner. If you submit personal information
          to any of those sites, your information is governed by their privacy
          policies. We encourage you to carefully read the privacy policy of any
          Web site you visit.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c7 c10"></span>
      </p>
      <h2 className="mt-4" id="h.3dy6vkm">
        <span className="c9">Social Media Widgets</span>
      </h2>
      <p className="c0">
        <span className="c7 c10">
          Our Web site includes Social Media Features, such as the Facebook Like
          button, the Twitter Tweet button and other social sharing buttons or
          interactive mini-programs that run on our site. These Features may
          collect your IP address, which page you are visiting on our site, and
          may set a cookie to enable the Feature to function properly. Social
          Media Features and Widgets are either hosted by a third party or
          hosted directly on our Site. Your interactions with these Features are
          governed by the privacy policy of the company providing it.
        </span>
      </p>
      <h2 className="mt-4" id="h.1t3h5sf">
        <span className="c9">Security</span>
      </h2>
      <p className="c0">
        <span className="c7 c10">
          The security of your personal information is important to us, thus
          AgentsCorner has taken reasonable steps to help protect the personal
          information our users share with us in an effort to prevent loss,
          misuse, and unauthorized access, disclosure, alteration, and
          destruction.
        </span>
      </p>
      <p className="c0">
        <span className="c7">
          We follow generally accepted standards to protect the personal
          information submitted to us, both during transmission and once we
          receive it. No method of transmission over the Internet, or method of
          electronic storage, is 100% secure, however. Therefore, we cannot
          guarantee its absolute security. If you have questions about security
          on our Website, you can contact us at{" "}
        </span>
        <span className="c16">support@agentscorner.com</span>
        <span className="c7 c10">.</span>
      </p>
      <h2 className="mt-4" id="h.4d34og8">
        <span className="c9">Changes to the Policy</span>
      </h2>
      <p className="c0">
        <span className="c7 c10">
          From time to time, AgentsCorner may revise this Policy. We encourage
          you to review the Policy whenever you access the Services to make sure
          that you understand our information collection, use, and disclosure
          practices.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c7 c10"></span>
      </p>
      <h2 className="mt-4" id="h.2s8eyo1">
        <span className="c9">Contact Us</span>
      </h2>
      <p className="c0">
        <span className="c7 c10">
          If users have any questions or suggestions regarding the company
          privacy policy, please contact company at:
        </span>
      </p>
      <p className="c0">
        <span className="c10 c11">AgentsCorner LLC</span>
      </p>
      <p className="c0">
        <span className="c11 c10">4305 Windsor Centre Trail. Suite 300</span>
      </p>
      <p className="c0">
        <span className="c11 c10">Flower Mound, Texas 75028</span>
      </p>
      <p className="c0">
        <span className="c10 c16">support@agentscorner.com</span>
      </p>
      <p className="c0 c2">
        <span className="c18 c10"></span>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
