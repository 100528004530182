import React from "react";
import { Menu, Button, Spin } from "antd";
import { WarningOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { FAQ_URL } from "@constants";
import { useGetAgentQuery } from "@queries";
import { useFeatureSpec } from "@queries/select";
import { ACCOUNT_AVATAR } from "@images";

const { SubMenu } = Menu;

export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { data: agent, isLoading, error } = useGetAgentQuery();
  const isSignupComplete = agent?.isSignupFlowComplete;
  const balance = agent?.balance;
  const name = agent?.user?.firstName;
  const profilePhoto = agent?.photo;
  const identicon = `https://avatars.dicebear.com/api/gridy/${name}.svg`; // Identicon from dicebears

  const {
    campaignsEnabled,
    monthlyBudgetCampaignsEnabled,
    agentsBalanceVisible,
    agentsAddFundsVisible,
  } = useFeatureSpec();

  return (
    <Menu mode="horizontal" className="container mx-auto font-bold max-w-7xl">
      <Menu.Item key="home">
        <Link to="/">
          <img
            src="/images/Agents_Corner_Icon.png"
            width="43.1"
            height="42"
            alt="icon logo"
          />
        </Link>
      </Menu.Item>

      <Menu.Item key="dashboard">
        <Link to="/">Dashboard</Link>
      </Menu.Item>

      <Menu.Item key="contacts">
        <Link to="/contacts">Contacts</Link>
      </Menu.Item>

      {campaignsEnabled && (
        <Menu.Item key="campaigns">
          <Link to="/campaigns">Campaigns</Link>
        </Menu.Item>
      )}

      {monthlyBudgetCampaignsEnabled && (
        <Menu.Item key="subscription-campaign">
          <Link to="/subscription-campaign">Campaign</Link>
        </Menu.Item>
      )}

      <SubMenu
        key="account"
        title={name}
        className="ml-auto mr-0"
        icon={
          profilePhoto ? (
            <img
              className="rounded-full"
              id="h-mini-avatar"
              src={profilePhoto || ACCOUNT_AVATAR}
              width="35"
              height="35"
              alt="avatar"
            />
          ) : (
            <UserOutlined style={{ fontSize: 28 }} />
          )
        }
      >
        {isSignupComplete && agentsBalanceVisible && (
          <Menu.Item key="balance">
            <Link to="/deposit">
              My Balance{" "}
              {error ? (
                <WarningOutlined />
              ) : isLoading ? (
                <Spin spinning />
              ) : (
                `$${balance}`
              )}
            </Link>
          </Menu.Item>
        )}

        {isSignupComplete && agentsAddFundsVisible && (
          <Menu.Item key="deposit">
            <Link to="/deposit">
              <Button type="primary" block>
                Deposit Funds
              </Button>
            </Link>
          </Menu.Item>
        )}

        <hr />
        {isSignupComplete && (
          <Menu.Item key="settings">
            <Link to="/settings">Settings</Link>
          </Menu.Item>
        )}
        <Menu.Item key="help">
          <a href={FAQ_URL} target="_blank" rel="noreferrer">
            Help
          </a>
        </Menu.Item>
        <hr />
        <Menu.Item
          key="logout"
          onClick={() => {
            navigate("/logout");
          }}
        >
          Logout
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};
