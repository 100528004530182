import React from "react";
import { Row, Col, Alert, Button, Layout, Spin, Result } from "antd";
import { RightOutlined, AreaChartOutlined } from "@ant-design/icons";
import { RecentContact, DashboardPanel, BlogWidget } from "./components";
import {
  useGetContactsQuery,
  ContactsOrdering,
  useGetCampaigns,
  useSuccessManagerQuery,
} from "@queries";
import { useFeatureSpec } from "@queries/select";
import { Link } from "react-router-dom";

import { ASMDashboardBlock, ASMWidget, CampaignStatTotals } from "@components";
import { ReferralDashboardWidget } from "@containers";
import SignupProgressBanner from "src/v2/component/SignupProgressBanner";
import { useScreenSize } from "@utils/hooks";

const { Content } = Layout;

export const DashboardPage = () => {
  const { data, error, isLoading, isFetching } = useGetContactsQuery({
    limit: 20,
    offset: 0,
    ordering: ContactsOrdering.newest,
  });
  const {
    data: successManager,
    isLoading: isLoadingASM,
    error: isErrorASM,
  } = useSuccessManagerQuery();
  const { campaignsEnabled } = useFeatureSpec();

  const { campaignStatTotals } = useGetCampaigns();

  const contacts = data?.results;
  const hasContacts = data?.count && data?.count > 0;

  const { isSmallScreen } = useScreenSize();

  return (
    <Content className="text-base">
      <Row
        className="container py-8 max-w-7xl"
        style={{ marginLeft: "auto", marginRight: "auto" }}
        gutter={{ xs: 0, lg: 16 }}
      >
        <Col xs={24} lg={16}>
          <div className="max-w-3xl mx-auto mt-4 space-y-4 lg:ml-0">
            <SignupProgressBanner />
            {hasContacts ? (
              <div className="panel">
                <Row justify="space-between">
                  <h2 className="mb-6">Most Recent</h2>
                  <Link to="/contacts">
                    <Button type="text" size="small" className="float-right">
                      Go to Contacts <RightOutlined />
                    </Button>
                  </Link>
                </Row>
                <div className="space-y-2">
                  {contacts?.slice(0, 3).map((contact) => (
                    <RecentContact key={contact?.id} contact={contact} />
                  ))}
                </div>
              </div>
            ) : null}
            <DashboardPanel title="Campaigns">
              <Row className="mb-4">
                {campaignsEnabled && (
                  <Col xs={24} md={8} className="space-y-4">
                    <CampaignStatTotals data={campaignStatTotals} />
                  </Col>
                )}
                <Col xs={24} md={16}>
                  {/* <LeadsChart data={leadsData} /> */}
                </Col>
              </Row>
              <Alert
                type="success"
                showIcon
                className="leading-4 text-white border-0 bg-green"
                icon={
                  <span className=" material-icons" style={{ color: "white" }}>
                    insights
                  </span>
                }
                closeText={
                  <Link
                    className="p-2 text-base text-white rounded-full hover:text-white hover:bg-opacity-20 hover:bg-white"
                    to={
                      campaignsEnabled ? "/campaigns" : "/subscription-campaign"
                    }
                  >
                    Manage campaigns
                    <RightOutlined />
                  </Link>
                }
                message={<div className="text-white">Need more leads?</div>}
                description="Increase your bid or expand your region to win more leads."
              />
            </DashboardPanel>
            <ReferralDashboardWidget />
          </div>
        </Col>

        <Col xs={24} lg={8} className="max-w-3xl mx-auto mt-4 space-y-4">
          {isErrorASM ? (
            <div className="panel">
              <Result
                status="warning"
                title="Error retrieving your success manager."
              />
            </div>
          ) : isLoadingASM || !successManager ? (
            <div className="flex panel">
              <Spin spinning className="py-4 mx-auto" />
            </div>
          ) : isSmallScreen ? (
            <ASMDashboardBlock {...successManager} />
          ) : (
            <ASMWidget {...successManager} />
          )}

          <BlogWidget />
        </Col>
      </Row>
    </Content>
  );
};
