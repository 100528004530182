import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

interface PanelStepProps {
  key: string | number;
  title: string;
  headerContent?: React.ReactNode;
  isCurrentStep: boolean;
}
export const PanelStep: React.FC<PanelStepProps> = ({
  key,
  title,
  headerContent,
  isCurrentStep,
  children,
}) => {
  const PanelHeader: React.FC = ({ children }) => {
    const containerClasses = "p-4 mt-4 rounded-t-lg ";
    const titleClasses = "text-lg font-semibold ";
    return (
      <div
        className={
          isCurrentStep
            ? containerClasses + "bg-blue"
            : containerClasses +
              "border-2 border-gray-300 bg-gray-200 rounded-b-lg"
        }
      >
        <div
          className={
            isCurrentStep ? titleClasses + " text-white" : titleClasses
          }
        >
          {title}
        </div>
        <hr className="my-2" />
        {children}
      </div>
    );
  };

  const PanelContent: React.FC = ({ children }) => {
    const defaultClasses = "p-4 border-2 rounded-b-lg ";
    return (
      <div
        className={
          isCurrentStep
            ? defaultClasses + "border-blue"
            : defaultClasses + "border-gray-400"
        }
      >
        {children}
      </div>
    );
  };

  return (
    <Panel
      key={key}
      header={<PanelHeader>{headerContent}</PanelHeader>}
      showArrow={false}
    >
      {/* <PanelContent>{children}</PanelContent> */}
      {/* <h1>hi</h1> */}
      {children}
    </Panel>
  );
};
