import React from "react";
import IntegrationsInfo from "./components/IntegrationsInfo";
import IntegrationsTable from "./components/IntegrationsTable";
import { Button, Form, Input, Spin, Switch, Typography } from "antd";
import {
  useWebhookIntegrationsQuery,
  useUpdateIntegrationMutation,
  useAddIntegrationMutation,
  useDeleteIntegrationMutation,
} from "@queries";

const { Text, Title } = Typography;

export const Integrations: React.FC = () => {
  const [form] = Form.useForm();
  const { data: integrations, isLoading } = useWebhookIntegrationsQuery();
  const [editIntegration] = useUpdateIntegrationMutation();
  const [deleteIntegration] = useDeleteIntegrationMutation();
  const [addIntegration] = useAddIntegrationMutation();

  const handleAddIntegration = (values: {
    url: string;
    name: string;
    active: boolean;
  }) => {
    addIntegration(values);
  };

  const handleEditIntegration = ({
    id,
    url,
    name,
  }: {
    id: number;
    url: string;
    name: string;
  }) => {
    editIntegration({ integrationId: id, url, name });
  };

  const handleDeleteIntegration = ({ id }: { id: number }) => {
    deleteIntegration(id);
  };

  const handleUpdateIntegrationStatus = ({
    id,
    active,
  }: {
    id: number;
    active: boolean;
  }) => {
    editIntegration({ integrationId: id, active });
  };

  return (
    <div className="p-4 space-y-8">
      <IntegrationsInfo />

      {integrations?.length ? (
        <>
          <Title level={5}>Your current integrations</Title>
          <IntegrationsTable
            webhooks={integrations}
            onEdit={handleEditIntegration}
            onDelete={handleDeleteIntegration}
            onUpdateStatus={handleUpdateIntegrationStatus}
          />
        </>
      ) : null}

      <div className="panel">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddIntegration}
          requiredMark={false}
          initialValues={{ active: true }}
        >
          <Form.Item
            name="url"
            label="Paste webhook here"
            wrapperCol={{ xs: 24, md: 18 }}
            rules={[
              {
                type: "url",
                required: true,
                message: "Please enter a valid url",
              },
              {
                max: 4095,
                message: "Max length is 4095",
              },
            ]}
          >
            <Input placeholder="https://webhook.com/will_look_somethinglikethis" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            wrapperCol={{ xs: 24, md: 8 }}
            rules={[
              {
                type: "string",
                required: true,
                message: "Please name your webhook",
              },
              {
                min: 1,
                max: 40,
                message: "Webhook name must be between 1 and 40 characters",
              },
            ]}
          >
            <Input placeholder="My Amazing Webhook" />
          </Form.Item>
          <div className="mb-1">
            <Form.Item name="active" noStyle>
              <Switch defaultChecked />
            </Form.Item>
            <Text strong style={{ marginLeft: 8 }}>
              Activate
            </Text>
          </div>
          <Spin spinning={isLoading}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="block px-2 mx-auto"
            >
              Save
            </Button>
          </Spin>
        </Form>
      </div>
    </div>
  );
};
