import React, { useCallback, useState } from "react";
import { Table, Form } from "antd";
import { Switch, Button, Popconfirm, Input } from "antd";
import ConfirmActiveModal from "./ConfirmActiveModal";
import { DeleteFilled } from "@ant-design/icons";
import { Webhook } from "../../../../../types";

interface Integrations {
  webhooks: Webhook[];
  onEdit: ({
    id,
    url,
    name,
  }: {
    id: number;
    url: string;
    name: string;
  }) => void;
  onDelete: ({ id }: { id: number }) => void;
  onUpdateStatus: ({
    id,
    url,
    active,
  }: {
    id: number;
    url: string;
    active: boolean;
  }) => void;
}

const IntegrationsTable: React.FC<Integrations> = ({
  webhooks,
  onEdit,
  onDelete,
  onUpdateStatus,
}) => {
  const [form] = Form.useForm();
  const handleUpdateIntegrationStatus = () => {
    const selectedWebhook = webhooks?.find(
      (webhook) => webhook.id === changeStatusId
    );

    if (!selectedWebhook) {
      console.error("No selected webhook");
      return;
    }
    onUpdateStatus({
      id: selectedWebhook?.id,
      url: selectedWebhook?.url,
      active: !selectedWebhook?.active,
    });
  };

  const handleDelete = useCallback((id) => {
    onDelete({ id });
  }, []);

  const isEditing = (record: Webhook) => record?.id === editingId;

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: string;
    record: Webhook;
    index: number;
    children: React.ReactNode;
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record: Webhook) => {
    form.setFieldsValue({ ...record });
    setEditingId(record?.id);
  };

  const handleEditSubmit = async (id: number) => {
    const row = (await form.validateFields()) as Webhook;
    const { name, url } = row;
    onEdit({ id, name, url });
    setEditingId(false);
  };

  const [editingId, setEditingId] = useState<number | false>();
  const [deletingId, setDeletingId] = useState<number | false>();
  const [changeStatusId, setChangeStatusId] = useState<number | false>();

  const Actions = (_: any, record: Webhook & { editing?: boolean }) => {
    if (isEditing(record)) {
      return (
        <>
          <Button
            type="text"
            htmlType="submit"
            onClick={() => handleEditSubmit(record.id)}
          >
            Save
          </Button>
          <Button type="text" onClick={() => setEditingId(false)}>
            Cancel
          </Button>
        </>
      );
    }
    return (
      <>
        <Button type="text" onClick={() => edit(record)}>
          Edit
        </Button>
        <Popconfirm
          title="Are you sure you want to delete this integration?"
          visible={deletingId === record?.id}
          onCancel={() => setDeletingId(false)}
          onConfirm={() => {
            setDeletingId(false);
            handleDelete(record?.id);
          }}
          placement="topRight"
        >
          <Button
            type="text"
            onClick={() => setDeletingId(record?.id)}
            icon={<DeleteFilled className="relative" />}
          />
        </Popconfirm>
      </>
    );
  };

  const ActiveSwitch = (active: boolean, row: Webhook) => {
    return (
      <Switch checked={active} onClick={() => setChangeStatusId(row?.id)} />
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
      onCell: (record: any) => ({
        ...record,
        dataIndex: "name",
        title: "Name",
        editing: isEditing(record),
      }),
    },
    {
      title: "Active",
      dataIndex: "active",
      render: ActiveSwitch,
    },
    {
      title: "Webhook URL",
      dataIndex: "url",
      editable: true,
      onCell: (record: any) => ({
        ...record,
        dataIndex: "url",
        title: "Webhook URL",
        editing: isEditing(record),
      }),
    },
    {
      title: "Actions",
      render: Actions,
    },
  ];

  const selectedWebhook = webhooks?.find(
    (webhook) => webhook.id === changeStatusId
  );

  return (
    <>
      <Form form={form}>
        <Table
          columns={columns}
          dataSource={webhooks}
          components={{ body: { cell: EditableCell } }}
        />
      </Form>
      <ConfirmActiveModal
        name={selectedWebhook?.name ?? "this webhook"}
        visible={!!changeStatusId}
        closeModal={() => setChangeStatusId(false)}
        onSubmit={handleUpdateIntegrationStatus}
        deactivate={selectedWebhook?.active}
      />
    </>
  );
};

export default IntegrationsTable;
