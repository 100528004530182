import React from "react";
import { ContactSection } from "../../../../stories/ContactSection/ContactSection";
import { CollapsibleContactBlock } from "../CollapsibleContactBlock/CollapsibleContactBlock";
import { EstatedPropertyData } from "@types";
import "./EstatedDetails.less";
import { MaterialIcon } from "@components";
import { Collapse, Row, Statistic, Table } from "antd";
import { formatCurrency, stripeRowClasses } from "@utils/formatters";
import { SafeContactDetail } from "@components/ContactDetail";

const { Panel } = Collapse;
interface EstatedDetailsProps {
  /**
   * Estated V4 Raw Data
   */
  data: EstatedPropertyData;
  ltv?: number | string | null;
}

/*
 * Displays Estated V4 Raw Data in collapsible sections
 */
export const MortgageAndTaxDetails: React.FC<EstatedDetailsProps> = ({
  data,
  ltv,
}) => {
  const { structure, deeds, marketAssessments, assessments, taxes } = data;

  const assessmentColumns = [
    { title: "Year", dataIndex: "year" },
    { title: "Improvement Value", dataIndex: "improvementValue" },
    { title: "Total Value", dataIndex: "totalValue" },
  ];

  const taxAssessmentsByYear = assessments?.map((obj) => ({
    year: obj?.year,
    landValue: obj?.landValue ? formatCurrency(obj.landValue) : "N/A",
    improvementValue: obj?.improvementValue
      ? formatCurrency(obj.improvementValue)
      : "N/A",
    totalValue: obj?.totalValue ? formatCurrency(obj.totalValue) : "N/A",
  }));
  const marketAssessmentsByYear = marketAssessments?.map((obj) => ({
    year: obj?.year,
    landValue: obj?.landValue ? formatCurrency(obj.landValue) : "N/A",
    improvementValue: obj?.improvementValue
      ? formatCurrency(obj.improvementValue)
      : "N/A",
    totalValue: obj?.totalValue ? formatCurrency(obj.totalValue) : "N/A",
  }));
  const deedsByYear = deeds?.map((obj, index) => ({
    buyer:
      obj?.buyerFirstName && obj?.buyerLastName
        ? `${obj?.buyer2FirstName} ${obj?.buyerLastName}`
        : `Mortgage ${index}`,
    recordingDate: obj?.recordingDate,
    salePrice: obj?.salePrice ? formatCurrency(obj.salePrice) : "N/A",
  }));

  const taxByYear = taxes?.map((obj) => ({
    year: obj?.year,
    amount: obj?.amount ? formatCurrency(obj.amount) : "N/A",
  }));

  return (
    <>
      {/* <CollapsibleContactBlock
        title="Additional"
        data={structure}
        justify="space-between"
      /> */}

      <ContactSection
        title="Mortgage and Tax"
        icon={
          <MaterialIcon icon="landscape" className="text-xl align-middle" />
        }
      >
        <Collapse className="spaced-collapse">
          <Panel header="Deed" key="1">
            <Row>
              <Statistic
                className="px-4 mb-4"
                title="Loan to value"
                value={ltv ? ltv : "N/A"}
                valueStyle={{ fontSize: 16 }}
              />
            </Row>
            <Table
              dataSource={deedsByYear}
              rowClassName={(_, index) => {
                return stripeRowClasses(index);
              }}
              pagination={false}
              columns={[
                { title: "Buyer", dataIndex: "buyer" },
                { title: "Sale Price", dataIndex: "salePrice" },
                { title: "Recording Date", dataIndex: "recordingDate" },
              ]}
            />
          </Panel>
        </Collapse>
        <Collapse className="spaced-collapse">
          <Panel header="Property Tax" key="1">
            <p>Property Tax</p>
            <Table
              dataSource={taxByYear}
              rowClassName={(_, index) => {
                return stripeRowClasses(index);
              }}
              pagination={false}
              columns={[
                { title: "Year", dataIndex: "year" },
                { title: "Amount", dataIndex: "amount" },
              ]}
            />
            <p className="mt-2">Tax Assessments</p>
            <Table
              dataSource={taxAssessmentsByYear}
              rowClassName={(_, index) => {
                const isEven = index % 2 === 0;
                return isEven ? " bg-striped" : " bg-transparent";
              }}
              pagination={false}
              columns={assessmentColumns}
            />
            <p className="mt-2">Market Assessments</p>
            <Table
              dataSource={marketAssessmentsByYear}
              rowClassName={(_, index) => {
                return stripeRowClasses(index);
              }}
              pagination={false}
              columns={assessmentColumns}
            />
          </Panel>
        </Collapse>
      </ContactSection>
    </>
  );
};
