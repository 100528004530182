import React, { FC } from "react";
import { Alert, AlertProps } from "antd";

export const ErrorAlert: FC<AlertProps> = ({ ...alertProps }) => {
  // if (!message || typeof errorMsg === "object") return null;
  return (
    <Alert
      className="my-4"
      // message={errorMsg}
      type="error"
      showIcon
      closable
      {...alertProps}
    />
  );
};
