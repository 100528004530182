import React, { useMemo, useState } from "react";
import { Layout, Statistic, Row, Button } from "antd";
import { CampaignsList } from "./components/CampaignsList/CampaignsList";
import {
  CampaignsOrdering,
  useGetAgentQuery,
  useGetCampaigns,
  useGetCampaignsQuery,
} from "@queries";
import {
  CampaignStatTotals,
  Loading,
  CampaignEditor,
  GenericError,
  MaterialIcon,
} from "@components";

const { Content } = Layout;

export const CampaignsPage = () => {
  const { data: agent } = useGetAgentQuery();
  const { campaigns, isLoading, error, campaignStatTotals } = useGetCampaigns();
  const [isCreating, setIsCreating] = useState(false);

  if (isLoading) {
    return <Loading tip="Retrieving your campaigns..." />;
  }

  if (error) {
    return <GenericError />;
  }

  return (
    <Layout className="min-h-screen bg-white">
      <Content className="container py-8 mx-auto bg-transparent">
        {isCreating && (
          <CampaignEditor
            isNew
            visible={isCreating}
            onClose={() => setIsCreating(false)}
          />
        )}
        <div className="mx-auto max-w-7xl">
          <Row className="px-4 pb-8" justify="space-between">
            {/* <Col xs={12}>
                <DashboardPanel>
                  <LeadsChart data={leadsData} />
                </DashboardPanel>
              </Col> */}
            {/* <Col xs={12}> */}
            {/* <div className="flex p-4 ml-4 space-y-4"> */}
            <div className="flex">
              <CampaignStatTotals data={campaignStatTotals} />
            </div>
            {agent?.isSignupFlowComplete && (
              <Button
                size="large"
                type="primary"
                onClick={() => setIsCreating(true)}
              >
                <MaterialIcon icon="add" className="mr-1 align-middle" />
                New Campaign
              </Button>
            )}
          </Row>
        </div>
        <div className="mx-auto max-w-7xl">
          <CampaignsList data={campaigns} />
        </div>
      </Content>
    </Layout>
  );
};
