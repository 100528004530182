import React, { useCallback, useState } from "react";
import { Button, Form, Input, notification, Row } from "antd";
import { FileOutlined } from "@ant-design/icons";
import { ContactStageSelect, MaterialIcon } from "@components";
import { SelectedContact } from "./SelectedContactActivity";
import { useAddContactNoteMutation } from "@queries";
import { useBoolean } from "ahooks";

const { TextArea } = Input;

export const UpdateContactBar: React.FC<SelectedContact> = ({
  contactId,
  currentContactStage,
}) => {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const openNoteForm = useCallback(() => setIsAddingNote(true), []);
  const closeNoteForm = useCallback(() => setIsAddingNote(false), []);
  const [saveActive, { setTrue: setSaveActive, setFalse: setSaveInactive }] =
    useBoolean(false);
  const [form] = Form.useForm();

  const [addNote, { isLoading, error }] = useAddContactNoteMutation();

  const handleNoteSubmit = useCallback(
    ({ note }) => {
      addNote({ contactId, note })
        .unwrap()
        .then(() => form.resetFields());
    },
    [addNote, contactId, form]
  );

  if (error) {
    notification.warning({ message: "Unable to add note." });
  }

  return (
    <div>
      <div className="flex justify-evenly">
        <div className="w-32">
          <ContactStageSelect
            subtle={false}
            contactId={contactId}
            contactStage={currentContactStage}
          />
        </div>
        <Button onClick={openNoteForm} type="primary">
          <FileOutlined />
          Add a note
        </Button>
      </div>
      {isAddingNote && (
        <div className="mt-4">
          <Row justify="space-between" className="px-4">
            <div className="text-base font-bold">Add a note</div>
            <Button type="text" onClick={closeNoteForm}>
              Cancel
              <MaterialIcon
                icon="close"
                className="ml-1 text-sm align-middle"
              />
            </Button>
          </Row>
          <div className="w-full p-4" style={{ backgroundColor: "#F6F7F9" }}>
            <Form
              form={form}
              onFinish={handleNoteSubmit}
              onValuesChange={({ note }) =>
                note?.length ? setSaveActive() : setSaveInactive()
              }
            >
              <Form.Item
                name="note"
                validateTrigger="onSubmit"
                rules={[
                  {
                    min: 3,
                    required: true,
                    message: "Note must be at least 3 characters.",
                  },
                ]}
              >
                <TextArea
                  bordered={false}
                  autoSize
                  className="border-0"
                  style={{ backgroundColor: "#F6F7F9" }}
                  placeholder="Note your latest activity."
                  maxLength={10000}
                />
              </Form.Item>
              <div className="flex justify-end pr-4">
                <Button
                  htmlType="submit"
                  type={saveActive ? "primary" : "text"}
                  className={saveActive ? "" : "btn-tertiary"}
                  loading={isLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};
