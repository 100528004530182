import React, { FC, useCallback } from "react";
import { Button, ButtonProps, Tooltip } from "antd";
import { useBoolean } from "ahooks";

export const CopyableButton: FC<ButtonProps & { copyText?: string }> = ({
  children,
  copyText,
  ...props
}) => {
  const [copied, { setTrue, setFalse }] = useBoolean();
  const copyToClipboard = useCallback(() => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
      setTrue();
      setTimeout(() => setFalse(), 1000);
    }
  }, [copyText, navigator, setTrue, setFalse]);

  return (
    <Tooltip title={copied ? "Copied!" : "Copy"}>
      <Button type="text" className="px-4" {...props} onClick={copyToClipboard}>
        <>
          {children}
          <span
            className="text-base align-middle material-icons text-green"
            style={{ color: copied ? "green" : "transparent" }}
          >
            done
          </span>
        </>
      </Button>
    </Tooltip>
  );
};
