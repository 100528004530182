import { Campaign, BidCampaign, SubscriptionCampaign } from "@types";
import { useMemo } from "react";
import { appApi } from "./api";

export enum CampaignsOrdering {
  activeFirst = "status",
  inactiveFirst = "-status",
  leadStage = "leadStage",
  leadStageDesc = "-leadStage",
  weeklyBudget = "weeklyBudget",
  weeklyBudgetDesc = "-weeklyBudget",
  bidAmount = "bidAmount",
  bidAmountDesc = "-bidAmount",
}

export interface CampaignsRequest {
  limit: number;
  offset: number;
  ordering?: CampaignsOrdering;
}

export interface CampaignsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: BidCampaign[];
}
export const campaignsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getZipCodes: build.query<
      { zipCodes: string[] },
      { city: string; state: string }
    >({
      query: ({ city, state }) => `zip-codes/?city=${city}&state=${state}`,
    }),
    getCampaigns: build.query<CampaignsResponse, CampaignsRequest | void>({
      query: (params) => ({
        url: "/campaigns/home-sellers/",
        params: { ...params, api_version: "2.1.0" },
      }),
      providesTags: ["Campaign"],
    }),
    getCampaign: build.query<Campaign, number>({
      query: (campaignId) => ({
        url: `/campaigns/home-sellers/${campaignId}/`,
      }),
      providesTags: ["Campaign"],
    }),
    getSubscriptionCampaign: build.query<SubscriptionCampaign[], void>({
      query: () => "/campaigns/monthly-budget-home-sellers/",
    }),
    createCampaign: build.mutation<BidCampaign, BidCampaign>({
      query: (campaign) => ({
        url: "/campaigns/home-sellers/",
        method: "POST",
        body: campaign,
      }),
      invalidatesTags: ["Campaign"],
    }),
    updateCampaign: build.mutation<BidCampaign, Partial<BidCampaign>>({
      query: (campaign) => ({
        url: `/campaigns/home-sellers/${campaign.id}/`,
        method: "PATCH",
        body: campaign,
      }),
      invalidatesTags: ["Campaign"],
    }),
    deleteCampaign: build.mutation<Campaign, number>({
      query: (campaignId) => ({
        url: `/campaigns/home-sellers/${campaignId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Campaign"],
    }),
  }),
});

export const useGetCampaigns = () => {
  const { data, isLoading, error } = useGetCampaignsQuery({
    limit: 50,
    offset: 0,
    // ordering: CampaignsOrdering.activeFirst,
  });

  const campaigns = data?.results;

  const campaignStatTotals = useMemo(
    () =>
      campaigns?.reduce(
        (c1, c2) => ({
          totalSpent: c1.totalSpent + parseFloat(c2.amountSpent),
          totalLeadsWon: c1.totalLeadsWon + c2.numLeadsWon,
          totalWeeklyBudget: c1.totalWeeklyBudget + parseFloat(c2.weeklyBudget),
        }),
        { totalSpent: 0, totalLeadsWon: 0, totalWeeklyBudget: 0 }
      ),
    [campaigns]
  );

  return { campaigns, isLoading, error, campaignStatTotals };
};

export const {
  useLazyGetZipCodesQuery,
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useGetSubscriptionCampaignQuery,
} = campaignsApi;
