import { Contact } from "@types";
import { appApi } from "./api";

export const refundApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    refundLeads: build.query<Contact[], void>({
      query: () => "/leads/refund-eligible-home-sellers/",
      providesTags: ["RefundLeads"],
    }),
    refundRequest: build.mutation<
      unknown,
      { leadId: number; reason: string; comment: string }
    >({
      query: ({ leadId, reason, comment }) => ({
        url: `/leads/home-sellers/${leadId}/request-refund/`,
        method: "POST",
        body: { reason, comment },
      }),
      invalidatesTags: ["RefundLeads"],
    }),
  }),
});

export const { useRefundLeadsQuery, useRefundRequestMutation } = refundApi;
