import React, { FC, useMemo } from "react";
import { Button, Typography, Space, Spin, Row, notification } from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";

import { useGetAgentQuery, useActivateSignupCampaignMutation } from "@queries";
import { setInitialZipCodes } from "@utils/campaign";
import { useNavigate } from "react-router-dom";
import {
  CityZipcodesList,
  LoadingScreen,
  EditButton,
  GenericError,
} from "@components";
import { ASMPopUp } from "@containers";

const { Text, Title } = Typography;

const ActivateCampaign: FC = () => {
  const { data: agent, isLoading, error } = useGetAgentQuery();
  const v2Signup = agent?.v2Signup;
  const campaign = v2Signup?.homeSellerCampaign;
  const navigate = useNavigate();
  const [activateCampaign, { isLoading: activateIsLoading }] =
    useActivateSignupCampaignMutation();

  const handleSubmit = () => {
    activateCampaign()
      .unwrap()
      .then((payload) => {
        navigate("/signup/complete");
      })
      .catch((error) => {
        notification.error({
          message: "There was an error activating your campaign.",
        });
      });
  };

  const handleZipcodesEdit = () => {
    navigate("/signup/campaign", { state: { fromActivate: true } });
  };

  if (!campaign || isLoading) return <LoadingScreen />;
  if (error) return <GenericError />;

  const zipCodes = setInitialZipCodes(campaign);

  return (
    <OnboardingLayout step={7}>
      <div className="mt-8 panel-header">
        <h2>Activate</h2>
        <Text className="text-base">
          When you activate your campaign, your account will automatically begin
          to bid for each introduction that becomes available in your defined
          region.
        </Text>
      </div>
      <div className="flex justify-center panel-w-header">
        <div className="w-full max-w-2xl">
          <Text className="text-base font-bold">Your campaign:</Text>
          <div className="p-4 space-y-2">
            <Row justify="space-between">
              <Title level={5}>Region</Title>
              <CityZipcodesList cityStateToZipCodes={zipCodes} />
              <EditButton onClick={handleZipcodesEdit} />
            </Row>
            <hr />
            <Row justify="space-between">
              <Title level={5}>Bid Amount</Title>
              <Text className="text-base">${campaign?.bidAmount}</Text>
            </Row>
            <hr />
            <Row justify="space-between">
              <Title level={5}>Weekly Budget</Title>
              <Text className="text-base">
                {campaign?.weeklyBudget == "0.00"
                  ? "None"
                  : `$${campaign?.weeklyBudget}`}
              </Text>
            </Row>
          </div>
        </div>
      </div>

      <Row justify="end" className="p-4">
        <Spin spinning={activateIsLoading}>
          <Button
            className="px-4"
            type="primary"
            key="create"
            size="large"
            onClick={handleSubmit}
          >
            Activate Campaign
          </Button>
        </Spin>
      </Row>
      <ASMPopUp />
    </OnboardingLayout>
  );
};

export default ActivateCampaign;
