import React, { FC, useCallback, useState } from "react";
import { Col, Row, Alert, Button, Empty, Spin, notification } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { CreditCardInfo } from "@components";
import { CreditCard } from "@types";
import { useActiveCreditCardQuery, useAddCreditCardMutation } from "@queries";
import { AutoDeposit } from "@containers";

const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;
const stripePromise = loadStripe(STRIPE_PUB_KEY);

export const Payment: FC = () => (
  <Elements stripe={stripePromise}>
    <InjectedPayment />
  </Elements>
);

export const InjectedPayment: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { data, isLoading, error: cardError } = useActiveCreditCardQuery();
  const [addCreditCard, { isLoading: addCardIsLoading, error: addCardError }] =
    useAddCreditCardMutation();
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const openCreditCardForm = useCallback(() => {
    setIsEditing(true);
  }, []);
  const closeCreditCardForm = useCallback(() => {
    setIsEditing(false);
  }, []);

  const updateCard = useCallback(async () => {
    try {
      // Use elements.getElement to get a reference to the mounted Element.
      const cardElement = elements && elements.getElement(CardElement);

      if (!stripe || !cardElement) throw new Error("Stripe Error");
      const card = await stripe.createToken(cardElement);
      console.log("stripe card response: ", card.error);
      if (card?.error) {
        if (card?.error?.message) {
          setError(card.error.message);
        } else throw new Error("Stripe Error");
      }
      const { token } = card;
      console.log("stripe token: ", token);
      if (!token?.id)
        throw new Error("Did not receive id for card from stripe.");

      setError("");
      addCreditCard({ token })
        .unwrap()
        .then((payload) => {
          console.log("updateCard fulfilled: ", payload);
          notification.success({
            placement: "bottomLeft",
            message: "Payment method updated.",
          });
          closeCreditCardForm();
        })
        .catch((error) => {
          console.log("addPayment rejected", error);
          notification.error({
            placement: "bottomLeft",
            message: "Sorry, there was an error with your payment.",
          });
        });
    } catch (e) {
      console.log("Stripe error: ", (e as Error)?.message);
    }
  }, [elements, stripe, addCreditCard]);

  if (isLoading) {
    return (
      <div className="w-full p-4 panel">
        <Spin spinning />
      </div>
    );
  }

  return (
    <div className="max-w-4xl p-4">
      <h1 className="mb-4">Payment Method</h1>
      <div className="p-0 m-0">
        {isEditing ? (
          <div className="panel">
            <h2>Add Card</h2>
            {error && (
              <Alert
                className="mb-2"
                closable
                message={error}
                type="error"
                showIcon
                afterClose={() => setError("")}
              />
            )}
            <CardElement
              className="p-4"
              options={{ style: { base: { fontSize: "20px" } } }}
            />
            <div className="flex flex-wrap justify-center">
              <Button
                type="text"
                className="w-24 m-4 btn-tertiary"
                onClick={closeCreditCardForm}
              >
                Cancel
              </Button>
              <Spin spinning={addCardIsLoading}>
                <Button
                  type="primary"
                  className="w-32 m-4"
                  onClick={updateCard}
                >
                  Save
                </Button>
              </Spin>
            </div>
          </div>
        ) : (
          <Row>
            <Col xs={20} md={16}>
              {data ? (
                <CreditCardInfo {...data} />
              ) : (
                <Empty description="No credit card yet, add one to start winning leads!" />
              )}
            </Col>
            <Col>
              <Button className="ml-6" onClick={openCreditCardForm}>
                Update Card
              </Button>
            </Col>
          </Row>
        )}
        <hr className="my-8" />
        <AutoDeposit />
      </div>
    </div>
  );
};
