import React, { useMemo } from "react";
import { Form, Slider, Checkbox, InputNumber, FormInstance } from "antd";
import {
  EST_HOME_VALUE_MAX_NOT_SET,
  EST_HOME_VALUE_MIN_NOT_SET,
  // HOME_PRICE_MARKS,
  PROPERTY_TYPES,
  // SIMPLE_HOME_PRICE_MARKS,
} from "@constants";
import { useScreenSize } from "@utils/hooks";

export const FilterOptions: React.FC<{ form: FormInstance }> = ({ form }) => {
  // TODO: home price sliders for mobile
  // const { isSmallScreen } = useScreenSize();
  // const homePriceOptions = useMemo(
  //   () => (isSmallScreen ? SIMPLE_HOME_PRICE_MARKS : HOME_PRICE_MARKS),
  //   [isSmallScreen]
  // );
  // const numOptions = useMemo(() => (isSmallScreen ? 5 : 11), [isSmallScreen]);
  return (
    <>
      {/* <Form.Item
        key="homePrice"
        name="homePrice"
        label="Home Price"
        labelAlign="left"
        tooltip="Most of the homes we get fall between 100k and 700k"
        // labelCol={{ xs: { span: 24 } }}
        // wrapperCol={{ xs: { span: 24 } }}
        normalize={normalizeHomePrice}
        rules={[
          {
            validator: (_, value) =>
              value[0] === value[1]
                ? Promise.reject(new Error("Please expand your range"))
                : Promise.resolve(),
          },
        ]}
      >
        <Slider
          marks={homePriceOptions}
          range
          min={0}
          max={numOptions}
          tooltipVisible={false}
        />
      </Form.Item> */}
      <Form.Item
        key="estimatedHomeValueMin"
        name="estimatedHomeValueMin"
        label="Estimated Home Price Minimum"
        labelAlign="left"
        tooltip="Most of the homes we get fall between 100k and 700k"
        dependencies={["estimatedHomeValueMax"]}
        rules={[
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              else {
                const estimatedHomeValueMax = form.getFieldValue(
                  "estimatedHomeValueMax"
                );

                if (!estimatedHomeValueMax) return Promise.resolve();

                if (value >= estimatedHomeValueMax) {
                  return Promise.reject(
                    new Error(
                      `This must be less than estimated home value maximum.`
                    )
                  );
                }
                if (value < EST_HOME_VALUE_MIN_NOT_SET) {
                  return Promise.reject(
                    new Error(
                      `Estimated home value minimum must be greater than ${EST_HOME_VALUE_MIN_NOT_SET}`
                    )
                  );
                }

                if (value > EST_HOME_VALUE_MAX_NOT_SET) {
                  return Promise.reject(
                    new Error(
                      `Estimated home value minimum must be less than ${EST_HOME_VALUE_MAX_NOT_SET}`
                    )
                  );
                }

                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <InputNumber
          className="w-40"
          size="large"
          min={EST_HOME_VALUE_MIN_NOT_SET}
          max={EST_HOME_VALUE_MAX_NOT_SET}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          step={100000}
        />
      </Form.Item>
      <Form.Item
        key="estimatedHomeValueMax"
        name="estimatedHomeValueMax"
        label="Estimated Home Price Maximum"
        labelAlign="left"
        tooltip="Most of the homes we get fall between 100k and 700k"
        dependencies={["estimatedHomeValueMin"]}
        rules={[
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              else {
                const estimatedHomeValueMin = form.getFieldValue(
                  "estimatedHomeValueMin"
                );

                if (!estimatedHomeValueMin) return Promise.resolve();

                if (value <= estimatedHomeValueMin) {
                  return Promise.reject(
                    new Error(
                      `This must be greater than estimated home value minimum.`
                    )
                  );
                }
                if (value < EST_HOME_VALUE_MIN_NOT_SET) {
                  return Promise.reject(
                    new Error(
                      `Estimated home value maximum must be greater than ${EST_HOME_VALUE_MIN_NOT_SET}`
                    )
                  );
                }

                if (value > EST_HOME_VALUE_MAX_NOT_SET) {
                  return Promise.reject(
                    new Error(
                      `Estimated home value maximum must be less than ${EST_HOME_VALUE_MAX_NOT_SET}`
                    )
                  );
                }

                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <InputNumber
          className="w-40"
          size="large"
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          min={EST_HOME_VALUE_MIN_NOT_SET}
          max={EST_HOME_VALUE_MAX_NOT_SET}
          step={100000}
        />
      </Form.Item>
      <Form.Item
        key="propertyType"
        name="propertyType"
        label="Property Type"
        labelAlign="left"
        rules={[
          {
            validator: (_, value) =>
              value?.length
                ? Promise.resolve()
                : Promise.reject(
                    new Error("You must select at least one property type")
                  ),
          },
        ]}
      >
        <Checkbox.Group options={PROPERTY_TYPES} />
      </Form.Item>
    </>
  );
};
