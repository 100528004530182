import Cookies from "js-cookie";
import { getTime } from "date-fns";
import { useNavigate } from "react-router-dom";
import { resetApiState } from "@queries";

const TOKEN_KEY = "Token";

export const getToken = (): string | undefined => Cookies.get(TOKEN_KEY);

export const setToken = (token: string): string | undefined =>
  Cookies.set(TOKEN_KEY, token);

export const removeToken = (): void => Cookies.remove(TOKEN_KEY);

export const isTokenSet = (): boolean => {
  return Boolean(getToken());
};

export interface AgentResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateJoined: string;
  hasAddedFunds: boolean;
  hasCreatedCampaign: boolean;
  hasActivatedCampaign: boolean;
  hasWonLead: boolean;
}

export const userpilotIdentify = ({
  id,
  firstName,
  lastName,
  email,
  dateJoined,
  hasAddedFunds,
  hasCreatedCampaign,
  hasActivatedCampaign,
  hasWonLead,
}: AgentResponse): void => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  if (
    (window as any).userpilot &&
    typeof (window as any).userpilot === "object"
  ) {
    (window as any).userpilot.identify(id, {
      name: `${firstName} ${lastName}`,
      email,
      created_at: getTime(new Date(dateJoined)),
      has_added_funds: hasAddedFunds,
      has_created_campaign: hasCreatedCampaign,
      has_activated_campaign: hasActivatedCampaign,
      has_won_leads: hasWonLead,
    });
  }
};
