import React, { FC } from "react";

interface Title {
  title: string;
  onClick?: () => void;
  isActive: boolean;
}

export const PanelHeader: FC<Title> = ({
  children,
  title,
  onClick,
  isActive,
}) => {
  const containerClasses = "p-4 mt-4 rounded-t-lg ";
  const titleClasses = "text-lg font-semibold ";
  return (
    <div
      onClick={onClick}
      className={
        isActive
          ? containerClasses + "bg-blue"
          : containerClasses +
            "border-2 border-gray-300 bg-gray-200 rounded-b-lg"
      }
    >
      <div className={isActive ? titleClasses + " text-white" : titleClasses}>
        {title}
      </div>
      <hr className="my-2" />
      {children}
    </div>
  );
};
