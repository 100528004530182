import { appApi } from "./api";
import { Activity, Contact, ContactStage } from "@types";
import { RootState } from "@store/store";
import { notification } from "antd";

export enum ContactsOrdering {
  oldest = "created",
  newest = "-created",
  leadStage = "leadStage",
  leadStageDesc = "-leadStage",
}

export interface ContactsRequest {
  limit: number;
  offset: number;
  ordering?: ContactsOrdering;
}

export interface ContactsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Contact[];
}

export interface UpdateStageRequest {
  contactId: number;
  contactStage: ContactStage;
}

export interface AddNoteRequest {
  contactId: number;
  note: string;
}

export const contactsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query<ContactsResponse, ContactsRequest>({
      query: (params) => ({
        url: "/leads/home-sellers/",
        params: { ...params, api_version: "2.1.0" },
      }),
      providesTags: [{ type: "Contact", id: "LIST" }],
    }),
    getContact: build.query<Contact, number>({
      query: (contactId) => ({
        url: `/leads/home-sellers/${contactId}/`,
      }),
      providesTags: (result, error, contactId) => [
        { type: "Contact", id: contactId },
      ],
    }),
    updateContactStage: build.mutation<Contact, UpdateStageRequest>({
      query: ({ contactId, contactStage }) => ({
        url: `/leads/home-sellers/${contactId}/`,
        method: "PATCH",
        body: { leadStage: contactStage },
      }),
      invalidatesTags: (result, error, { contactId }) => [
        { type: "Activity", id: contactId },
        // { type: "Contact", id: contactId },
        // { type: "Contact", id: "LIST" },
      ],
      async onQueryStarted(
        { contactId, ...patch },
        { getState, dispatch, queryFulfilled }
      ) {
        // Apply pessimistic update to contact detail and contact list to avoid refetching the contact list and details
        try {
          const { data: updatedContact } = await queryFulfilled;
          const contactIdPatchResult = dispatch(
            contactsApi.util.updateQueryData(
              "getContact",
              contactId,
              (draft) => {
                draft.leadStage = updatedContact?.leadStage;
              }
            )
          );
          const { page, pageSize, ordering } = (getState() as RootState)
            .contactsPagination;

          const contactListPatchResult = dispatch(
            contactsApi.util.updateQueryData(
              "getContacts",
              {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                ordering,
              },
              (draft) => {
                const cachedContact = draft?.results.find(
                  (contact) => contact.id === contactId
                );
                if (cachedContact)
                  cachedContact.leadStage = updatedContact?.leadStage;
              }
            )
          );
          console.log("Patch contact result: ", contactIdPatchResult);
          console.log("Patch contact list result: ", contactListPatchResult);
        } catch {
          notification.warning({ message: "Unable to update contact stage" });
        }
      },
    }),
    getContactActivity: build.query<Activity[], number | string | void>({
      query: (contactId) => ({
        url: "/leads/home-seller-activities/",
        params: contactId ? { lead: contactId } : undefined,
      }),
      providesTags: (result, error, contactId) => [
        { type: "Activity", id: contactId || "ALL" },
      ],
    }),
    addContactNote: build.mutation<Contact, AddNoteRequest>({
      query: ({ contactId, note }) => ({
        url: "/leads/home-seller-activities/note/",
        method: "POST",
        body: { lead: contactId, note },
      }),
      invalidatesTags: (result, error, { contactId }) => [
        { type: "Activity", id: contactId },
        { type: "Activity", id: "ALL" },
      ],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useGetContactQuery,
  useGetContactActivityQuery,
  useLazyGetContactActivityQuery,
  useAddContactNoteMutation,
  useUpdateContactStageMutation,
} = contactsApi;
