import React, { FC } from "react";
import { matchStage } from "@utils/signupHelpers";
import { useV2Signup } from "@queries";
import { BID_SIGNUP_STAGES, SUBSCRIPTION_SIGNUP_STAGES } from "@constants";
import { CampaignTypePath } from "@types";
import { Navigate, useLocation } from "react-router-dom";

export interface SignupRoutePermissions {
  children: React.ReactElement;
  requireBiddingPath?: boolean;
  requireSubscriptionPath?: boolean;
  requireVerified?: boolean;
  requireNotVerified?: boolean;
  requireCampaignLocationSet?: boolean;
}

export const SignupRoutePermissionVerifier = ({
  children,
  requireBiddingPath = false,
  requireSubscriptionPath = false,
  requireVerified = false,
  requireNotVerified = false,
  requireCampaignLocationSet = false,
}: SignupRoutePermissions): React.ReactElement => {
  const {
    stage,
    campaignTypePath,
    isVerificationComplete,
    emailVerified,
    phonenumberVerified,
  } = useV2Signup();

  const { pathname, state } = useLocation();
  const agentStage = matchStage({ stageName: stage, campaignTypePath });
  const currentStage = matchStage({ path: pathname, campaignTypePath });

  const isCampaignLocationSet =
    agentStage?.page > BID_SIGNUP_STAGES.CAMPAIGN_LOCATION.page ||
    agentStage?.page > SUBSCRIPTION_SIGNUP_STAGES.CAMPAIGN_LOCATION.page;

  if ((state as any)?.fromPreviousStage) return children;

  try {
    // * Default permission to prevent advancing to next step
    if (isCampaignLocationSet) {
      if (currentStage?.page > agentStage?.page)
        throw new Error("Agent is not on that step yet");
    }

    if (requireBiddingPath) {
      if (campaignTypePath !== CampaignTypePath.Bidding)
        throw new Error("Not bidding campaign type path");
    }
    if (requireSubscriptionPath) {
      if (campaignTypePath !== CampaignTypePath.MonthlyBudget)
        throw new Error("Not monthly budget campaign type path");
    }
    if (requireVerified) {
      if (!isVerificationComplete) throw new Error("Agent is not verified");
    }
    if (requireNotVerified) {
      if (isVerificationComplete) throw new Error("Agent is verified");
    }
    if (requireCampaignLocationSet) {
      if (!isCampaignLocationSet) throw new Error("Campaign location not set");
    }

    return children;
  } catch (error) {
    console.log(
      `Invalid route permissions: ${error}, redirecting to stage: ${stage}.`
    );
    return <Navigate to={agentStage?.path} />;
  }
};
