/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useState } from "react";
import {
  Form,
  Layout,
  Typography,
  Space,
  notification,
  Image,
  Input,
  Button,
  Spin,
  Alert,
} from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";

import styles from "./EmailVerification.m.less";
import { useResendEmailVerificationMutation, useGetAgentQuery } from "@queries";

const { Link, Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const EmailVerification: FC = () => {
  const {
    data: agent,
    isLoading: isLoadingAgent,
    error: agentError,
  } = useGetAgentQuery();
  const userEmail = agent?.user?.email || "your email";
  const [showResendForm, setShowResendForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resendEmailVerification] = useResendEmailVerificationMutation();

  const handleResendSubmit = useCallback(async (values) => {
    setIsLoading(true);
    setErrorMsg("");
    resendEmailVerification(values)
      .unwrap()
      .then((payload) => {
        setShowResendForm(false);
        setIsLoading(false);
        notifyResendSuccess();
      })
      .catch((error) => {
        setIsLoading(false);
        const errorResponse =
          error?.data?.email?.[0] || error?.data?.nonFieldErrors;
        if (errorResponse) setErrorMsg(errorResponse);
        // else if (errorMessage) notifyFailure(errorMessage);
        else notifyResendFailure();
      });
  }, []);

  const notifyResendSuccess = useCallback(() => {
    notification.success({
      message: `We've just sent you an email! Check your inbox.`,
      placement: "bottomLeft",
    });
  }, [notification]);

  const notifyResendFailure = useCallback(() => {
    notification.error({
      message: `There was a problem with verifying your email, please try again.`,
      placement: "bottomLeft",
    });
  }, [notification]);

  const notifyFailure = useCallback(
    (errMsg) => {
      notification.error({
        message: errMsg,
        placement: "bottomLeft",
        duration: 8,
      });
    },
    [notification]
  );

  return (
    <OnboardingLayout title={"Verify Your Email"} step={3}>
      <Content className={styles.container}>
        <Space key="a" direction="vertical" size="small" align="start" wrap>
          {showResendForm ? (
            <ResendEmailForm
              onSubmit={handleResendSubmit}
              onCancel={() => {
                setShowResendForm(false);
                setErrorMsg("");
              }}
              isLoading={isLoading}
              errorMessage={errorMsg}
            />
          ) : (
            <EmailVerificationSent
              onResendRequest={() => setShowResendForm(true)}
              userEmail={userEmail}
            />
          )}
        </Space>
        <Space
          key="space"
          className={styles.imageContainer}
          direction="vertical"
          wrap={false}
          align="center"
        >
          <Image src="/images/svgs/next_verify_email.svg" preview={false} />
        </Space>
      </Content>
    </OnboardingLayout>
  );
};

export default EmailVerification;

interface ResendEmailProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading: boolean;
  errorMessage: string;
}

const ResendEmailForm = ({
  onCancel,
  onSubmit,
  isLoading,
  errorMessage,
}: ResendEmailProps) => {
  const [form] = Form.useForm();

  return (
    <>
      <Text style={{ fontSize: 18 }}>
        Double-check this email, then hit send.
      </Text>
      <Form
        key="a"
        form={form}
        layout="inline"
        name="resendEmailVerify"
        requiredMark={"optional"}
        onFinish={onSubmit}
        className={"form mb-1"}
        validateTrigger="onSubmit"
      >
        <Form.Item
          key="b"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input a valid email",
            },
            { type: "email", message: "Please input a valid email" },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item key="c">
          <Spin spinning={isLoading}>
            <Button type="primary" htmlType="submit" block size="large">
              Send
            </Button>
          </Spin>
        </Form.Item>
        <Button size="large" title="Cancel" onClick={onCancel}>
          Cancel
        </Button>
      </Form>
      {errorMessage && (
        <Space className={styles.alertContainer}>
          <Alert
            className="my-4"
            message={errorMessage}
            type="error"
            showIcon
            closable
          />
        </Space>
      )}
    </>
  );
};

interface EmailVerificationSentProps {
  onResendRequest: () => void;
  userEmail: string;
}

const EmailVerificationSent = ({
  onResendRequest,
  userEmail,
}: EmailVerificationSentProps) => {
  return (
    <>
      <Paragraph style={{ fontSize: 18 }}>
        Check your inbox! You have an email from us{" "}
        {userEmail && (
          <span>
            in <b>{userEmail}</b>
          </span>
        )}
        . Click the link we set you so that we can introduce you to our leads by
        email.
      </Paragraph>
      <Button
        type="text"
        size="large"
        className="link-secondary"
        onClick={onResendRequest}
      >
        Resend email
      </Button>
    </>
  );
};
