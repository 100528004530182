import React from "react";
import { Modal, Button, Typography } from "antd";

interface IProps {
  visible: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  deactivate?: boolean;
  name: string;
}

const { Title, Paragraph } = Typography;

const ConfirmActiveModal: React.FC<IProps> = ({
  visible,
  closeModal,
  onSubmit,
  name,
  deactivate = false,
}) => {
  return (
    <Modal
      visible={visible}
      centered
      keyboard
      width={600}
      onCancel={closeModal}
      closable={true}
      footer={null}
      mask={false}
      destroyOnClose
    >
      <div
        className="text-center"
        style={{
          backgroundImage: "url(/images/Graphics/pig-drinking-cup.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: 80,
          backgroundPosition: "bottom left",
        }}
      >
        <Title level={4}>
          Do you want to {deactivate ? "deactivate" : "activate"} {name}?
        </Title>
        <Paragraph>
          Your CRM will sync with this account only when it is active
        </Paragraph>
        <div className="flex flex-col mx-auto" style={{ maxWidth: 200 }}>
          {deactivate ? (
            <>
              <Button type="primary" onClick={onSubmit} size="large">
                Deactivate
              </Button>
              <Button className="mt-1" onClick={closeModal}>
                Keep Active
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" size="large" onClick={onSubmit}>
                Activate
              </Button>
              <Button
                type="text"
                onClick={closeModal}
                className="mt-1"
                size="middle"
              >
                Keep Inactive
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmActiveModal;
