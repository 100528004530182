import React, { FC } from "react";
import { useReferralCodesQuery } from "@queries";
import { ReferralDashboardWidget } from "@components";

export const ReferralDashboardWidgetContainer: FC = () => {
  const { data, isLoading, error } = useReferralCodesQuery();
  const firstCode = data?.[0];

  if (isLoading || error || !firstCode) return null;
  return <ReferralDashboardWidget {...firstCode} />;
};
