import React from "react";
import { images } from "@constants";
import { Typography } from "antd";

const { Paragraph } = Typography;

export const ContactsHomePage: React.FC = () => {
  return (
    <div className="max-w-4xl p-8 mx-auto my-auto space-y-8 text-center panel">
      <img src={images.HOME_SELLER} width={300} />
      <Paragraph className="text-xl">
        Contacts that you win will be available here.
      </Paragraph>
    </div>
  );
};
