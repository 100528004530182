import React from "react";
import { Row, Col, Button } from "antd";
import { RightOutlined, FileOutlined } from "@ant-design/icons";
import { ContactSection } from "../../../../stories/ContactSection/ContactSection";
import { ContactDetail } from "@types";
import { ContactInfo } from "../../../../stories/ContactInfo/ContactInfo";
import { SafeContactDetail } from "../../../../stories/ContactDetail";
import { ContactStageSelect, MaterialIcon } from "@components";
import { ActivityDrawer } from "../ActivityDrawer/ActivityDrawer";
import { MortgageAndTaxDetails } from "../MortgageAndTaxDetails/MortgageAndTaxDetails";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import { setIsActivityOpen } from "@store/contactsPaginationSlice";

interface ContactDetails {
  /**
   * Detail Section Header TItle
   */
  title?: string;
  /**
   * Contact/Lead data
   */
  contact: ContactDetail;
}

/**
 * Displays all contact details
 */
export const ContactDetails: React.FC<ContactDetails> = ({ contact }) => {
  const dispatch = useAppDispatch();
  const { isActivityOpen } = useAppSelector(
    (state) => state.contactsPagination
  );

  const openActivity = () => {
    dispatch(setIsActivityOpen(true));
  };

  const closeActivity = () => {
    dispatch(setIsActivityOpen(false));
  };

  if (!contact) return null;

  const {
    id,
    leadStage,
    //* Selling details
    askingPrice,
    behindOnMortgagePayments,
    timeFrameToSell,
    reasonForSelling,
    estimatedHomeValue,
    estimatedHomeValueAppend,
    ltv,
    //* Property/Structure
    propertyType,
    yearBuilt,
    bedrooms,
    bathrooms,
    sqft,
  } = contact;

  //* Contact Info (Header)
  const {
    created,
    firstName,
    lastName,
    primaryPhone,
    email,
    propertyAddress,
    city,
    state,
    zipCode,
  } = contact;
  const contactInfo = {
    created,
    firstName,
    lastName,
    primaryPhone,
    email,
    propertyAddress,
    city,
    state,
    zipCode,
  };

  const estatedData = contact?.estatedData?.v4Raw?.data;
  const lotSize = estatedData?.parcel?.areaSqFt ?? "N/A";
  const owners = estatedData?.owner?.name;

  const mortgageStatus =
    behindOnMortgagePayments === "No"
      ? "Current On Payments"
      : "Behind on payments";

  return (
    <div className="min-w-min">
      <Row gutter={{ lg: 16 }}>
        <Col
          xs={{ span: 24, order: 1 }}
          xl={{ span: 19, order: 0 }}
          className="space-y-4"
        >
          <ActivityDrawer
            contactId={id}
            currentContactStage={leadStage}
            visible={isActivityOpen}
            onClose={closeActivity}
          />

          <ContactInfo {...contactInfo} />
          <div className="grid grid-cols-5 gap-x-4 gap-y-2">
            <div className="h-full col-span-5 row-span-2 lg:col-span-2">
              <ContactSection
                title="Owner Details"
                icon={
                  <MaterialIcon
                    icon="person"
                    className="text-xl align-bottom text-grays-fourth"
                  />
                }
              >
                <Row className="p-4 space-y-4 divide-y-2 panel" justify="start">
                  <SafeContactDetail title="Owner(s)" value={owners} />
                  <SafeContactDetail
                    title="Time Frame To Sell"
                    value={timeFrameToSell}
                  />
                  <SafeContactDetail
                    title="Reason For Selling"
                    value={reasonForSelling}
                  />
                  <SafeContactDetail
                    title="Mortgage Status"
                    value={mortgageStatus}
                  />
                </Row>
              </ContactSection>
            </div>
            <div className="col-span-5 row-span-1 lg:col-span-3">
              <ContactSection
                title="Property Details"
                icon={
                  <MaterialIcon
                    icon="home"
                    className="text-xl align-bottom text-grays-fourth"
                  />
                }
              >
                <div className="px-8 py-4 divide-y-2 panel">
                  <Row justify="start" style={{ flex: 2 }}>
                    <SafeContactDetail
                      className="w-1/2"
                      title="Asking Price"
                      value={askingPrice === 0 ? "Not Available" : askingPrice}
                      prefix={askingPrice === 0 ? "" : "$"}
                      groupSeparator=","
                    />
                    <SafeContactDetail
                      className="w-1/2"
                      title="Estimated Value"
                      value={
                        estimatedHomeValueAppend ??
                        estimatedHomeValue ??
                        "Not Available"
                      }
                      prefix={
                        estimatedHomeValueAppend ?? estimatedHomeValue
                          ? "$"
                          : ""
                      }
                      groupSeparator=","
                    />
                  </Row>
                  <Row justify="start">
                    <SafeContactDetail
                      className="w-1/2"
                      title="Type"
                      value={propertyType}
                    />
                    <SafeContactDetail
                      className="w-1/2"
                      title="Built"
                      value={yearBuilt === 0 ? "N/A" : yearBuilt}
                      groupSeparator=""
                    />
                  </Row>
                </div>
              </ContactSection>
            </div>
            <div className="col-span-5 row-span-1 px-8 py-4 divide-y-2 lg:col-span-3 panel">
              <Row justify="start" style={{ flex: 2 }}>
                <SafeContactDetail
                  className="w-1/2"
                  title="Size"
                  value={sqft === 0 ? "N/A" : sqft}
                  suffix={sqft === 0 ? "" : "sqft"}
                  groupSeparator=","
                />
                <SafeContactDetail
                  className="w-1/2"
                  title="Lot Size"
                  value={lotSize}
                  groupSeparator=","
                />
              </Row>
              <Row justify="start" style={{ flex: 2 }}>
                <SafeContactDetail
                  className="w-1/2"
                  title="Bedrooms"
                  value={bedrooms}
                  suffix="beds"
                />
                <SafeContactDetail
                  className="w-1/2"
                  title="Bathrooms"
                  value={bathrooms}
                  suffix="baths"
                />
              </Row>
            </div>
          </div>
          {contact.estatedRetrieved && estatedData && (
            <MortgageAndTaxDetails data={estatedData} ltv={ltv} />
          )}
        </Col>

        <Col xs={{ span: 24, order: 0 }} xl={{ span: 5, order: 1 }}>
          <div className="flex flex-wrap justify-between gap-4 px-4 mb-4 panel xl:flex-col">
            <Button type="text" className="flex-1" onClick={openActivity}>
              Activity Log
              <RightOutlined />
            </Button>
            <div className="flex-1 order-last sm:order-none">
              <ContactStageSelect
                contactId={id}
                contactStage={leadStage}
                subtle={false}
              />
            </div>
            <Button className="flex-1" type="primary" onClick={openActivity}>
              <FileOutlined />
              Add a note
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
