import React, { FC } from "react";
import { campaignEditSteps } from "./CampaignEditor";

interface PanelContent {
  isActive: boolean;
}
export const PanelContent: FC<PanelContent> = ({ children, isActive }) => {
  const defaultClasses = "p-4 border-2 rounded-b-lg space-y-4 ";
  return (
    <div
      className={
        isActive
          ? defaultClasses + "border-blue"
          : defaultClasses + "border-gray-400"
      }
    >
      {children}
    </div>
  );
};
