import React, { useEffect } from "react";
import { notification } from "antd";
import {
  useFlashMessage,
  useClearFlashMessageMutation,
  useV2Signup,
} from "@queries";
import { FlashLevel } from "@types";
import { useNavigate } from "react-router-dom";
import { navigateToCurrentStage } from "@utils/signupHelpers";

export const FlashMessageNotifier = () => {
  const { campaignTypePath, stage } = useV2Signup();
  const { level, message } = useFlashMessage();
  const [clearFlashMessage] = useClearFlashMessageMutation();
  const navigate = useNavigate();

  const placement = "topRight";
  const duration = 15;

  useEffect(() => {
    if (message) {
      console.log("Received flash message ", message);
      if (level === FlashLevel.ERROR) {
        notification.error({
          placement,
          message,
          duration,
        });
        navigateToCurrentStage({ stage, campaignTypePath, navigate });
        setTimeout(() => {
          clearFlashMessage();
        }, 1000);
      }
      if (level === FlashLevel.WARNING) {
        notification.warning({ placement, message, duration });
        navigateToCurrentStage({ stage, campaignTypePath, navigate });
        setTimeout(() => {
          clearFlashMessage();
        }, 1000);
      }
      if (level === FlashLevel.INFO)
        notification.info({ placement, message, duration });
      if (level === FlashLevel.SUCCESS)
        notification.success({ placement, message, duration });
    }
  }, [message]);

  return null;
};
