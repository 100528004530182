import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { MaterialIcon } from "@components";
import { Button } from "antd";

export const BackToZipcodes: FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      className="absolute px-6 bg-white border-0 border-l-8 rounded shadow-md top-4 sm:top-auto sm:bottom-1/4 border-primary"
      onClick={() => navigate("/signup/campaign")}
      type="text"
    >
      <MaterialIcon icon="reply" className="mr-2 align-bottom" />
      Change zip codes
    </Button>
  );
};
