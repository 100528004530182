import { useMemo } from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Agent, SuccessManager, ISOString, ReferralCode } from "@types";
import { RootState } from "@store/store";
import { isAfter } from "date-fns";
import { getCurrentToken } from "@store/authSlice";

export interface UpdatePasswordRequest {
  newPassword: string;
  currentPassword: string;
}

export interface SuccessManagerResponse {
  assignedAt: ISOString;
  assignedUser: number;
  id: number;
  successManager: SuccessManager;
  temporarilyConstructed: boolean;
}

const appApi = createApi({
  reducerPath: "ac-api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: RootState = getState();
      const token = getCurrentToken(state);

      if (token) {
        headers.set("Authorization", `JWT ${token}`);
      } else {
        console.error("No authorization token provided for request.");
      }

      return headers;
    },
  }),
  tagTypes: [
    "Contact",
    "Campaign",
    "Integration",
    "Agent",
    "Activity",
    "CreditCard",
    "RefundLeads",
  ],
  endpoints: (build) => ({
    getAgent: build.query<Agent, void>({
      query: () => "/agents/me/",
      providesTags: ["Agent"],
    }),
    updateAgent: build.mutation<Agent, Partial<Agent>>({
      query: (data) => ({
        url: "/agents/me/",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Agent"],
    }),
    setPassword: build.mutation<Agent, UpdatePasswordRequest>({
      query: ({ newPassword, currentPassword }) => ({
        url: "/auth/users/set_password/",
        method: "POST",
        body: { newPassword, currentPassword },
      }),
    }),
    successManager: build.query<SuccessManager, void>({
      query: () => "/auth/users/me/success-manager-assignments/",
      transformResponse: (data: SuccessManagerResponse[]) => {
        const latestASM = data?.reduce((acc, curr) =>
          isAfter(new Date(acc?.assignedAt), new Date(curr?.assignedAt))
            ? acc
            : curr
        );
        return latestASM?.successManager;
      },
    }),
    referralCodes: build.query<ReferralCode[], void>({
      query: () => "/referral-codes/",
    }),
  }),
});

export const {
  useGetAgentQuery,
  useUpdateAgentMutation,
  useLazyGetAgentQuery,
  useSetPasswordMutation,
  useSuccessManagerQuery,
  useReferralCodesQuery,
} = appApi;
export { appApi };

export const resetApiState = () => appApi.util.resetApiState();
