import React from "react";
import { Typography, Collapse, Button } from "antd";

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const IntegrationsInfo: React.FC = () => (
  <>
    <h1 className="mb-4">Integrations</h1>

    <Paragraph>
      Send lead info straight to your CRM using webhooks and an integration
      platform like Zapier or Integremot
    </Paragraph>
    <Collapse className="mb-2">
      <Panel key="1" header="How do integrations work?">
        <div className="max-w-3xl mx-auto">
          <Paragraph>
            You will need to use an integration platform like Zapier or
            Integremot. We provide an easy way to connect one of these platforms
            to Agent&apos;s Corner via the fields below. These platforms, and
            similar platforms, are already connected to a set of apps, including
            many CRMs.
          </Paragraph>
          <Text>
            You can see if your CRM is supported by Zapier or Integremot here:
          </Text>
          <Button type="link" className="block">
            Is my CRM supported by Zapier?
          </Button>
          <Button type="link" className="block">
            Is my CRM supported by Integremot?
          </Button>
          <Paragraph>
            Directions for Zapier are given below; however, other integration
            platforms follow a similar process.
          </Paragraph>
          <Title level={5}>
            Step 1: Connect Zapier to your Agent&apos;s Corner account
          </Title>
          <div className="ml-2">
            <Paragraph>1. Create an account with Zapier</Paragraph>
            <Paragraph>
              2. Click to create a &quot;zap,&quot; then select
              &quot;webhook&quot;
            </Paragraph>
            <Paragraph>
              3. You will now see Zapier&apos;s webhook. Copy it and paste it in
              the field below, &quot;Paste webhook here.&quot;{" "}
            </Paragraph>
          </div>
          <Title level={5}>Step 2: Connect Zapier to your CRM</Title>
          <div className="ml-2">
            <Paragraph>
              1. Next you should see options of where to connect it
            </Paragraph>
            <Paragraph>
              2. Type in your CRM, and select it. (See if your CRM is supported{" "}
              <a>here</a>.)
            </Paragraph>
            <Paragraph>
              3. Follow the directions on Zapier to finish connecting.
            </Paragraph>
          </div>
        </div>
      </Panel>
      <Panel key="2" header="What is a webhook?">
        <div className="mx-auto" style={{ maxWidth: 800 }}>
          <Paragraph>
            A webhook URL pulled from the destination app (in this case Zapier,
            Integremot or some other integration platform) that is pasted into
            the app you want to send information from (in this case,
            Agent&apos;s Corner.)
          </Paragraph>
          <Paragraph>
            This allows one app to send real-time information to another app,
            which means that every time you win a lead, it&apos;s information
            will be automatically sent to your CRM!
          </Paragraph>
        </div>
      </Panel>
      <Panel
        key="3"
        header="What if my CRM isn't supported by integration platforms?"
      >
        <div className="mx-auto" style={{ maxWidth: 800 }}>
          <Paragraph>
            While Zapier and Integremot support the greatest number of
            CRM&apos;s (with Zapier winning first place,) not every CRM is
            supported by integration platforms. If this happens to be your case,
            we recommend you reach out to us directly, and we will see what we
            can do for your.
          </Paragraph>
          <Button type="link">Reach out to our team</Button>
        </div>
      </Panel>
    </Collapse>
  </>
);

export default IntegrationsInfo;
