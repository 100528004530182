/* eslint-disable react/display-name */
import React from "react";
import { Table, Switch, Row, Empty } from "antd";
import { MaterialIcon, ZipcodeTags } from "@components";
import { useNavigate } from "react-router-dom";
import { Campaign, BidCampaign, Zipcode, CampaignStatus } from "@types";
import "./CampaignsList.less";
import { isActive } from "@utils/conditionals";
import { stripeRowClasses } from "@utils/formatters";

interface CampaignsListProps {
  /**
   * List of labels and values to display
   */
  data: BidCampaign[] | undefined;
}

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    className: "font-bold",
    ellipsis: {
      showTitle: false,
    },
    width: "20%",
  },
  {
    title: "Zip Codes",
    dataIndex: "zipCodes",
    className: "max-w-min",
    render: (allZips: string) => {
      const zipcodes: Zipcode[] = allZips.split(",");
      return <ZipcodeTags zipcodes={zipcodes} />;
    },
    responsive: ["md", "lg", "xl", "xxl"],
    width: "40%",
  },
  {
    title: "Leads",
    dataIndex: "numLeadsWon",
    sorter: (a: Campaign, b: Campaign) => a.numLeadsWon - b.numLeadsWon,
    width: "10%",
  },
  {
    title: "Bid",
    dataIndex: "bidAmount",
    responsive: ["xl", "xxl"],
    sorter: (a: BidCampaign, b: BidCampaign) =>
      parseFloat(a.bidAmount) - parseFloat(b.bidAmount),
    width: "10%",
  },
  // {
  //   title: "Bid Success",
  //   dataIndex: "bidSuccess",
  //   render: (bidSuccess: number) => `%${bidSuccess * 100}`,
  // },
  {
    title: "Budget",
    dataIndex: "monthlyBudget",
    responsive: ["xl", "xxl"],
    sorter: (a: BidCampaign, b: BidCampaign) =>
      parseFloat(a.monthlyBudget) - parseFloat(b.monthlyBudget),
    width: "10%",
  },
  {
    title: "Active",
    dataIndex: "status",
    width: "10%",
    render: (status: string) => (
      <Row justify="space-between">
        <Switch checked={status === CampaignStatus.Active} />
        <MaterialIcon icon="arrow_forward_ios" className="text-gray-300" />
      </Row>
    ),
    sorter: (a: Campaign, b: Campaign) =>
      a.status === b.status ? 0 : isActive(a.status) ? -1 : 1,
    filters: [
      { text: CampaignStatus.Active, value: CampaignStatus.Active },
      { text: "Inactive", value: CampaignStatus.NotActive },
    ],
    onFilter: (value: CampaignStatus, record: Campaign) =>
      record.status.indexOf(value) === 0,
  },
];

/**
 * Campaigns Table
 */
export const CampaignsList: React.FC<CampaignsListProps> = ({
  data,
  ...props
}) => {
  const navigate = useNavigate();

  if (!data?.length)
    return (
      <Empty description="No campaigns yet. Finish signing up to start winning leads." />
    );

  return (
    <Table
      // * TS not recognizing string as Breakpoint for responsive sizings
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      className="custom-antd-thead"
      dataSource={data}
      rowKey={(row) => row.id}
      rowClassName={(_, index) => {
        const baseClasses = "whitespace-nowrap cursor-pointer text-base ";
        return stripeRowClasses(index, baseClasses);
      }}
      tableLayout="fixed"
      // tableLayout="auto"
      sticky
      pagination={false}
      onRow={(record) => {
        return {
          onClick: () => {
            navigate(`/campaigns/${record.id}`);
          },
        };
      }}
      {...props}
    />
  );
};
