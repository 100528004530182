import React, { FC } from "react";
import { Statistic, StatisticProps } from "antd";

/**
 * Shows floating label and value. Must set border color!
 */
export const Stat: FC<StatisticProps> = ({ className, ...props }) => {
  return (
    <Statistic
      className={"pl-4 pr-8 leading-6 border-l-4 " + className}
      {...props}
    />
  );
};
