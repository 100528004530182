import React, { FC } from "react";
import { Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { HOME_BLOG_THUMBNAIL } from "@images";
import { RECOMMENDED_ARTICLE_URL } from "@constants";

const { Text } = Typography;

export const BlogWidget: FC = () => {
  return (
    <div>
      <Text className="mb-1 text-base" strong>
        Recommended for you
      </Text>
      <a
        href={RECOMMENDED_ARTICLE_URL}
        rel="noreferrer"
        target="_blank"
        className="leading-5 hover:text-gray-500"
      >
        <div className="flex bg-white rounded-lg">
          <img src={HOME_BLOG_THUMBNAIL} width={130} className="rounded-l-lg" />
          <div className="p-4">
            <p>How to approach a lead looking for a cash-offer</p>
            <span className="text-blue hover:text-blue-dark">
              Helpful hints from our{" "}
              <span className="whitespace-nowrap">
                blog <RightOutlined />
              </span>
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};
