import React from "react";
import { NUM_SIGNUP_STAGES } from "@constants";
import SignupProgressBanner from "./SignupProgressBanner";
import { useGetAgentQuery } from "@queries";
import { matchStage } from "@utils/signupHelpers";

const SignupProgressBannerContainer: React.FC = () => {
  const { data: agent, isLoading, error } = useGetAgentQuery();
  const isSignupComplete = agent?.isSignupFlowComplete;

  if (isSignupComplete || isLoading || error) {
    return null;
  }

  const currentAgentStage = agent?.v2Signup?.stage;
  const agentCampaignType = agent?.v2Signup?.campaignTypePath;
  const signupStage = matchStage({
    stageName: currentAgentStage,
    campaignTypePath: agentCampaignType,
  });

  return (
    <SignupProgressBanner totalSteps={NUM_SIGNUP_STAGES} stage={signupStage} />
  );
};

export default SignupProgressBannerContainer;
