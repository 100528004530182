import React, { FC } from "react";
import { Button, Layout, Typography, Row, Col } from "antd";

import { useAppDispatch } from "../../../../store/hooks";
import { useMarkJustExploringMutation } from "@queries";
import AccountCreated from "./Account-created.svg";

const { Title } = Typography;

const Verified: FC = () => {
  const { Content } = Layout;
  const dispatch = useAppDispatch();

  const handleExploring = () => markExploring();
  const [markExploring] = useMarkJustExploringMutation();

  return (
    <Layout
      className="p-1 layout"
      style={{
        backgroundImage: `url(${AccountCreated})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <Content className="flex items-center">
        <span className="relative bottom-28">
          <div className="pb-2 text-center">
            <Title level={4}>Your account has been created!</Title>
            <Title level={4}>Now get started with winning introductions.</Title>
          </div>
          <Row justify="center">
            <Col xs={{ order: 1 }} sm={{ order: 0, pull: 1 }} className="mt-1">
              <Button
                href="/"
                key="explore"
                size="large"
                onClick={handleExploring}
                className="ant-btn-secondary"
              >
                No thanks, I&apos;m just exploring
              </Button>
            </Col>
            <Col xs={{ order: 0 }} sm={{ order: 1, push: 1 }} className="mt-1">
              <Button
                href="/signup/campaign/"
                type="primary"
                key="create"
                size="large"
              >
                Next step: Create a campaign
              </Button>
            </Col>
          </Row>
        </span>
      </Content>
    </Layout>
  );
};

export default Verified;
