import { useGetAgentQuery } from "./api";
import { useGetCampaigns } from "./campaigns";
import { BidCampaign } from "@types";

export const selectCampaignById = (
  campaignId: number
): { campaign: BidCampaign | undefined; isLoading: boolean; error: any } => {
  // Will select the post with the given id, and will only rerender if the given campaigns data changes
  const { campaigns, isLoading, error } = useGetCampaigns();
  const campaign = campaigns?.find((campaign) => campaign.id === campaignId);

  return { campaign, isLoading, error };
};

export const useFeatureSpec = () => {
  const { featureSpec } = useGetAgentQuery(undefined, {
    selectFromResult: ({ data }) => ({
      featureSpec: data?.featureSpec,
    }),
  });

  return { ...featureSpec };
};

export const useV2Signup = () => {
  return useGetAgentQuery(undefined, {
    selectFromResult: ({ data }) => ({
      ...data?.v2Signup,
    }),
  });
};

export const useV2SignupSubscriptionCampaign = () => {
  return useGetAgentQuery(undefined, {
    selectFromResult: ({ data }) => ({
      ...data?.v2Signup?.monthlyBudgetHomeSellerCampaign,
    }),
  });
};

export const useSignupSubscriptionOffers = () => {
  return useGetAgentQuery(undefined, {
    selectFromResult: ({ data }) => ({
      ...data?.v2Signup?.monthlyBudgetHomeSellerCampaign?.pendingOfferMetadata,
    }),
  });
};

export const useFlashMessage = () => {
  return useGetAgentQuery(undefined, {
    selectFromResult: ({ data }) => ({
      level: data?.v2Signup?.flashLevel,
      message: data?.v2Signup?.flashMessage,
    }),
  });
};
