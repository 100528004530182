import { authApi } from "@queries";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { PURGE } from "redux-persist";

const slice = createSlice({
  name: "auth",
  initialState: { token: null } as {
    token: null | string;
  },
  reducers: {
    setToken: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      state.token = token;
    },
    removeToken: (state) => {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.token = null;
    }),
      builder.addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.access;
        }
      );
  },
});

export const { setToken, removeToken } = slice.actions;

export default slice.reducer;

export const getCurrentToken = (state: RootState): string | null =>
  state.auth.token;
