import { useState, useEffect, useRef } from "react";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

// export const useComponentVisible = (initialIsVisible: any) => {
//   const [isComponentVisible, setIsComponentVisible] = useState(
//     initialIsVisible
//   );
//   const ref = useRef(null);

//   const handleClickOutside = (event: { target: any }) => {
//     if (ref.current && !ref.current.contains(event.target)) {
//       setIsComponentVisible(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("click", handleClickOutside, true);
//     return () => {
//       document.removeEventListener("click", handleClickOutside, true);
//     };
//   });

//   return { ref, isComponentVisible, setIsComponentVisible };
// };

export const useScreenSize = () => {
  const { sm, md, lg, xl } = useBreakpoint();
  const isSmallScreen = !lg && !xl;
  const isOnlySm = !md && isSmallScreen;
  return { isSmallScreen, isOnlySm };
};
