import React, { FC, useEffect, useState } from "react";
import { Alert, Skeleton } from "antd";
import {
  useFeatureSpec,
  useGetAgentQuery,
  useUpdateAgentMutation,
} from "@queries";
import { AutoDepositBrief } from "./AutoDepositBrief";
import { AutoDepositIntroduction } from "./AutoDepositIntroduction";

export interface AutoDepositChild {
  autoRefreshFundsEnabled: boolean;
  handleSubmit: () => void;
}

export const AutoDeposit: FC = () => {
  const { data: agent, isLoading, error } = useGetAgentQuery();
  const autoRefreshFundsEnabled = agent?.autoRefreshFundsEnabled;
  const hasActivatedCampaign = agent?.hasActivatedCampaign;
  const { agentsAutoDepositFundsSettingVisible } = useFeatureSpec();

  const [updateAgent, { isLoading: updateIsLoading, error: updateError }] =
    useUpdateAgentMutation();

  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const [alert, setAlert] = useState<
    false | { type: "success" | "warning" | "error"; message: string }
  >(false);

  useEffect(() => {
    if (
      autoRefreshFundsEnabled != null &&
      autoRefresh != autoRefreshFundsEnabled
    )
      setAutoRefresh(autoRefreshFundsEnabled);
  }, [autoRefreshFundsEnabled]);

  const handleSubmit = () => {
    const newRefreshValue = !autoRefresh;
    setAutoRefresh(newRefreshValue);
    updateAgent({
      autoRefreshFundsEnabled: newRefreshValue,
    })
      .unwrap()
      .then((payload) => {
        console.log("updateAgent fulfilled: ", payload);
        setAlert({
          type: newRefreshValue ? "success" : "warning",
          message: `Auto Deposit ${newRefreshValue ? "enabled" : "disabled"}`,
        });
      })
      .catch((error) => {
        console.log("updateAgent rejected: ", error);
        setAlert({
          type: "error",
          message: "Unable to update Auto-Deposit setting, please try again.",
        });
        setAutoRefresh((state) => !state);
      });
  };

  if (isLoading) return <Skeleton.Button active={true} size="large" block />;
  if (agentsAutoDepositFundsSettingVisible)
    return (
      <div className="text-base">
        {alert && (
          <Alert
            className="p-4"
            key="autoDepositAlert"
            type={alert.type}
            message={alert.message}
            closable
            afterClose={() => setAlert(false)}
          />
        )}

        {hasActivatedCampaign ? (
          <AutoDepositBrief
            autoRefreshFundsEnabled={autoRefresh}
            handleSubmit={handleSubmit}
          />
        ) : (
          <AutoDepositIntroduction
            autoRefreshFundsEnabled={autoRefresh}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    );
  return null;
};
