import { appApi } from "./api";

import {
  Agent,
  BidCampaign,
  CampaignTypePath,
  FlashLevel,
  SignupStage,
  SubscriptionCampaign,
} from "@types";

export interface CampaignLocationRequest {
  zipCodes: string;
  cityStateToZipCodes: string;
}

export interface AddPaymentInfoBidSignupRequest {
  amount: string;
  token: string; // Stripe card token
}

export interface AddPaymentInfoSubscriptionSignupRequest {
  offer: number;
  token: string;
}

export interface V2SignupMutationResponse {
  // Response structure for the majority of V2 endpoints when posting
  agent: Agent;
  stage: SignupStage;
  phonenumberVerified: boolean;
  emailVerified: boolean;
  campaignTypePath: CampaignTypePath;
  homeSellerCampaign: BidCampaign;
  monthlyBudgetHomeSellerCampaign: SubscriptionCampaign;
  minRemainingToDeposit: number;
  isVerificationComplete: boolean;
  isComplete: boolean;
  flashMessage: string;
  flashLevel: FlashLevel;
}

export interface SetCampaignSubscriptionResponse {
  monthlyBudget: string;
  monthlyExpectedNumLeads: number;
}

const v2SignupBaseUrl = "/agents-v2-signup";

export const onboardingApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    checkPhonenumberVerification: build.mutation<
      V2SignupMutationResponse,
      { code: string }
    >({
      query: (data) => ({
        url: `${v2SignupBaseUrl}/check-phonenumber-verification/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Agent"],
    }),
    resendPhonenumberVerification: build.mutation<
      unknown,
      { phonenumber: string }
    >({
      query: (data) => ({
        url: `${v2SignupBaseUrl}/resend-phonenumber-verification/`,
        method: "POST",
        body: data,
      }),
    }),
    resendEmailVerification: build.mutation<
      V2SignupMutationResponse,
      { email: string }
    >({
      query: (data) => ({
        url: `${v2SignupBaseUrl}/resend-email-verification/`,
        method: "POST",
        body: { ...data, fromSource: "web", fromSubSource: "" },
      }),
      invalidatesTags: ["Agent"],
    }),
    setCampaignLocation: build.mutation<
      V2SignupMutationResponse,
      CampaignLocationRequest
    >({
      query: ({ zipCodes, cityStateToZipCodes }) => ({
        url: `${v2SignupBaseUrl}/set-campaign-location/`,
        method: "POST",
        body: {
          zipCodes,
          cityStateToZipCodes,
        },
      }),
      invalidatesTags: ["Agent"],
    }),
    addPaymentInfo: build.mutation<
      V2SignupMutationResponse,
      AddPaymentInfoBidSignupRequest | AddPaymentInfoSubscriptionSignupRequest
    >({
      query: (paymentInfo) => ({
        url: `${v2SignupBaseUrl}/add-payment-info/`,
        method: "POST",
        body: { ...paymentInfo },
      }),
      invalidatesTags: ["Agent"],
    }),
    setCampaignBid: build.mutation<V2SignupMutationResponse, unknown>({
      query: (data) => ({
        url: `${v2SignupBaseUrl}/set-campaign-bid/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Agent"],
    }),
    recommendBid: build.mutation<unknown, void>({
      query: () => ({
        url: "recommend-bid",
        method: "POST",
        body: {},
      }),
    }),
    setCampaignSubscription: build.mutation<
      V2SignupMutationResponse,
      { offerId: number }
    >({
      query: ({ offerId }) => ({
        url: `${v2SignupBaseUrl}/set-monthly-budget-campaign-monthly-budget/`,
        method: "POST",
        body: { offer: offerId },
      }),
      // TODO: Use validateStatus
      invalidatesTags: ["Agent"],
    }),
    activateSignupCampaign: build.mutation<V2SignupMutationResponse, void>({
      query: () => ({
        url: `${v2SignupBaseUrl}/activate-campaign/`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Agent"],
    }),
    markSkip: build.mutation<void, SignupStage>({
      query: (stage) => ({
        url: `${v2SignupBaseUrl}/mark-skip/`,
        method: "POST",
        body: { stage },
      }),
    }),
    markJustExploring: build.mutation<void, void>({
      query: () => ({
        url: `${v2SignupBaseUrl}/mark-just-exploring/`,
        method: "POST",
        body: {},
      }),
    }),
    clearFlashMessage: build.mutation<void, void>({
      query: () => ({
        url: `${v2SignupBaseUrl}/clear-flash-message/`,
        method: "POST",
        body: {},
      }),
    }),
  }),
});

export const {
  useCheckPhonenumberVerificationMutation,
  useResendPhonenumberVerificationMutation,
  useResendEmailVerificationMutation,
  useSetCampaignLocationMutation,
  useAddPaymentInfoMutation,
  useSetCampaignBidMutation,
  useActivateSignupCampaignMutation,
  useMarkSkipMutation,
  useMarkJustExploringMutation,
  useSetCampaignSubscriptionMutation,
  useClearFlashMessageMutation,
  useRecommendBidMutation,
} = onboardingApi;
