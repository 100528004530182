import React, { FC } from "react";
import { useAppDispatch } from "@store/hooks";
import { Form, Input, Layout, Image, Button } from "antd";
import { setToken } from "@store/authSlice";
import { useNavigate } from "react-router-dom";
import { images } from "@constants";
import { resetApiState } from "@queries";

export const LoginAsAgent: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loginAsAgent = (token: { token: string }) => {
    dispatch(resetApiState());
    dispatch(setToken(token));
    setTimeout(() => {
      navigate("/");
    }, 100);
  };
  return (
    <Layout className="items-center justify-center min-h-screen">
      <div className="max-w-xs">
        <Image
          src={images.AGENTS_CORNER_LOGO}
          preview={false}
          className="max-w-sm mb-8"
        />
      </div>
      <h2>Login As Agent</h2>
      <Form form={form} onFinish={loginAsAgent}>
        <Form.Item name="token" rules={[{ required: true }]}>
          <Input placeholder="token" />
        </Form.Item>
        <Button htmlType="submit" block>
          Submit
        </Button>
      </Form>
    </Layout>
  );
};
