import React, { FC, useState, useEffect } from "react";
import { Button, Layout, Typography, Space, Col, Row, Modal } from "antd";
import { AppleFilled, AndroidFilled } from "@ant-design/icons";
import { Image } from "antd";
import confetti from "canvas-confetti";
import { AGENTS_CORNER_PHONENUMBER } from "@constants";
import { AGENTS_CORNER_LOGO_PNG, CAMPAIGN_LIVE, COPY_ICON } from "@images";

import styles from "./SignupComplete.m.less";
import { formatPhonenumber } from "@utils/formatters";
import { Link } from "react-router-dom";

const { Text, Title, Paragraph } = Typography;

const SignupComplete: FC = () => {
  const { Content } = Layout;

  const [copySuccess, setCopySuccess] = useState("");
  const [showModal, setShowModal] = useState(false);

  const copyPhoneToClipboard = () => {
    navigator.clipboard.writeText(AGENTS_CORNER_PHONENUMBER);
    setCopySuccess(" Copied!");
    setTimeout(() => setCopySuccess(""), 1000);
  };

  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function fire(particleRatio: number, opts: any) {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  }

  useEffect(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, []);

  return (
    <Layout className="layout">
      <Content className={styles.container}>
        <Space key="a" direction="vertical" size="large" align="center" wrap>
          <Image src={AGENTS_CORNER_LOGO_PNG} preview={false} width={120} />
          <div className="text-center">
            <Title level={3}>Your Campaign is Live!</Title>
            <Text style={{ fontSize: 18 }}>
              Here&apos;s what to expect when you win a lead:
            </Text>
          </div>
          <Row gutter={[8, 8]} className="pt-4">
            <Col xs={24} sm={12}>
              <Image src={CAMPAIGN_LIVE} preview={false} />
            </Col>
            <Col xs={24} sm={12}>
              <div className="flex flex-col h-full">
                <div>
                  <Title level={5}>SMS Introduction</Title>
                  <Paragraph>
                    We will introduce you in a group text. We recommend you save
                    our number.
                  </Paragraph>
                  <Button
                    type="text"
                    className="mb-4 rounded-full bg-gray-50"
                    onClick={() => copyPhoneToClipboard()}
                  >
                    <Image
                      src={COPY_ICON}
                      height={14}
                      style={{ marginRight: 4 }}
                      preview={false}
                    />
                    copy: &nbsp; {formatPhonenumber(AGENTS_CORNER_PHONENUMBER)}
                    <Text className="text-success">&nbsp;{copySuccess}</Text>
                  </Button>
                </div>
                <div className="mt-auto mb-1">
                  <Title level={5} className="">
                    Email Introduction
                  </Title>
                  <Text>We will also introduce you in an email.</Text>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className="w-full p-4 rounded-lg"
            style={{ backgroundColor: "#CBD0D5" }}
            align="middle"
          >
            <div>
              <Text>Stay on top of leads with push notifications.</Text>
              <br />
              <Text>Download the app</Text>
              <span className="ml-2 align-bottom material-icons">east</span>
            </div>

            {/* Apple App Store link */}
            <a
              href="https://apps.apple.com/us/app/agents-corner/id1490499879?itsct=apps_box_badge&amp;itscg=30200"
              target="_blank"
              rel="noreferrer"
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: 13,
                width: 130,
                marginLeft: 10,
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1582243200&h=834889d33ecd1978c2d01247f38ac071"
                alt="Download on the App Store"
                style={{ borderRadius: 5, width: 130 }}
              />
            </a>
            {/* Google Play Store link */}
            <a
              href="https://play.google.com/store/apps/details?id=com.agentscorner.app&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                width={160}
                style={{ imageRendering: "-webkit-optimize-contrast" }}
              />
            </a>

            <div
              className="p-1 px-2 bg-black rounded-lg cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              <span className="text-3xl text-white material-icons">
                qr_code
              </span>
            </div>
          </Row>
          <Link to="/">
            <Button className="mb-4 w-80" type="primary" size="large">
              Go to dashboard
            </Button>
          </Link>
        </Space>
        <Modal
          visible={showModal}
          closable
          width={850}
          onCancel={() => setShowModal(false)}
          centered
          footer={null}
        >
          <Row justify="center" align="middle" className="py-4">
            <Col>
              <div
                style={{ height: 300, width: 300 }}
                className="flex items-center justify-center"
              >
                <img
                  src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/5f09eb6b1acdd216f2e01daa77377d2f/a27e3e3ec6a1a41a0e6522b4a074a175.png"
                  width={250}
                  height={250}
                />
              </div>
              <div className="text-center">
                <AppleFilled style={{ fontSize: 48 }} />
              </div>
            </Col>
            <Col>
              <img
                src="https://chart.apis.google.com/chart?chs=300x300&cht=qr&chl=https://play.google.com/store/apps/details?id=com.agentscorner.app&hl=en_US&gl=US"
                style={{ height: 300 }}
              />
              <div className="text-center">
                <AndroidFilled style={{ fontSize: 48 }} />
              </div>
            </Col>
          </Row>
        </Modal>
      </Content>
    </Layout>
  );
};

export default SignupComplete;
