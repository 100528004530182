import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Form,
  Layout,
  Typography,
  Space,
  notification,
  Image,
  Input,
  Button,
  Spin,
  Row,
  Alert,
} from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";
import PhoneNumberInput from "../../../component/PhoneNumberInput";
import { useNavigate } from "react-router-dom";

import styles from "./PhonenumberVerification.m.less";
import {
  useCheckPhonenumberVerificationMutation,
  useGetAgentQuery,
  useResendPhonenumberVerificationMutation,
} from "@queries";

const { Text, Paragraph } = Typography;

const PhonenumberVerification: FC = () => {
  const { Content } = Layout;
  const navigate = useNavigate();

  const [
    checkPhonenumberVerification,
    { data: checkResp, isLoading: checkIsLoading, error: checkError },
  ] = useCheckPhonenumberVerificationMutation();

  const errorMessage =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    checkError?.data?.phonenumber || checkError?.data?.nonFieldErrors;

  const [
    resendPhonenumberVerification,
    { data: resendResp, isLoading: resendIsLoading, error: resendError },
  ] = useResendPhonenumberVerificationMutation();

  const handleVerifySubmit = useCallback((values) => {
    checkPhonenumberVerification(values)
      .unwrap()
      .then((payload) => {
        navigate("/signup/verify-email");
      });
  }, []);

  const handleResendSubmit = useCallback((values) => {
    resendPhonenumberVerification(values)
      .unwrap()
      .then((payload) => {
        setShowResendForm(false);
        notifyResendSuccess();
      })
      .catch((error) => {
        const errorResponse =
          error?.data?.phonenumber || error?.data?.nonFieldErrors;
        // if (errorMessage) notifyFailure(errorMessage);
        if (errorResponse) notifyFailure(errorResponse);
        else notifyResendFailure();
      });
  }, []);

  const notifyResendSuccess = useCallback(() => {
    notification.success({
      message: `We've just texted you! Check your phone for the code.`,
      placement: "bottomLeft",
      style: { padding: 8 },
    });
  }, [notification]);

  const notifyResendFailure = useCallback(() => {
    notification.error({
      message: `There was a problem with verifying your phonenumber, please try again.`,
      placement: "bottomLeft",
      style: { padding: 8 },
      duration: 6,
    });
  }, [notification]);

  const notifyFailure = useCallback(
    (errMsg) => {
      notification.error({
        message: errMsg,
        placement: "bottomLeft",
        duration: 10,
      });
    },
    [notification]
  );

  const [showResendForm, setShowResendForm] = useState(false);

  return (
    <OnboardingLayout title={"Verify Your Phone Number"} step={2}>
      <Content className={styles.container}>
        <Space key="a" direction="vertical" size="small" align="start" wrap>
          {showResendForm ? (
            <ResendCodeForm
              onSubmit={handleResendSubmit}
              onCancel={() => setShowResendForm(false)}
              isLoading={resendIsLoading}
            />
          ) : (
            <VerifyCodeForm
              onSubmit={handleVerifySubmit}
              onResendRequest={() => setShowResendForm(true)}
              isLoading={checkIsLoading}
              errorMessage={errorMessage}
            />
          )}
        </Space>
        <Space
          key="b"
          className={styles.imageContainer}
          direction="vertical"
          wrap={false}
          align="center"
        >
          <Image
            src="/images/svgs/next_verify_phonenumber.svg"
            preview={false}
          />
        </Space>
      </Content>
    </OnboardingLayout>
  );
};

export default PhonenumberVerification;

interface ResendCodeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const ResendCodeForm = ({ onCancel, onSubmit, isLoading }: ResendCodeProps) => {
  const [form] = Form.useForm();

  return (
    <>
      <Text style={{ fontSize: 18 }}>
        Please double-check to make sure your phone number is correct, then
        resend the code.
      </Text>
      <Row>
        <Form
          key="a"
          form={form}
          layout="inline"
          name="resendPhoneVerify"
          requiredMark={"optional"}
          onFinish={onSubmit}
          className={"form"}
          validateTrigger="onSubmit"
        >
          <Form.Item
            key="b"
            name="phonenumber"
            className="mt-1"
            rules={[
              {
                required: true,
                message: "Please input a valid phonenumber",
              },
            ]}
          >
            <PhoneNumberInput
              size="large"
              placeholder={"( - - - ) - - -  - - - -"}
            />
          </Form.Item>
          <Form.Item key="c" className="mt-1">
            <Spin spinning={isLoading}>
              <Button type="primary" htmlType="submit" block size="large">
                Send Code
              </Button>
            </Spin>
          </Form.Item>
          <Button
            size="large"
            title="Cancel"
            className="mt-1"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Form>
      </Row>
    </>
  );
};

interface VerifyCodeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: any) => void;
  onResendRequest: () => void;
  isLoading: boolean;
  errorMessage: string;
}

const VerifyCodeForm = ({
  onSubmit,
  onResendRequest,
  isLoading,
  errorMessage,
}: VerifyCodeProps) => {
  const [form] = Form.useForm();
  const { data: agent } = useGetAgentQuery();
  const phonenumber = agent?.user?.phonenumber;

  const formattedPhonenumber =
    phonenumber &&
    `${phonenumber.slice(0, 2)} (${phonenumber.slice(
      2,
      5
    )}) ${phonenumber.slice(5, 8)}-${phonenumber.slice(8, 12)}`;

  return (
    <>
      <Paragraph style={{ fontSize: 18 }}>
        Let&apos;s verify your phone number so that we can instantly introduce
        you to home sellers by text message.
      </Paragraph>
      <Text style={{ fontSize: 18 }}>
        We have texted a 6-digit code to{" "}
        {formattedPhonenumber ? <b>{formattedPhonenumber}</b> : "you"}. Please
        enter the code.
      </Text>

      <Form
        key="a"
        form={form}
        layout="inline"
        name="phoneVerifyForm"
        requiredMark={"optional"}
        onFinish={onSubmit}
        className={"form"}
        validateTrigger="onSubmit"
      >
        <Form.Item
          key="b"
          name="code"
          className="mt-1"
          rules={[
            {
              required: true,
              message: "Please input your verification code",
            },
            {
              len: 6,
              message: "Please enter a valid 6 digit code",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item key="c" className="mt-1">
          <Spin spinning={isLoading}>
            <Button type="primary" htmlType="submit" block size="large">
              Continue
            </Button>
          </Spin>
        </Form.Item>
        <Button
          type="text"
          className="mt-1 link-secondary"
          size="large"
          onClick={onResendRequest}
        >
          Resend Code
        </Button>
      </Form>

      {errorMessage && (
        <Space className={styles.alertContainer}>
          <Alert message={errorMessage} type="error" showIcon closable />
        </Space>
      )}
    </>
  );
};
