import React, { FC, useCallback, useMemo, useState, useEffect } from "react";
import { Layout, Grid } from "antd";
import { ContactsList } from "@components/ContactsList/ContactsList";
import { useGetContactsQuery } from "@queries";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { setPage } from "@store/contactsPaginationSlice";
import { usePrevious } from "ahooks";
import { GenericError } from "@components";
import { useScreenSize } from "@utils/hooks";

const { Sider, Content } = Layout;

export const ContactsPage: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { page, pageSize, ordering } = useAppSelector(
    (state) => state.contactsPagination
  );

  const { data, error, isLoading, isFetching } = useGetContactsQuery({
    limit: pageSize,
    offset: (page - 1) * pageSize,
    ordering,
  });
  const contacts = data?.results;

  const [firstId, setFirstId] = useState<number>();
  const prevFirstId = usePrevious(firstId);

  const isNewPage = useMemo(
    () => contacts?.[0]?.id !== prevFirstId,
    [prevFirstId, contacts]
  );

  useEffect(() => {
    // Load/Navigate to first lead in page only if we switched pages
    if (
      contacts?.length &&
      !(state as { fromDashboard?: boolean })?.fromDashboard &&
      isNewPage
    ) {
      setFirstId(contacts[0].id);
      navigate(`${contacts[0].id}`);
    }
  }, [data]);

  const handlePageChange = useCallback((page) => {
    dispatch(setPage(page));
  }, []);

  if (error) {
    console.error("ContactsQuery: ", error);
    return <GenericError />;
  }

  const { isSmallScreen, isOnlySm } = useScreenSize();

  return (
    <Layout className="">
      <div className="absolute top-0 w-16 bg-white -left-32" />
      <Sider
        className="z-50 max-w-md"
        style={{ backgroundColor: "white", zIndex: 9999 }}
        width={isOnlySm ? "80vw" : isSmallScreen ? "40vw" : "30vw"}
        breakpoint="md"
        collapsedWidth="0"
        // zeroWidthTriggerStyle={{ position: "sticky", top: 20 }}
      >
        <div className="w-full bg-white">
          <div className="float-right max-w-xs">
            <ContactsList
              data={data?.results}
              isLoading={isLoading || isFetching}
              onContactClick={(contactId) => {
                navigate(`${contactId}`);
              }}
              totalContacts={data?.count}
              currentPage={page}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </Sider>
      <Content className="p-4 mx-auto overflow-hidden max-w-7xl">
        <Outlet />
      </Content>
    </Layout>
  );
};
