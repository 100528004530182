import React, { useMemo } from "react";
import { Row } from "antd";
import { formatDateString, formatPhonenumber } from "@utils/formatters";
import { CopyableButton, MaterialIcon } from "..";

interface ContactInfoProps {
  /**
   * Contact first name
   */
  firstName: string;
  /**
   * Contact last name
   */
  lastName: string;
  /**
   * Contact phone number
   */
  primaryPhone: string;
  /**
   * Contact email
   */
  email: string;
  /**
   * Property street address
   */
  propertyAddress: string;
  /**
   * Property address city
   */
  city: string;
  /**
   * Property address state
   */
  state: string;
  /**
   * Contact address zipcode
   */
  zipCode: string;
  /**
   * Created date time string
   */
  created: string;
}

/**
 * Primary UI component for user interaction
 */
export const ContactInfo: React.FC<ContactInfoProps> = ({
  firstName,
  lastName,
  primaryPhone,
  email,
  propertyAddress,
  city,
  state,
  zipCode,
  created,
}) => {
  const date = useMemo(() => formatDateString(created), [created]);
  const phonenumber = useMemo(
    () => formatPhonenumber(primaryPhone),
    [primaryPhone]
  );

  return (
    <div className="pb-12 text-center rounded-lg panel">
      <div className="text-sm text-right" style={{ color: "#697183" }}>
        Acquired {date}
      </div>

      <div className="pt-8 text-2xl font-extrabold text-black">
        {firstName} {lastName}
      </div>
      <p className="text-xl leading-6" style={{ color: "#2F76AC" }}>
        {propertyAddress}, {city}, {state} {zipCode}
      </p>
      <hr className="max-w-md mx-auto my-4" />
      <Row justify="center" className="text-lg">
        <CopyableButton copyText={phonenumber} className="font-bold text-black">
          <MaterialIcon icon="phone" className="mr-2 align-top text-blue" />
          {phonenumber}
        </CopyableButton>

        <CopyableButton copyText={email} className="font-bold text-black">
          <MaterialIcon icon="email" className="mr-2 align-top text-blue" />
          {email}
        </CopyableButton>
      </Row>
    </div>
  );
};
