import { ContactsOrdering } from "@queries";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ContactsPagination {
  page: number;
  pageSize: number;
  previousPage: number;
  isActivityOpen: boolean;
  ordering?: ContactsOrdering;
}

const initialState: ContactsPagination = {
  page: 1,
  pageSize: 20,
  previousPage: 1,
  ordering: ContactsOrdering.newest,
  isActivityOpen: false,
};

export const contactsPaginationSlice = createSlice({
  name: "contactsPagination",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.previousPage = state.page;
      state.page = action.payload;
    },
    setIsActivityOpen: (state, action: PayloadAction<boolean>) => {
      state.isActivityOpen = action.payload;
    },
  },
});

export const { setPage, setIsActivityOpen } = contactsPaginationSlice.actions;

export default contactsPaginationSlice.reducer;
