import React, { FC } from "react";
import { useSuccessManagerQuery } from "@queries";
import { Result, Spin } from "antd";
import { ASMWidgetCompact } from "@components";

export const ASMWidgetBlock: FC = () => {
  const {
    data: successManager,
    isLoading: isLoadingASM,
    error: isErrorASM,
  } = useSuccessManagerQuery();

  if (isErrorASM)
    return (
      <div className="panel">
        <Result
          status="warning"
          title="Error retrieving your success manager."
        />
      </div>
    );

  if (isLoadingASM || !successManager)
    return (
      <div className="flex panel">
        <Spin spinning className="py-4 mx-auto" />
      </div>
    );

  return (
    <div className="px-4 panel md:px-8">
      <h2 className="font-bold">Need to make a change?</h2>
      <div className="mb-4">
        We&apos;re here to help. Reach out to your success manager today.
      </div>
      <ASMWidgetCompact {...successManager} />
    </div>
  );
};
