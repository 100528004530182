import React, { useMemo } from "react";
import { Stat } from "..";

interface CampaignStatTotals {
  data:
    | {
        totalSpent: number;
        totalLeadsWon: number;
        totalWeeklyBudget: number;
      }
    | undefined;
}

export const CampaignStatTotals: React.FC<CampaignStatTotals> = ({
  data = { totalSpent: 0, totalLeadsWon: 0, totalWeeklyBudget: 0 },
}) => {
  const { totalSpent, totalLeadsWon, totalWeeklyBudget } = data;
  const budgetValueOrText = useMemo(
    () => (totalWeeklyBudget > 0 ? totalWeeklyBudget?.toFixed(2) : "None"),
    [totalWeeklyBudget]
  );

  return (
    <>
      <Stat
        className="pl-4 pr-8 leading-6 border-l-4 border-green"
        title="Total Won"
        value={totalLeadsWon}
        suffix="Leads"
      />
      <Stat
        className="pl-4 pr-8 leading-6 border-l-4 border-green"
        title="Total Spent"
        value={totalSpent?.toFixed(2)}
        prefix="$"
      />
      <Stat
        className="pl-4 pr-8 leading-6 border-l-4 border-blue"
        title="Weekly Budget"
        value={budgetValueOrText}
        prefix={budgetValueOrText === "None" ? "" : "$"}
      />
    </>
  );
};
