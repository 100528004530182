import React from "react";
// import { getToken, removeToken } from "@utils/auth";
import { Navigate, useLocation } from "react-router-dom";
import { getCurrentToken, removeToken } from "@store/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/store";
import { resetApiState } from "@queries";

/*
 * Wrapper component that will validate auth status before returning underlying routes (or redirect to login)
 */
export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  // const token = getToken();
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => getCurrentToken(state));
  const location = useLocation();

  if (!token) {
    dispatch(removeToken());
    dispatch(resetApiState());
    localStorage.clear();
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};
