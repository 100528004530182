import React, { FC } from "react";
import { Row, Col, Image, Typography, Switch } from "antd";
import { AUTO_DEPOSIT } from "@images";
import { AutoDepositChild } from "./AutoDeposit";
import { AUTO_DEPOSIT_HELP_URL } from "@constants";

const { Title, Text } = Typography;

export const AutoDepositIntroduction: FC<AutoDepositChild> = ({
  autoRefreshFundsEnabled,
  handleSubmit,
}) => (
  <div className="py-8 panel">
    <Row>
      <Col xs={24} md={4} className="flex justify-center">
        <Image src={AUTO_DEPOSIT} width={108} />
      </Col>
      <Col xs={24} md={20}>
        <div className="pl-4 mt-4 md:mt-0">
          <Title style={{ fontSize: 16 }}>
            Stay Competitive with Auto Deposit
          </Title>
          <Text className="ac-text-16">
            Keep this on to prevent your balance from ever dropping too low and
            missing out on leads.
          </Text>
          <br />
          <a
            className="link-secondary"
            href={AUTO_DEPOSIT_HELP_URL}
            rel="noreferrer noopener"
            target="_blank"
          >
            <b>Learn More</b>
          </a>
          <hr className="my-2" />
          <Switch
            className="mr-2"
            checked={autoRefreshFundsEnabled}
            onChange={handleSubmit}
          />
          <Text className="ac-text-16">
            <b>Auto Deposit </b> is turned{" "}
            {autoRefreshFundsEnabled ? "on" : "off"}
          </Text>
        </div>
      </Col>
    </Row>
  </div>
);
