import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";

import { GMapsQuery } from "@types";
import { useBoolean, useExternal } from "ahooks";

interface SearchLocationInputProps {
  /**
   * function to set zipcodes from query
   */
  onChange: (zipcodes: GMapsQuery) => void;
  /**
   * for manually entered zipcodes
   */
  onAddZipCode: (zipcodes: string) => void;
  /**
   * api call to fetch zip codes
   */
  fetchZipCodes: ({ city, state }: { city: string; state: string }) => Promise<{
    zipCodes: string[];
  }>;
}

/**
 * Uses Places API to auto complete locations and set zipcode results
 */
export const SearchLocationInput: React.FC<SearchLocationInputProps> = ({
  onChange,
  onAddZipCode,
  fetchZipCodes,
}) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const [isLoaded, { setTrue }] = useBoolean(false);

  const checkValidZipCode = (q: string) => {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(q);
  };

  const [status, { load, unload }] = useExternal(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    { type: "js" }
  );

  // console.log("Script status: ", status);

  useEffect(() => {
    load();
    return () => {
      unload();
    };
  }, []);

  useEffect(() => {
    if (status === "ready" && !isLoaded) {
      handleScriptLoad(setQuery, onChange, autoCompleteRef, fetchZipCodes);
      setTrue();
    }
  }, [status, fetchZipCodes]);
  //   useEffect(() => {
  //     loadScript(
  //       `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  //       () => handleScriptLoad(setQuery, onChange, autoCompleteRef)
  //     );
  //   }, [onChange]);

  const addZipCode = useCallback(() => {
    onAddZipCode(query);
  }, [query]);

  const addButton = useMemo(() => {
    const isDisabled = !checkValidZipCode(query);
    return (
      <span
        onClick={isDisabled ? () => null : addZipCode}
        style={{ fontSize: 56 }}
        className={`ml-4 material-icons align-middle ${
          isDisabled ? "text-gray-200" : "text-primary"
        } ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
      >
        add_box
      </span>
    );
  }, [query]);

  return (
    <div>
      <input
        className="w-full h-10 max-w-xs p-4 align-middle border-2 rounded-lg"
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Enter a City or Zip Code"
        value={query}
      />
      {addButton}
    </div>
  );
};

export let autoComplete: any;

const PLACE_PARAMETERS = {
  LOCALITY: "locality",
  STATE: "administrative_area_level_1",
};

function getCityState(address: any) {
  return address.reduce((addressAccumulator: any, curr: any) => {
    const [type] = curr.types;
    if (type === PLACE_PARAMETERS.LOCALITY) {
      return { ...addressAccumulator, city: curr.long_name };
    } else if (type === PLACE_PARAMETERS.STATE) {
      return { ...addressAccumulator, state: curr.short_name };
    }
    return addressAccumulator;
  }, {});
}

function handleScriptLoad(
  updateQuery: React.Dispatch<React.SetStateAction<string>>,
  onChange: any,
  autoCompleteRef: React.MutableRefObject<null>,
  fetchZipCodes: ({ city, state }: { city: string; state: string }) => Promise<{
    zipCodes: string[];
  }>
) {
  autoComplete = new (window as any).google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(cities)"], componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "place_id",
  ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, onChange, fetchZipCodes)
  );
}

async function handlePlaceSelect(
  updateQuery: React.Dispatch<React.SetStateAction<string>>,
  onChange: any,
  fetchZipCodes: ({ city, state }: { city: string; state: string }) => Promise<{
    zipCodes: string[];
  }>
) {
  const addressObject = autoComplete.getPlace();
  const id = addressObject.place_id;
  const query = addressObject.formatted_address;
  const address = addressObject.address_components;
  if (address) {
    try {
      const { city, state } = getCityState(address);
      const { zipCodes } = await fetchZipCodes({ city, state });
      updateQuery(query);
      onChange({ id, query, state, codes: zipCodes });
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(`Place input error: ${err.message}`);
    }
  }
}
