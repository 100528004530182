import React, { FC, useCallback } from "react";
import {
  Layout,
  Row,
  InputNumber,
  Form,
  notification,
  Button,
  Spin,
  Col,
} from "antd";
import {
  useGetAgentQuery,
  useActiveCreditCardQuery,
  useAddBalanceMutation,
} from "@queries";
import { CreditCardInfo, Loading, ErrorAlert, EditButton } from "@components";
import { useNavigate } from "react-router-dom";
import { AutoDeposit } from "@containers";
import { extractListObjectError } from "@queries/helpers";

const { Content } = Layout;

export const DepositFunds: FC = () => {
  const [form] = Form.useForm();
  const {
    data: agent,
    isLoading: isLoadingAgent,
    error: agentError,
  } = useGetAgentQuery();
  const {
    data: creditCard,
    isLoading: cardIsLoading,
    error: cardError,
  } = useActiveCreditCardQuery();
  const [
    addBalance,
    {
      data: balanceResponse,
      isLoading: isAddingBalance,
      error: addBalanceError,
    },
  ] = useAddBalanceMutation();
  const navigate = useNavigate();
  const addBalanceErrorMsg =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    addBalanceError && extractListObjectError(addBalanceError?.data);

  const navigateToPayment = useCallback(() => {
    navigate("/settings", { state: { toPayment: true } });
  }, [navigate]);

  const handleDepositFunds = useCallback(
    (values) => {
      if (!creditCard) {
        notification.error({
          message: "Can not deposit funds. Please add credit card.",
        });
        return;
      }

      addBalance({ amount: values?.amount, creditCard: creditCard.id })
        .unwrap()
        .then((payload) => {
          notification.success({
            placement: "topRight",
            message: `Successfully added $${values?.amount} to your account.`,
          });
        })
        .catch((error) => {
          notification.error({
            message: "Unable to deposit funds to your account.",
          });
        });
    },
    [creditCard, addBalance, notification]
  );

  return (
    <Content className="container max-w-4xl py-8 mx-auto">
      <h1 className="page-title">Deposit</h1>
      <Row justify="space-between">
        <h2>Active Card</h2>
        <EditButton onClick={navigateToPayment} />
      </Row>
      <div className="mb-8 panel">
        {cardIsLoading ? (
          <Loading />
        ) : cardError ? (
          <ErrorAlert message="There was a problem loading your card." />
        ) : (
          <CreditCardInfo {...creditCard!} />
        )}
      </div>
      {addBalanceErrorMsg && <ErrorAlert message={addBalanceErrorMsg} />}
      <Row justify="space-around" className="py-8 panel">
        <Col>
          <h2>Deposit Amount</h2>
          <Form
            form={form}
            onFinish={handleDepositFunds}
            initialValues={{ amount: 100 }}
          >
            <Form.Item name="amount">
              <InputNumber
                className="w-40"
                prefix="$"
                size="large"
                min={50}
                max={15000}
                step={50}
              />
            </Form.Item>
            <Spin spinning={isAddingBalance}>
              <Button size="large" htmlType="submit">
                Deposit Funds
              </Button>
            </Spin>
          </Form>
        </Col>
        <Col>
          <h2>Current Balance</h2>
          {isLoadingAgent ? (
            <Loading />
          ) : (
            <div className="text-xl">${agent?.balance}</div>
          )}
        </Col>
      </Row>

      <hr className="my-8" />
      <AutoDeposit />
    </Content>
  );
};
