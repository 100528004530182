import React from "react";
import { Spin, SpinProps } from "antd";

export const Loading: React.FC<SpinProps> = (props) => {
  return (
    <div className="flex items-center justify-center py-12">
      <Spin spinning {...props} />
    </div>
  );
};
