import React, { FC } from "react";
import { ReferralCode } from "@types";
import { CopyableButton, MaterialIcon } from "..";
import { Row } from "antd";

/**
 * Referral Dashboard Alert/Message
 */
export const ReferralDashboardWidget: FC<ReferralCode> = ({
  code,
  referringAgentAmount,
  referredAgentAmount,
}) => {
  return (
    <div
      className="flex flex-col font-medium text-white panel"
      style={{ backgroundColor: "#005955" }}
    >
      <span className="text-xl">
        Refer your friends to Agent&apos;s Corner and they will receive $
        {referredAgentAmount} credit!
      </span>
      <div className="mb-2 font-light">
        When your friends sign up with your personal referral code below and
        activate their first campaign you receive ${referringAgentAmount}{" "}
        credit!
      </div>
      <Row>
        <span className="text-2xl items-middle">{code}</span>
        <CopyableButton copyText={code} className="ml-4 bg-white bg-opacity-50">
          <MaterialIcon
            icon="content_copy"
            className="mr-2 text-base align-top"
          />
          Copy
        </CopyableButton>
      </Row>
    </div>
  );
};
