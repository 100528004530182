import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./App.less";
import "./tailwind.css";

// import ConfigureStore from "./store/index";
import * as serviceWorker from "./serviceWorker";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AppRouter } from "./navigation";
import store, { persistor } from "./store";
import { LoadingScreen } from "@components";

// eslint-disable-next-line no-undef,@typescript-eslint/no-var-requires
require("dotenv").config();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  release: `v${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_ENVIRONMENT,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.05,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An Error has occurred"}>
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
