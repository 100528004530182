import React, { useMemo, useState, useCallback, useEffect } from "react";
import { BidCampaign } from "@types";
import {
  Steps,
  Drawer,
  Button,
  Collapse,
  Form,
  Spin,
  notification,
  Input,
} from "antd";
import { useScreenSize } from "@utils/hooks";
import {
  CityZipcodesList,
  ZipcodeTags,
  SearchLocationInput,
  ZipcodeSelect,
  ButtonPair,
  ErrorAlert,
} from "@components";
import {
  getCitiesNames,
  isEstHomeValueMaxSet,
  isEstHomeValueMinSet,
  useZipcodesEditor,
} from "@utils/campaign";
import "./CampaignEditor.less";
import { FilterOptions, SetBid } from "@components/forms/components";
import {
  EST_HOME_VALUE_MAX_NOT_SET,
  EST_HOME_VALUE_MIN_NOT_SET,
  MINIMUM_NUM_CAMPAIGN_ZIPS,
  PROPERTY_TYPES,
} from "@constants";
import { abbreviateCurrency } from "@utils/formatters";
import {
  useUpdateCampaignMutation,
  useCreateCampaignMutation,
  useLazyGetZipCodesQuery,
} from "@queries";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useNavigate } from "react-router-dom";
import { PanelHeader } from "./PanelHeader";
import { PanelContent } from "./PanelContent";

const { Step } = Steps;
const { Panel } = Collapse;

export const campaignEditSteps: { [key: number]: string } = {
  0: "location",
  1: "filters",
  2: "bid",
};

interface CampaignEditor {
  visible: boolean;
  onClose: () => void;
  campaign?: BidCampaign;
  isNew?: boolean;
}

export const CampaignEditor: React.FC<CampaignEditor> = ({
  visible = false,
  onClose,
  campaign,
  isNew = false,
}) => {
  const navigate = useNavigate();
  const { isSmallScreen } = useScreenSize();
  const name = campaign?.name;
  const id = campaign?.id;
  const estimatedHomeValueMin = campaign?.estimatedHomeValueMin;
  const estimatedHomeValueMax = campaign?.estimatedHomeValueMax;
  const propertyType = campaign?.propertyType;
  const bidAmount = campaign?.bidAmount;
  const weeklyBudget = campaign?.weeklyBudget;
  const [currentStep, setCurrentStep] = useState(0);
  const [updateCampaign, { isLoading: updateIsLoading, error: updateError }] =
    useUpdateCampaignMutation();
  const [createCampaign, { isLoading: createIsLoading, error: createError }] =
    useCreateCampaignMutation();

  const nonFieldErrorMsg =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updateError?.data?.nonFieldErrors?.[0] ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    createError?.data?.nonFieldErrors?.[0];

  const {
    selectedZipCodeGroups,
    selectedZipCodesList,
    placeQuery: { query },
    currentQueryZipcodes,
    currentQuerySelectedZipcodes,
    setQueryByPlace,
    setPlaceQuery,
    manuallyAddZipcode,
    toggleZipcode,
    deleteZipcode,
    selectAll,
    unselectAll,
    clearCurrentQuery,
    removeSelectedPlace,
  } = useZipcodesEditor(campaign);

  const goToStepLocation = useCallback(() => setCurrentStep(0), []);
  const goToStepFilters = useCallback(() => setCurrentStep(1), []);
  const goToStepBid = useCallback(() => setCurrentStep(2), []);
  const goToStepFinalization = useCallback(() => setCurrentStep(3), []);

  const [form] = Form.useForm();

  const [homeValueMin, setHomeValueMin] = useState(
    estimatedHomeValueMin ? abbreviateCurrency(estimatedHomeValueMin) : ""
  );
  const [homeValueMax, setHomeValueMax] = useState(
    estimatedHomeValueMax ? abbreviateCurrency(estimatedHomeValueMax) : ""
  );

  const [bidAmountHeader, setBidAmountHeader] = useState(bidAmount);
  const [minNumZipsError, setMinNumZipsError] = useState(false);

  useEffect(() => {
    if (selectedZipCodesList.length >= MINIMUM_NUM_CAMPAIGN_ZIPS)
      setMinNumZipsError(false);
  }, [selectedZipCodesList]);

  const updateChangedFields = (changed: any[], allFields: any[]) => {
    // console.log("Changed: ", changed);
    // changed.forEach((field) => {
    //   if (field.name.includes("estHomeValueMin")) {
    //     setHomeValueMin(abbreviateCurrency(field.value));
    //   }
    //   if (field.name.includes("estHomeValueMax")) {
    //     setHomeValueMax(abbreviateCurrency(field.value));
    //   }
    // });
  };
  const [getZipCodes] = useLazyGetZipCodesQuery();
  const fetchZipCodes = ({ city, state }: { city: string; state: string }) => {
    return getZipCodes({ city, state })
      .unwrap()
      .then((payload) => payload);
  };

  const handleSubmit = (values: any) => {
    if (selectedZipCodesList.length < MINIMUM_NUM_CAMPAIGN_ZIPS) {
      goToStepLocation();
      setMinNumZipsError(true);
      return;
    }

    setMinNumZipsError(false);
    const data = {
      ...values,
      id,
      zipCodes: selectedZipCodesList.join(","),
      cityStateToZipCodes: JSON.stringify(selectedZipCodeGroups),
    };

    // Submit only updated fields
    if (
      data?.estimatedHomeValueMin === estimatedHomeValueMin ||
      (!data?.estimatedHomeValueMin &&
        estimatedHomeValueMin === EST_HOME_VALUE_MIN_NOT_SET)
    ) {
      delete data.estimatedHomeValueMin;
    } else {
      if (!data?.estimatedHomeValueMin)
        data.estimatedHomeValueMin = EST_HOME_VALUE_MIN_NOT_SET;
    }
    if (
      data?.estimatedHomeValueMax === estimatedHomeValueMax ||
      (!data?.estimatedHomeValueMax &&
        estimatedHomeValueMax === EST_HOME_VALUE_MAX_NOT_SET)
    ) {
      delete data.estimatedHomeValueMax;
    } else {
      if (!data?.estimatedHomeValueMax)
        data.estimatedHomeValueMax = EST_HOME_VALUE_MAX_NOT_SET;
    }
    const updated = data?.propertyType?.slice()?.sort()?.toString();
    const initial = propertyType?.slice()?.sort()?.toString();
    if (updated === initial) {
      delete data.propertyType;
    } else {
      if (data?.propertyType?.length === 4)
        data.propertyType.splice(0, data.propertyType.length);
    }
    if (data?.name === name) delete data.name;
    if (data?.bidAmount === bidAmount) delete data.bidAmount;
    if (
      data?.weeklyBudget === weeklyBudget ||
      (!data?.weeklyBudget && weeklyBudget === "0.00")
    ) {
      delete data.weeklyBudget;
    } else {
      if (!data?.weeklyBudget) data.weeklyBudget = "0.00";
    }

    if (
      data?.zipCodes === campaign?.zipCodes &&
      data?.cityStateToZipCodes === campaign?.cityStateToZipCodes
    ) {
      delete data.zipCodes;
      delete data.cityStateToZipCodes;
    }

    if (isNew) {
      createCampaign(data)
        .unwrap()
        .then((payload) => {
          notification.success({
            placement: "bottomLeft",
            message: `Campaign created: ${payload?.name}`,
          });
          onClose();
          navigate(`/campaigns/${payload?.id}`);
        })
        .catch((error) => {
          notification.error({
            placement: "bottomLeft",
            message: "Unable to create campaign.",
          });
        });
    } else {
      updateCampaign(data)
        .unwrap()
        .then((payload) => {
          notification.success({
            placement: "bottomLeft",
            message: "Campaign updated successfully.",
          });
          onClose();
        })
        .catch((error) =>
          notification.error({
            placement: "bottomLeft",
            message: "Unable to update campaign.",
          })
        );
    }
  };

  const handleValidationFailed = (failedResult: ValidateErrorEntity) => {
    const errorFields: string[] = [];

    failedResult?.errorFields?.forEach((error) =>
      errorFields.push(...(error?.name as string[]))
    );

    if (
      errorFields.includes("estimatedHomeValueMin") ||
      errorFields.includes("estimatedHomeValueMax") ||
      errorFields.includes("propertyType")
    )
      goToStepFilters();
    else if (
      errorFields.includes("bidAmount") ||
      errorFields.includes("weeklyBudget")
    )
      goToStepBid();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };

  const locationIsActiveKey = campaignEditSteps[currentStep] === "location";
  const filtersIsActiveKey = campaignEditSteps[currentStep] === "filters";
  const bidIsActiveKey = campaignEditSteps[currentStep] === "bid";

  return (
    <Drawer
      title={isNew ? "New Campaign" : "Edit Campaign"}
      className="z-50"
      visible={visible}
      width={isSmallScreen ? "100vw" : "70vw"}
      onClose={onClose}
    >
      <Form
        form={form}
        {...formItemLayout}
        requiredMark={"optional"}
        colon={false}
        onFinish={handleSubmit}
        onFinishFailed={handleValidationFailed}
        onFieldsChange={updateChangedFields}
        initialValues={{
          name,
          estimatedHomeValueMin: isEstHomeValueMinSet(estimatedHomeValueMin)
            ? estimatedHomeValueMin
            : null,
          estimatedHomeValueMax: isEstHomeValueMaxSet(estimatedHomeValueMax)
            ? estimatedHomeValueMax
            : null,
          propertyType:
            propertyType?.length === 0 || !propertyType
              ? PROPERTY_TYPES
              : propertyType,
          bidAmount,
          weeklyBudget: weeklyBudget === "0.00" ? null : weeklyBudget,
        }}
      >
        <div className="text-base">Campaign Name</div>
        <Form.Item
          key="name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please entire a new for your campaign",
            },
          ]}
        >
          <Input size="large" maxLength={80} placeholder="My Campaign" />
        </Form.Item>
        {minNumZipsError && (
          <ErrorAlert
            message={`Please select at least ${MINIMUM_NUM_CAMPAIGN_ZIPS} zip codes`}
            afterClose={() => setMinNumZipsError(false)}
          />
        )}
        {nonFieldErrorMsg && <ErrorAlert message={nonFieldErrorMsg} />}
        <Collapse
          activeKey={campaignEditSteps[currentStep]}
          className="custom-header"
          ghost
        >
          <Panel
            key="location"
            forceRender
            header={
              <PanelHeader
                title="Location"
                onClick={goToStepLocation}
                isActive={locationIsActiveKey}
              >
                <div
                  className={`mb-2 ${
                    locationIsActiveKey ? "text-white" : "text-black"
                  }`}
                >
                  {getCitiesNames({
                    cityStateToZipCodes: JSON.stringify(selectedZipCodeGroups),
                  })}
                </div>
                <ZipcodeTags
                  zipcodes={selectedZipCodesList}
                  zipcodesBeforeOverflow={6}
                />
              </PanelHeader>
            }
            showArrow={false}
          >
            <PanelContent isActive={locationIsActiveKey}>
              <SearchLocationInput
                onChange={setPlaceQuery}
                onAddZipCode={manuallyAddZipcode}
                fetchZipCodes={fetchZipCodes}
              />
              <ZipcodeSelect
                query={query}
                zipcodeOptions={currentQueryZipcodes}
                selectedZipcodes={currentQuerySelectedZipcodes}
                toggleZipcode={toggleZipcode}
                selectAll={selectAll}
                unselectAll={unselectAll}
                clearCurrentQuery={clearCurrentQuery}
              />
              <CityZipcodesList
                cityStateToZipCodes={selectedZipCodeGroups}
                onDelete={deleteZipcode}
                onHeaderClick={setQueryByPlace}
                onPlaceDelete={removeSelectedPlace}
              />
              <ButtonPair onSubmit={goToStepFilters} />
            </PanelContent>
          </Panel>
          <Panel
            key="filters"
            forceRender
            header={
              <PanelHeader
                title="Filters"
                onClick={goToStepFilters}
                isActive={filtersIsActiveKey}
              >
                {/* ${homeValueMin} - ${homeValueMax} &bull; */}
              </PanelHeader>
            }
            showArrow={false}
          >
            <PanelContent isActive={filtersIsActiveKey}>
              <div className="max-w-4xl p-4 mx-auto">
                <FilterOptions form={form} />
              </div>
              <ButtonPair onCancel={goToStepLocation} onSubmit={goToStepBid} />
            </PanelContent>
          </Panel>
          <Panel
            key="bid"
            forceRender
            header={
              <PanelHeader
                title="Bid & Budget"
                onClick={goToStepBid}
                isActive={bidIsActiveKey}
              >
                {/* ${bidAmountHeader} &bull; */}
              </PanelHeader>
            }
            showArrow={false}
          >
            <PanelContent isActive={bidIsActiveKey}>
              <div className="max-w-4xl p-4 mx-auto">
                <SetBid form={form} />
                <ButtonPair
                  onCancel={goToStepFilters}
                  onSubmit={goToStepFinalization}
                />
              </div>
            </PanelContent>
          </Panel>
        </Collapse>
        <div className="flex justify-center mt-8">
          <Button
            type="text"
            className="mx-2 max-w-btn btn-tertiary"
            block
            onClick={onClose}
          >
            Cancel
          </Button>
          <Spin spinning={updateIsLoading}>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full mx-2 max-w-btn"
              block
            >
              Save
            </Button>
          </Spin>
        </div>
      </Form>
    </Drawer>
  );
};
