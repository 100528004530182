import React, { FC, useEffect } from "react";
import { LoadingScreen, GenericError } from "@components";
import { useSearchParams, Navigate } from "react-router-dom";
import { useGetAgentQuery } from "@queries";
import { notification } from "antd";

const VerificationSuccess: FC = () => {
  const { data: agent, error } = useGetAgentQuery();
  const [params] = useSearchParams();
  const fromV2Signup = params.get("from_v2_signup");
  const fromEmailVerified = params.get("from_email_verified");
  console.log(
    `fromV2Signup: ${fromV2Signup} - fromEmailVerified: ${fromEmailVerified}`
  );

  useEffect(() => {
    if (!error && fromEmailVerified)
      notification.success({
        placement: "bottomLeft",
        message: "Your email has been verified.",
      });
  }, []);

  if (error) return <GenericError />;

  if (
    agent &&
    (!agent?.v2Signup?.phonenumberVerified ||
      !agent?.v2Signup?.isVerificationComplete)
  )
    return <Navigate to="/signup/redirect-to-stage" />;

  if (agent && (fromV2Signup || fromEmailVerified))
    return <Navigate to="/signup/verified" />;

  return <LoadingScreen />;
};

export default VerificationSuccess;
