import React, { useMemo } from "react";
import { Form, Typography, Input, Col, FormInstance } from "antd";
import { MINIMUM_BID_AMOUNT } from "@constants";

const { Title, Paragraph, Text } = Typography;

interface SetBidProps {
  /**
   * Form instance needed to validate weekly budget is greater than bid
   */
  form: FormInstance;
}
export const SetBid: React.FC<SetBidProps> = ({ form }) => {
  const minimumBidStr = useMemo(() => MINIMUM_BID_AMOUNT.toFixed(2), []);
  return (
    <>
      <Form.Item
        key="a"
        name="bidAmount"
        label={
          <Col>
            <Title level={5}>Bid Amount</Title>
            <Text>
              ${MINIMUM_BID_AMOUNT} is the minimum bid for a campaign.
            </Text>
          </Col>
        }
        style={{ minHeight: 60 }}
        labelAlign="left"
        dependencies={["weeklyBudget"]}
        rules={[
          {
            required: true,
            message: "",
          },
          {
            validator: (_, value) =>
              value >= MINIMUM_BID_AMOUNT
                ? /^\d{1,6}(\.\d{1,2})?$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please enter a valid dollar amount")
                    )
                : Promise.reject(
                    new Error(`The minimum bid amount is $${minimumBidStr}`)
                  ),
          },
        ]}
      >
        <Input size="large" prefix="$" placeholder={minimumBidStr} />
      </Form.Item>
      <Form.Item
        key="b"
        name="weeklyBudget"
        className="remove-antd-optional-mark"
        tooltip="Leave blank if you don't want to set a limit on the amount you are eligible to win."
        label={
          <Col>
            <div className="flex">
              <Title level={5}>Weekly Budget</Title>
              <span className="ant-form-item-optional">(optional)</span>
            </div>
            <Paragraph>
              The maximum amount you want to spend per week.
            </Paragraph>
          </Col>
        }
        labelAlign="left"
        style={{ minHeight: 60 }}
        dependencies={["bidAmount"]}
        rules={[
          {
            validator: (_, value) => {
              if (value == undefined || value == "") return Promise.resolve();
              else {
                const bid = parseFloat(form.getFieldValue("bidAmount"));
                const budget = parseFloat(value);

                if (budget < MINIMUM_BID_AMOUNT)
                  return Promise.reject(
                    new Error(`The minimum weekly budget is $${minimumBidStr}`)
                  );

                if (budget < bid) {
                  return Promise.reject(
                    new Error("Weekly budget must be greater than bid amount")
                  );
                }
                return /^\d{1,6}(\.\d{1,2})?$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please enter a valid dollar amount")
                    );
              }
            },
          },
        ]}
      >
        <Input size="large" prefix="$" placeholder={"0.00"} />
      </Form.Item>
    </>
  );
};
