import { SuccessManagerResponse } from "@queries";
import {
  LeadType,
  ISOString,
  DollarString,
  SubscriptionCampaign,
  SignupFlowVersion,
  Agent,
  BidCampaign,
} from "@types";

export enum CampaignTypePath {
  Bidding = "Bidding",
  MonthlyBudget = "Monthly Budget",
}

export enum SignupStage {
  VerifyPhone = "Verify Phone",
  VerifyEmail = "Verify Email",
  ChooseCampaignLocation = "Choose Campaign Location",
  SetCampaignBid = "Set Campaign Bid",
  SetCampaignBudget = "Set Campaign Budget",
  AddPaymentInfo = "Add Payment Info",
  ActivateCampaign = "Activate Campaign",
  Completed = "Completed Signup",
}

export enum FlashLevel {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export interface V2Signup {
  campaignTypePath: CampaignTypePath;
  emailVerified: boolean;
  flashLevel: FlashLevel;
  flashMessage: string;
  homeSellerCampaign: BidCampaign | null;
  isComplete: boolean;
  isVerificationComplete: boolean;
  minRemainingToDeposit: number | null;
  monthlyBudgetHomeSellerCampaign:
    | (SubscriptionCampaign & { pendingOfferMetadata: SubscriptionOfferMeta })
    | null;
  phonenumberVerified: boolean;
  stage: SignupStage;
}

export interface SubscriptionOfferMeta {
  activeDurationMonths: number;
  averageEstimatedHomeValue: number | null;
  availableHeldForAtLeast: number; // seconds
  availableHeldForAtLeastMinutes: number;
  selectedHeldForAtLeast: number; // seconds
  selectedHeldForAtLeastMinutes: number;
  leadType: LeadType;
  madeAt: ISOString;
  offers: SubscriptionOffer[];
  offersType: "choices";
  selected: AvailableOffer | null;
  expired?: boolean;
  expiredAt?: ISOString;
}

export interface SubscriptionOfferBase {
  available: boolean;
  expectedNumLeads: number;
}

export interface AvailableOffer extends SubscriptionOfferBase {
  offerId: number;
  monthlyBudget: DollarString;
}

export interface UnavailableOffer extends SubscriptionOfferBase {
  reasons: NoSubscriptionOfferReasons;
}
// export type SubscriptionOffer = AvailableOffer | UnavailableOffer;

export interface SubscriptionOffer {
  available: boolean;
  expectedNumLeads: number;
  offerId?: number;
  monthlyBudget?: DollarString;
  reasons?: NoSubscriptionOfferReasons;
}

export interface NoSubscriptionOfferReasons {
  tooFewLeads: string;
  tooMuchCompetition: string;
}

export interface SignupResponse {
  access: string;
  agent: Agent;
  agentSignupFlowIsComplete: boolean;
  agentSignupFlowVersion: SignupFlowVersion;
  dateJoined: ISOString;
  email: string;
  emailVerified: boolean;
  firstName: string;
  fullyVerified: boolean;
  hasAgent: boolean;
  id: number;
  isActive: boolean;
  lastName: string;
  phonenumber: string;
  phonenumberVerified: boolean;
  referralCode: string | null;
  refresh: string;
  signupSource: unknown;
  signupSubSource: unknown;
  successManagerAssignments: SuccessManagerResponse[];
  successManagerUserpilotToken: string;
  verified: boolean;
}
