import React, { FC } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import {
  PhonenumberVerification,
  EmailVerification,
  Verified,
  CreateCampaign,
  SetCampaignBid,
  AddPayment,
  ActivateCampaign,
  SignupComplete,
  SetSubscription,
  SubscriptionPayment,
} from "../page/onboarding";
import { LoadingScreen, GenericError } from "@components";
import { matchStage } from "@utils/signupHelpers";
import { useGetAgentQuery, useV2Signup } from "@queries";
import { FlashMessageNotifier } from "@containers";
import { SignupRoutePermissionVerifier } from "./SignupRoutepermissions";

const SignupRoutes: FC = () => {
  const { data: agent, isLoading, error } = useGetAgentQuery();
  const { pathname } = useLocation();
  const isSignupFlowComplete = agent?.isSignupFlowComplete;

  const {
    stage,
    campaignTypePath,
    emailVerified,
    phonenumberVerified,
    isComplete,
  } = useV2Signup();
  const agentStage = matchStage({ stageName: stage, campaignTypePath });

  if (isLoading) return <LoadingScreen />;
  if (error) return <GenericError />;

  if (pathname !== "/signup/complete")
    if (isSignupFlowComplete || isComplete) {
      return <Navigate to="/" />;
    } else if (emailVerified && !phonenumberVerified) {
      // return <Navigate to="/signup/verify-phonenumber" />;
    } else if (phonenumberVerified && !emailVerified) {
      // return <Navigate to="/signup/verify-email" />;
    }

  return (
    <>
      <FlashMessageNotifier />
      <Routes>
        <Route
          path="/verify-phonenumber"
          element={
            <SignupRoutePermissionVerifier requireNotVerified={true}>
              <PhonenumberVerification />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route
          path="/verify-email"
          element={
            <SignupRoutePermissionVerifier requireNotVerified={true}>
              <EmailVerification />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route path="/verified" element={<Verified />} />
        <Route
          path="/campaign"
          element={
            <SignupRoutePermissionVerifier requireVerified={true}>
              <CreateCampaign />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route
          path="/campaign/bid"
          element={
            <SignupRoutePermissionVerifier
              requireVerified={true}
              requireBiddingPath={true}
              requireCampaignLocationSet={true}
            >
              <SetCampaignBid />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route
          path="/campaign/subscription"
          element={
            <SignupRoutePermissionVerifier
              requireVerified={true}
              requireSubscriptionPath={true}
              requireCampaignLocationSet={true}
            >
              <SetSubscription />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route
          path="/payment/bid"
          element={
            <SignupRoutePermissionVerifier
              requireVerified={true}
              requireBiddingPath={true}
              requireCampaignLocationSet={true}
            >
              <AddPayment />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route
          path="/payment/subscription"
          element={
            <SignupRoutePermissionVerifier
              requireVerified={true}
              requireSubscriptionPath={true}
              requireCampaignLocationSet={true}
            >
              <SubscriptionPayment />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route
          path="/activate"
          element={
            <SignupRoutePermissionVerifier
              requireVerified={true}
              requireBiddingPath={true}
              requireCampaignLocationSet={true}
            >
              <ActivateCampaign />
            </SignupRoutePermissionVerifier>
          }
        />
        <Route path="/complete" element={<SignupComplete />} />
        <Route path="/loading" element={<LoadingScreen />} />
        <Route
          path="/redirect-to-stage"
          element={<Navigate to={agentStage?.path} />}
        />
      </Routes>
    </>
  );
};

export default SignupRoutes;
