import React, { FC, useState, useEffect } from "react";
import {
  Layout,
  Form,
  Typography,
  notification,
  Alert,
  Button,
  Checkbox,
  Spin,
  Collapse,
} from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";
import { SignupStage } from "@types";

import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";

import { CreditCardInfo } from "@components";
import { ASMPopUp } from "@containers";
import {
  useActiveCreditCardQuery,
  useAddPaymentInfoMutation,
  useSignupSubscriptionOffers,
} from "@queries";

// Stripe
const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;
const stripePromise = loadStripe(STRIPE_PUB_KEY);

const { Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

const SubscriptionPayment: FC = () => (
  <Elements stripe={stripePromise}>
    <InjectedAddPayment />
  </Elements>
);

const InjectedAddPayment: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const {
    selected,
    availableHeldForAtLeastMinutes,
    selectedHeldForAtLeastMinutes,
  } = useSignupSubscriptionOffers();
  const [addPaymentInfo, { isLoading: paymentIsLoading, error: paymentError }] =
    useAddPaymentInfoMutation();

  const [error, setError] = useState("");

  useEffect(() => {
    if (availableHeldForAtLeastMinutes && selectedHeldForAtLeastMinutes) {
      notification.info({
        message: `Your selected offer will be available for ${selectedHeldForAtLeastMinutes} minutes.`,
      });
    }
  }, [availableHeldForAtLeastMinutes, selectedHeldForAtLeastMinutes]);

  const {
    data: creditCard,
    isLoading: creditCardIsLoading,
    error: creditCardError,
  } = useActiveCreditCardQuery();

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      try {
        // Use elements.getElement to get a reference to the mounted Element.
        const cardElement = elements && elements.getElement(CardElement);

        if (!stripe || !cardElement) throw new Error("Stripe Error");
        const card = await stripe.createToken(cardElement);
        if (card?.error) {
          if (card?.error?.message) {
            console.log("StripeError: ", card.error);
            setError(card.error.message);
          } else throw new Error("Stripe Error");
        }
        const { token } = card;

        if (token) {
          setError("");
          if (!selected?.offerId) throw new Error("No Selected Offer");
          addPaymentInfo({ offer: selected?.offerId, token: token?.id })
            .unwrap()
            .then((payload) => {
              if (payload?.stage !== SignupStage.Completed) {
                notification.error({
                  placement: "topRight",
                  message: payload?.flashMessage,
                });
                return;
              }
              navigate("/signup/complete");
            })
            .catch((error) => console.log("addPayment rejected", error));
        }
      } catch (e) {
        notification.error({
          placement: "bottomLeft",
          message: "Sorry, there was an error with your payment.",
        });
      }
    });
  };

  return (
    <>
      <OnboardingLayout step={6}>
        <Content className="mt-8">
          <div className="panel-header">
            <h2 className="font-semibold">${selected?.monthlyBudget}/mo</h2>
            <div className="text-base">
              {selected?.expectedNumLeads} introductions per month
            </div>
          </div>
          <div className="mb-1 panel-w-header">
            <div className="p-4">
              {error && (
                <Alert
                  className="mb-1"
                  closable
                  message={error}
                  type="error"
                  showIcon
                  onClose={() => setError("")}
                />
              )}
              {/* <PaymentForm creditCard={latestCreditCard} /> */}
              {creditCard && <CreditCardInfo {...creditCard} />}

              {/* TODO: <ErrorAlert /> */}

              <Text strong style={{ fontSize: 16, color: "#000000D9" }}>
                Payment Method
              </Text>
              <CardElement
                className="p-4"
                options={{ style: { base: { fontSize: "20px" } } }}
              />
              {/* TODO: Once backend supports promo code not on initial signup page
               <Collapse ghost className="text-right">
                <Panel
                  showArrow={false}
                  header={
                    <span className="text-base hover:cursor-pointer">
                      Do you have a promo code?
                    </span>
                  }
                  key="promoCode"
                >
                  Promo
                </Panel>
              </Collapse> */}
            </div>
          </div>
          <div>
            You can cancel anytime. Cancellation will take effect in the
            following billing period.
          </div>
          <Form form={form} className="pt-4">
            <Form.Item
              name="termsOfUse"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Terms of Use must be accepted")
                        ),
                },
              ]}
            >
              <Checkbox style={{ fontSize: 16 }} className="font-semibold">
                I accept{" "}
                <a
                  href="/terms-of-use"
                  referrerPolicy="no-referrer"
                  target="_blank"
                  className="underline"
                >
                  Terms of Use
                </a>
              </Checkbox>
            </Form.Item>
          </Form>

          <div className="flex justify-end">
            <Spin spinning={paymentIsLoading}>
              <Button type="primary" onClick={handleSubmit} size="large">
                Confirm
              </Button>
            </Spin>
          </div>

          <ASMPopUp />
        </Content>
      </OnboardingLayout>
    </>
  );
};

export default SubscriptionPayment;
