import React from "react";
import { ZipcodeTag } from "@components";

interface Zipcodes {
  zipcodes: string[];
  zipcodesBeforeOverflow?: number;
}
export const ZipcodeTags: React.FC<Zipcodes> = ({
  zipcodes = [],
  zipcodesBeforeOverflow = 4,
}) => {
  if (zipcodes?.length <= 0) return null;
  const overflow = zipcodes.length - zipcodesBeforeOverflow;
  const tags = zipcodes
    .slice(0, zipcodesBeforeOverflow)
    .map((zipcode) => <ZipcodeTag key={zipcode} zipcode={zipcode} />);
  if (overflow > 0)
    tags.push(<ZipcodeTag key="overflow" zipcode={`${overflow} more...`} />);
  return <div className="flex flex-wrap gap-y-2">{tags}</div>;
};
