import React, { FC } from "react";
import { Switch } from "antd";
import { AutoDepositChild } from "./AutoDeposit";
import { AUTO_DEPOSIT_HELP_URL } from "@constants";

export const AutoDepositBrief: FC<AutoDepositChild> = ({
  autoRefreshFundsEnabled,
  handleSubmit,
}) => (
  <div className="">
    <Switch
      className="mr-2"
      //   value={autoRefreshFundsEnabled}
      checked={autoRefreshFundsEnabled}
      onChange={handleSubmit}
    />
    <span>
      <b>Auto Deposit </b> is turned {autoRefreshFundsEnabled ? "on" : "off"}
      <br />
      <span className="text-muted">
        &#9432; Keep this on to prevent missing out on leads
        <br />
        <a
          href={AUTO_DEPOSIT_HELP_URL}
          className="text-muted"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn More
        </a>
      </span>
    </span>
  </div>
);
