import { GMapsQuery, Zipcode } from "@types";
import React from "react";
import { ZipcodeTag } from "@components";
import { Button, Row } from "antd";

interface CityZipcodesListProps {
  cityStateToZipCodes: GMapsQuery[];
  /**
   * Callback when closing tag
   */
  onDelete?: (placeId: string, zipcode: Zipcode) => void;
  /**
   * Callback when clicking on header (the formatted city,state address)
   */
  onHeaderClick?: (formattedCity: string) => void;
  /**
   * Callback when clicking on header (the formatted city,state address)
   */
  onPlaceDelete?: (formattedCity: string) => void;
}

export const CityZipcodesList: React.FC<CityZipcodesListProps> = ({
  cityStateToZipCodes,
  onDelete,
  onHeaderClick,
  onPlaceDelete,
}) => {
  return (
    <div className="space-y-4">
      {cityStateToZipCodes.map((queryObj) => (
        <div key={queryObj.id}>
          <Row className="mb-1" justify="space-between">
            {onHeaderClick ? (
              <Button
                type="text"
                {...(onHeaderClick && {
                  onClick: () => onHeaderClick(queryObj.id),
                })}
              >
                {queryObj.query == "" || !queryObj.query
                  ? "Additional Zip Codes"
                  : queryObj.query}
              </Button>
            ) : (
              <span>
                {queryObj.query == "" || !queryObj.query
                  ? "Additional Zip Codes"
                  : queryObj.query}
              </span>
            )}
            {onPlaceDelete && (
              <Button type="text" onClick={() => onPlaceDelete(queryObj.id)}>
                Clear
              </Button>
            )}
          </Row>
          <div className="flex flex-wrap gap-2">
            {queryObj.codes.map((zipcode) => (
              <ZipcodeTag
                key={zipcode}
                zipcode={zipcode}
                {...(onDelete && {
                  onClose: () => onDelete(queryObj.id, zipcode),
                  closable: true,
                })}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
