import React, { useMemo } from "react";
import { Badge } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Contact } from "@types";
import { formatDistanceToNow, differenceInDays } from "date-fns";

interface RecentContactProps {
  /**
   * Lead Id - used to request lead data
   */
  contact: Contact;
}

/**
 * Button
 */
export const RecentContact: React.FC<RecentContactProps> = ({ contact }) => {
  const {
    firstName,
    lastName,
    created,
    propertyAddress,
    addressZip5,
    addressCity,
    addressState,
  } = contact;

  const receivedWithinLastWeek = useMemo(
    () => differenceInDays(new Date(), new Date(created)) < 7,
    [created]
  );

  const numTimePeriodsAgo = useMemo(
    () => formatDistanceToNow(new Date(created)) + " ago",
    [created]
  );

  return (
    <Link
      to={`/contacts/${contact.id}`}
      state={{ fromDashboard: true }}
      className="block"
    >
      <div className="flex justify-between p-2 px-8 text-white cursor-pointer rounded-2xl secondary secondary:hover">
        <span>
          {receivedWithinLastWeek && <Badge status="warning" />}
          {firstName} {lastName}
        </span>
        <span className="hidden md:inline-flex">
          {propertyAddress},&nbsp;
          <span className="capitalize">{addressCity.toLowerCase()}</span>,{" "}
          {addressState} {addressZip5}
        </span>
        <span className="italic"> {numTimePeriodsAgo}</span>
        <RightOutlined className="self-center" />
      </div>
    </Link>
  );
};
