import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { SubscriptionCampaignDetails } from "./components/SubscriptionCampaignDetails/SubscriptionCampaignDetails";
import { selectCampaignById } from "@queries/select";
import { Layout, Spin } from "antd";
import { Loading, GenericError } from "@components";
import {
  useGetSubscriptionCampaignQuery,
  useSignupSubscriptionOffers,
  useGetAgentQuery,
} from "@queries";
import { ASMWidgetBlock } from "@containers";
import SignupProgressBannerContainer from "src/v2/component/SignupProgressBanner";

const { Content } = Layout;

export const SubscriptionCampaignPage: FC = () => {
  const { data, isLoading, error } = useGetSubscriptionCampaignQuery();
  const {
    data: agent,
    isLoading: isLoadingAgent,
    error: agentError,
  } = useGetAgentQuery();
  const { offers } = useSignupSubscriptionOffers();
  console.log("subscriptionCampaign: ", data);

  if (isLoading || isLoadingAgent) return <Loading />;

  if (error || agentError) return <GenericError />;

  if (!agent?.isSignupFlowComplete)
    return (
      <Content className="container mx-auto">
        <div className="max-w-3xl mx-auto space-y-2">
          <h1 className="page-title">Your Campaign</h1>
          <SignupProgressBannerContainer />
          <ASMWidgetBlock />
        </div>
      </Content>
    );

  return (
    <Content className="container mx-auto">
      <div className="max-w-3xl mx-auto">
        <h1 className="page-title">Your Campaign</h1>
        <SubscriptionCampaignDetails campaign={data?.[0]} />
      </div>
    </Content>
  );
};
