import React from "react";
import { Tabs, Layout, PageHeader } from "antd";
import { Account, Integrations, Payment, BillingHistory } from "./components";
import { TermsOfUse, PrivacyPolicy } from "../legal";
import { useScreenSize } from "@utils/hooks";
import { useLocation } from "react-router-dom";

const { Content } = Layout;
const { TabPane } = Tabs;

export const SettingsPage: React.FC = () => {
  const { isSmallScreen } = useScreenSize();
  const { state } = useLocation();
  let active = "";

  if ((state as any)?.toPayment) active = "Payment";
  return (
    <Content className="container mx-auto max-w-7xl">
      <Tabs
        tabPosition={isSmallScreen ? "top" : "left"}
        className="mt-8"
        defaultActiveKey={active}
      >
        <TabPane key="Account" tab="Account">
          <Account />
        </TabPane>
        <TabPane key="Integrations" tab="Integrations">
          <Integrations />
        </TabPane>
        <TabPane key="Payment" tab="Payment">
          <Payment />
        </TabPane>
        <TabPane key="Billing History" tab="Billing History">
          <BillingHistory />
        </TabPane>
        <TabPane key="Terms of Use" tab="Terms of Use">
          <TermsOfUse />
        </TabPane>
        <TabPane key="Privacy Policy" tab="Privacy Policy">
          <PrivacyPolicy />
        </TabPane>
      </Tabs>
    </Content>
  );
};
