import {
  MiddlewareAPI,
  isFulfilled,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { notification } from "antd";

/**
 * Log a warning and show a notification
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // if (isFulfilled(action)) {
    //   console.log("Fulfilled action: ", action);
    //   notification.success({ message: "Success!" });
    // }

    // if (isRejectedWithValue(action)) {
    //   console.warn("We got a rejected action: ", action);
    //   notification.warn({
    //     message:
    //       "Sorry we were not able to complete your request. Please try again or refresh the page.",
    //   });
    // }

    return next(action);
  };
