import React from "react";
import { Statistic, StatisticProps } from "antd";

export type SafeContactDetailProps = SafeDetailProps &
  Omit<StatisticProps, "value">;
interface SafeDetailProps {
  /**
   * Title/Header of collapsible section
   */
  title: string;
  /**
   * Object data to display
   */
  value?: unknown;
}

const ContactDetail = (props: StatisticProps) => (
  <Statistic {...props} style={{ padding: 16 }} valueStyle={{ fontSize: 18 }} />
);

const toSentenceCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Contact Detail that's safe to give nullish values (won't render invalid values).
 */
export const SafeContactDetail: React.FC<SafeContactDetailProps> = ({
  title,
  value,
  ...props
}) => {
  if (typeof value === "number" || typeof value === "string") {
    const validValue: string | number = value;
    const formattedTitle = toSentenceCase(title as string);
    const extraProps = { groupSeparator: "" };
    // /\bYear\b/.test(formattedTitle) &&
    //   (extraProps = { ...extraProps, groupSeparator: "" });
    return (
      <ContactDetail
        title={formattedTitle}
        value={validValue}
        {...extraProps}
        {...props}
      />
    );
  }
  // TODO: Handle string lists
  // } else if (typeof value !== "string" /*  && value?.length > 0 */) {
  //   console.error("Need to handle list");
  //   // return <div className="bg-red">Need to implement list</div>;
  //   return null;
  // }
  return null;
};
