import axios from "axios";

import { getToken } from "./auth";

const service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `JWT ${token}`;
    }

    if (!config.params || !config.params.api_version) {
      config.params = config.params || {};
      config.params.api_version = "1.4.3";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
