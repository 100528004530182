import React, { FC } from "react";

const TermsOfUse: FC = () => (
  <div className="max-w-5xl p-4 space-y-4">
    <h1>
      <span>Terms of Use</span>
    </h1>
    <br></br>
    <h2 className="mt-4">
      <span>Use of Our Service</span>
    </h2>
    <p>
      <span>
        Matching homeowners with real estate professionals is the core of our
        business. We make an introduction between you and one of our Real Estate
        Agent partners based on your buying and selling goals. The service that
        we provide to both buyers and sellers of real estate and real estate
        professionals is outlined by the terms below.{" "}
      </span>
    </p>
    <p>
      <span>
        The terms of our agreement are set forth below in plain language. If you
        don&rsquo;t agree with the terms, then we ask that you do not submit
        your information to AgentsCorner.com. If you do use this Web site, then
        that action will serve as your agreement to be bound by our terms of
        service.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 className="mt-4">
      <span>Terms for Broker and Agents</span>
    </h2>
    <p>
      <span>
        Real estate professionals who are involved with and use our Web site
        must agree with the following terms:
      </span>
    </p>
    <span className="space-y-2">
      <li>
        <p>
          You grant AgentsCorner permission to display on our Web site and
          elsewhere information that we have gathered or you have supplied using
          your account related to the metrics that we gather and any of the real
          estate transactions that you have handled as a real estate
          professional.{" "}
        </p>
      </li>
      <li>
        <p>
          You are responsible for maintaining your own real estate license and
          for following all applicable real estate laws regarding disclosures,
          documentation and other brokerage responsibilities. In addition, you
          are responsible for the real estate brokerage services provided to
          your clients and agree to indemnify, defend and hold AgentsCorner
          harmless from any claims, costs, and damages incurred by AgentsCorner
          arising from claims by your clients regarding the brokerage services
          you have provided.
        </p>
      </li>
      <li>
        <p>
          Your participation in our service is voluntary and can be terminated
          by either of us for any reason at any time with written notice.
          However, any referrals made prior to such termination are still bound
          by this agreement. In connection with the introductions, you agree to
          be contacted by AgentsCorner and its referrals via phone, SMS, email,
          mail or other reasonable means. It is our goal to help you succeed
          with our referrals.{" "}
        </p>
      </li>
      <li>
        <p>
          You may initiate or receive a call from a AgentsCorner representative
          or one of our referrals via a recorded line. You acknowledge and agree
          that your phone call may be recorded for quality assurance purposes
          only.
        </p>
      </li>
      <li>
        <p>
          Any information that you provide to AgentsCorner shall be accurate,
          complete and owned by you, and you agree to keep your account
          information current. If the information is inaccurate we may correct
          it ourselves through our own means of real estate records and data
          providers. You hereby grant us permission to e-mail or display your
          Profile (including your name, likeness, contact information and
          transaction details) through email marketing
          &ldquo;www.agentscorner.com&rdquo; &nbsp;our partner and affiliate
          websites and marketing efforts as we believe advisable for marketing
          purposes.
        </p>
      </li>
      <li>
        <p>
          You agree that we may modify the services provided, these terms of
          service or the price charged for our services at any time. We will
          send you an email thirty days in advance of any significant changes of
          our services, terms of service or prices, and you agree that your
          continued use of our services after the notice period means that you
          accept the new terms and any subsequent referrals or services shall be
          governed by the new terms.
        </p>
      </li>
    </span>
    <h2 className="mt-4">
      <span>Terms for Buyers and Sellers</span>
    </h2>
    <p>
      <span>
        All non-real estate professionals (including prospective Buyers and
        Sellers and their representatives) who are involved with and use of
        &ldquo;www.AgentsCorner.com&rdquo; must agree with the following terms:
      </span>
    </p>
    <span className="space-y-2">
      <li>
        <p>
          We will make an introduction between you and one of our partners,
          including our real estate agent partners and investor partners. This
          introduction involves sharing the contact information of each party
          with the other with a personalized introduction to both parties via
          SMS and email. When you submit information to AgentsCorner or any of
          our partner sites for a request, you authorize us to use and provide
          this information to make an introduction. By providing this
          information to us, you consent to being contacted by us and by our
          partners via phone, email, SMS or mail.{" "}
        </p>
      </li>
      <li>
        <p>
          For us to help you, we need you to provide information that is
          accurate and complete, especially your contact information so that our
          Real Estate Agent partners can contact you quickly and efficiently. If
          part of your information is inaccurate, you give us permission to
          obtain your contact information through our data partners any other
          means necessary. &nbsp;
        </p>
      </li>
      <li>
        <p>
          You understand that while AgentsCorner may refer real estate
          professionals, we do not perform real estate brokerage services. Any
          Real Estate brokerage activities are performed by local Real Estate
          Professionals that have been referred to you. We do not endorse,
          recommend or otherwise know the terms of any agreement between you and
          a real estate professional.
        </p>
      </li>
      <li>
        <p>
          You understand that for our services we may receive payment for the
          introduction made. There is no charge to you. Any payment is due based
          on a separate agreement between us and the professional involved. Your
          use of our services constitutes your acknowledgment of, and agreement
          to, this compensation arrangement.
        </p>
      </li>
      <li>
        <p>
          Your participation in our service is voluntary and can be terminated
          by either of us for any reason at any time with written notice or
          through unsubscribing to our email list. However, any referrals made
          prior to such termination are still bound by the agreement that we may
          have with a real estate professional.
        </p>
      </li>
      <li>
        <p>
          You agree that we may modify the services provided or these terms of
          service at any time. We will notify you in advance of any significant
          changes to our services, and you agree that your continued use of our
          services after the notice period means that you accept the new terms
          and any subsequent services shall be governed by the new terms.
        </p>
      </li>
      <li>
        <p>
          You may initiate or receive a call from an AgentsCorner representative
          or one of our agent partners via a recorded line. You acknowledge and
          agree that your phone call may be recorded for quality assurance
          purposes only.
        </p>
      </li>
    </span>
    <h2 className="mt-4">
      <span>Additional Terms</span>
    </h2>
    <p>
      <span>
        All who are involved with and use our Web site must agree with the
        following additional terms:
      </span>
    </p>
    <p>
      <span>
        It is the policy of AgentsCorner to respond to all claims of
        intellectual property infringement. We will promptly investigate notices
        of alleged infringement and will take appropriate actions required under
        the Digital Millennium Copyright Act, Title 17, United States Code,
        Section 512(c)(2) (&quot;DMCA&quot;) and other applicable intellectual
        property laws.
      </span>
    </p>
    <p>
      <span>
        Pursuant to the DMCA, notifications of claimed copyright infringement
        should be sent to a Service Provider&#39;s Designated Agent.
        Notification must be submitted to the following Designated Agent for
        these sites:
      </span>
    </p>
    <hr />
    <p>
      <span>AgentsCorner, LLC.</span>
    </p>
    <p>
      <span>President</span>
    </p>
    <p>
      <span>Attn: Graham Gochnear </span>
    </p>
    <p>
      <span>4305 Windsor Centre Dr. Suite 300</span>
    </p>
    <p>
      <span>Flower Mound, Texas 75028</span>
    </p>
    <p>
      <span>USA</span>
    </p>
    <hr />
    <p>
      <span>
        To be effective, the notification must be a written communication that
        includes the following:
      </span>
    </p>
    <span className="space-y-2">
      <li>
        <p>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;
        </p>
      </li>
      <li>
        <p>
          Identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works at that
          site;
        </p>
      </li>
      <li>
        <p>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit the service provider to locate the material;
        </p>
      </li>
      <li>
        <p>
          Information reasonably sufficient to permit the service provider to
          contact the complaining party, such as an address, telephone number
          and, if available, an electronic mail address at which the complaining
          party may be contacted;
        </p>
      </li>
      <li>
        <p>
          A statement that the complaining party has a good-faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent or the law;
        </p>
      </li>
      <li>
        <p>
          A statement that the information in the notification is accurate and,
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </p>
      </li>
    </span>
    <span className="space-y-2">
      <li>
        <p>
          AgentsCorner intends that the information on our website be accurate
          and reliable; however, errors sometimes occur. In addition, we may
          make changes and improvements to the information provided at any time.
          Accordingly, we do not guarantee the accuracy of any information
          available on this Web site, and are not responsible for any errors,
          omissions, or misrepresentations and any information should be
          independently verified.
        </p>
      </li>
      <li>
        <p>
          To protect our service, you agree to refrain from the following
          prohibited activities: (a) submitting materials that are patently
          offensive to the online community, such as content that promotes
          racism, bigotry, hatred or physical harm of any kind against any group
          or individual; (b) engaging in activities or submitting materials that
          could be harmful to minors; (c) engaging in activity or submitting
          materials that harasses or advocates harassment of another person; (d)
          engaging in activity that involves the transmission of &quot;junk
          mail&quot; or unsolicited mass mailing or &quot;spam&quot; or
          harvesting or otherwise collecting personally identifiable information
          about Web site users, including names, phone numbers, addresses, email
          addresses, (collectively, &quot;User Data&quot;) without their
          consent; (e) engaging in activity, or submitting materials, or
          promoting information that is false, misleading or promotes illegal
          activities or conduct that is abusive, threatening, obscene,
          defamatory or libelous; (f) submitting materials that contain
          restricted or password only access pages, or hidden pages or images;
          (g) submitting materials that displays pornographic or sexually
          explicit material of any kind; (h) submitting materials that provide
          instructional information about illegal activities such as making or
          buying illegal weapons, violating someone&#39;s privacy, or providing
          or creating computer viruses; (i) submitting materials that contain
          viruses, Trojan horses, worms, or any other similar forms of malware,
          (j) engaging in activities or submitting materials that solicit
          passwords or personally identifiable information for unlawful purposes
          from other users; (k) engaging in unauthorized commercial activities
          and/or sales without our prior written consent such as advertising,
          solicitations, contests, sweepstakes, barter, and pyramid schemes; (l)
          using any robot, spider, other automatic device, or manual process to
          monitor, copy, or &quot;scrape&quot; web pages or the content
          contained in the Web site or for any other unauthorized purpose
          without our prior written consent; (m) using any device, software, or
          routine to interfere or attempt to interfere with the proper working
          of the Web site; (n) decompiling, reverse engineering, or
          disassembling the software or attempting to do so; or (o) taking any
          action that imposes an unreasonable or disproportionately large load
          on the Web site or our hardware and software infrastructure or that of
          any of our licensors or suppliers.
        </p>
      </li>
      <li>
        <span>You agree to the following limitation: </span>
        <p>
          The web site and the information, software, products and services
          associated with it are provided &quot;As is.&quot; AgentsCorner and/or
          its suppliers, participating lenders, or real estate professionals
          disclaim any warranty of any kind, whether express or implied, as to
          any matter whatsoever relating to the web site and any information,
          software, products and services provided herein, including without
          limitation the implied warranties of merchantability, fitness for a
          particular purpose, title, and noninfringement. Use of
          AgentsCorner&rsquo;s service is at your own risk. We and/or its
          suppliers, are not liable for any direct, indirect, punitive,
          incidental, special or consequential damages or other injury arising
          out of or in any way connected with the use of our services or with
          the delay or inability to use the web site, or for any information,
          software, products and services obtained through the web site, or
          otherwise arising out of the use of the web site, whether resulting in
          whole or in part, from breach of contract, tortious behavior,
          negligence, strict liability or otherwise, even if we and/or its
          suppliers had been advised of the possibility of damages. Some
          jurisdictions do not allow the exclusion of implied warranties, so the
          above exclusion may not apply to you.
        </p>
      </li>
      <li>
        <span>You also agree to the following: </span>
        <p>
          In no event shall AgentsCorner or our suppliers be liable for lost
          profits or any special, incidental or consequential damages (however
          arising, including negligence) arising out of or in connection with
          this agreement. Our liability, and the liability of our suppliers, to
          you or any third parties in any circumstance, is limited to $100.
          &nbsp;Some states do not allow the limitation of liability, so the
          foregoing limitation may not always apply.
        </p>
      </li>
      <li>
        <span>You also agree to the following </span>
        <span>
          dispute resolution, arbitration, and className action waiver:
        </span>
        <span>
          &nbsp;Instead of suing in Court, you and AgentsCorner agree to
          arbitrate all disputes between you and AgentsCorner or any of
          AgentsCorner&rsquo;s representatives, or partners acting on
          AgentsCorner&rsquo;s behalf, on an individual basis, not on a
          className-wide or consolidated basis. The FAA applies to this
          agreement, the arbitration provision, and all questions of whether a
          dispute is subject to arbitration. Unless we each agree otherwise, the
          Arbitration will be conducted by a single, neutral arbitrator and will
          take place in the county of your residence.{" "}
        </span>
        <span>
          We each agree that we will only pursue arbitration on an individual
          basis and will not pursue arbitration on a className-wide or
          consolidated basis.
        </span>
        <span>
          &nbsp;We each agree that any arbitration will be solely between you
          and AgentsCorner&rsquo;s (or AgentsCorner&rsquo;s representatives, or
          partners acting on AgentsCorner&rsquo;s &nbsp;behalf). We each{" "}
        </span>
        <span>
          waive any rights to bring a className action or consolidated action.
        </span>
        <p>
          &nbsp;Exceptions: Either of us may bring qualifying claims in small
          claims court.
        </p>
      </li>
      <li>
        <p>
          Finally, you also agree to the following: These Terms of Service shall
          be subject to and construed in accordance with the laws of the State
          of Texas, without regard to its conflict of laws principles. If any
          part of these Terms of Service are determined to be invalid or
          unenforceable pursuant to applicable law including, but not limited
          to, the warranty disclaimers and liability limitations set forth
          above, then the invalid or unenforceable provision will be deemed
          superseded by a valid enforceable provision that most closely matches
          the intent of the original provision, and the remainder of the
          Agreement shall continue in effect. A printed version of this
          Agreement and of any notice given in electronic form shall be
          admissible in judicial or administrative proceedings based upon or
          relating to this agreement to the same extent and subject to the same
          conditions as other business documents and records originally
          generated and maintained in printed form. All rights not expressly
          granted herein are reserved to AgentsCorner.
        </p>
      </li>
    </span>
  </div>
);

export default TermsOfUse;
