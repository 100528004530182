import React, { FC, useState, useCallback, ChangeEvent } from "react";
import "./PhoneNumberInput.less";
import { Input } from "antd";
import { InputProps } from "antd/lib/input/Input";

type IProps = InputProps;

/* eslint-disable  @typescript-eslint/no-explicit-any */
const normalizeInput = (value: string, previousValue: any) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

//Temporarily supports only US numbers +1
const PhoneNumberInput: FC<IProps> = (props) => {
  const { onChange, defaultValue } = props;
  // const { Option } = Select;
  const [value, setValue] = useState(defaultValue);
  const prefixSelector = "+1";
  {
    // * NOTE: Can uncomment if we support non-US phone numbers
    /* <Form.Item noStyle>
        <Select style={{ width: 70 }} defaultValue={"+1"}>
          <Option value="+1">+1</Option>
        </Select> 
        </Form.Item> */
  }

  const triggerChange = (changedValue: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(changedValue);
    }
  };
  const onValueChange = useCallback(
    (e) => {
      const output = normalizeInput(e.target.value, value);
      setValue(output);
      triggerChange({
        ...e,
        target: {
          ...e.target,
          value: "+1" + output?.match(/\d+/g)?.join("") ?? "",
        },
      });
    },
    [setValue]
  );
  return (
    <Input
      {...props}
      addonBefore={prefixSelector}
      value={value}
      onChange={onValueChange}
    />
  );
};

export default PhoneNumberInput;
