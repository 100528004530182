import React, { FC, useState, useCallback } from "react";

import "./ASMPopUp.less";
import { ASMWidget } from "@components";
import { useSuccessManagerQuery } from "@queries";
import { useScreenSize } from "@utils/hooks";

export const ASMPopUp: FC<{ defaultOpen?: boolean }> = ({
  defaultOpen = false,
}) => {
  const [show, setShow] = useState(defaultOpen);
  const { isSmallScreen } = useScreenSize();
  const classes = show ? "chat active" : "chat";
  const { data: successManager } = useSuccessManagerQuery();

  const handleOnClick = useCallback(() => {
    setShow((state) => !state);
  }, []);

  if (successManager) {
    return (
      <div className="z-top">
        {show && (
          <div
            className="fixed max-w-md sm:right-0 md:right-8 bottom-28"
            // style={{ position: "absolute", right: 40, bottom: 110 }}
          >
            <ASMWidget {...successManager} />
          </div>
        )}
        <div className={classes} onClick={handleOnClick}>
          <div className="fixed background" style={{ zIndex: 99999 }}></div>
          <svg
            className="fixed chat-bubble"
            width="70"
            height="70"
            viewBox="0 0 100 100"
            style={{ zIndex: 99999 }}
          >
            <g className="bubble">
              <path
                className="line line1"
                d="M 30.7873,85.113394 30.7873,46.556405 C 30.7873,41.101961
            36.826342,35.342 40.898074,35.342 H 59.113981 C 63.73287,35.342
            69.29995,40.103201 69.29995,46.784744"
              />
              <path
                className="line line2"
                d="M 13.461999,65.039335 H 58.028684 C
              63.483128,65.039335
              69.243089,59.000293 69.243089,54.928561 V 45.605853 C
              69.243089,40.986964 65.02087,35.419884 58.339327,35.419884"
              />
            </g>
            <circle className="circle circle1" r="1.9" cy="50.7" cx="42.5" />
            <circle className="circle circle2" cx="49.9" cy="50.7" r="1.9" />
            <circle className="circle circle3" r="1.9" cy="50.7" cx="57.3" />
          </svg>
        </div>
      </div>
    );
  }

  return null;
};
