import React, { FC } from "react";
import { Row, Radio, Space, Spin, Result } from "antd";
import { ZipcodeTag, ASMWidgetCompact, LoadingScreen } from "@components";
import { useSuccessManagerQuery } from "@queries";
import { SubscriptionCampaign, SubscriptionOffer } from "@types";
import { ASMWidgetBlock } from "@containers";
import { getCityWithMostZipsFromCityState } from "@utils/campaign";

export interface SubscriptionCampaignDetailsProps {
  campaign?: SubscriptionCampaign;
}

export const SubscriptionCampaignDetails: FC<
  SubscriptionCampaignDetailsProps
> = ({ campaign }) => {
  if (!campaign) return <LoadingScreen />;
  const {
    cityStateToZipCodes,
    monthlyExpectedNumLeads,
    monthlyBudget,
    zipCodes,
  } = campaign;
  const city = getCityWithMostZipsFromCityState(cityStateToZipCodes);
  const zips = zipCodes?.split(",");
  const numZipcodes = zips?.length;
  const avgHomePrice = "$1 million"; // TODO: Need from endpoint or on subscription campaign

  return (
    <div className="pb-16 space-y-2">
      <div className="">
        <div className="px-4 panel-header">
          <Row justify="space-between" className="px-4">
            <div className="flex flex-col">
              <h2 className="font-bold">{city || "Region"}</h2>
              <div>
                {numZipcodes} zip code{numZipcodes && numZipcodes > 1 && "s"}
              </div>
            </div>
            {/* TODO:  
            <div className="flex flex-col">
              <div>Avg. home value:</div>
              <div className="text-blue">{avgHomePrice}</div>
            </div> */}
          </Row>
        </div>

        <div className="panel-w-header">
          <div className="p-4">
            {zips?.map((zip) => (
              <ZipcodeTag key={zip} zipcode={zip} color="#fab115" />
            ))}
          </div>
        </div>
      </div>

      <div className="panel">
        <h2 className="mb-4 font-bold">Monthly Plan</h2>
        <div className="flex justify-center">
          <Radio.Group value={monthlyExpectedNumLeads}>
            <Space direction="vertical">
              <Radio
                value={monthlyExpectedNumLeads}
                key={monthlyExpectedNumLeads}
                className="w-full py-4 pl-6 text-xl border rounded"
                // checked={true}
              >
                <Row justify="space-between">
                  <div className="">
                    {monthlyExpectedNumLeads} introductions per month
                  </div>
                  <div className="pl-8 sm:pl-40 md:pl-72">
                    ${monthlyBudget}/mo
                  </div>
                </Row>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>

      <ASMWidgetBlock />
    </div>
  );
};
