import { appApi } from "./api";
import { WebhookIntegration } from "@types";

export interface UpdateIntegrationRequest {
  integrationId: number;
  name?: string;
  url?: string;
  active?: boolean;
}

export interface AddIntegrationRequest {
  active: boolean;
  name: string;
  url: string;
}

export const integrationsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    webhookIntegrations: build.query<WebhookIntegration[], void>({
      query: () => "/leads/agent-outgoing-webhooks/",
      providesTags: ["Integration"],
    }),
    updateIntegration: build.mutation<
      WebhookIntegration[],
      UpdateIntegrationRequest
    >({
      query: ({ url, name, integrationId }) => ({
        url: `/leads/agent-outgoing-webhooks/${integrationId}/`,
        method: "PATCH",
        body: { url, name },
      }),
      invalidatesTags: ["Integration"],
    }),
    addIntegration: build.mutation<WebhookIntegration, AddIntegrationRequest>({
      query: (data) => ({
        url: "/leads/agent-outgoing-webhooks/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Integration"],
    }),
    deleteIntegration: build.mutation<void, number>({
      query: (integrationId) => ({
        url: `/leads/agent-outgoing-webhooks/${integrationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Integration"],
    }),
  }),
});

export const {
  useWebhookIntegrationsQuery,
  useUpdateIntegrationMutation,
  useAddIntegrationMutation,
  useDeleteIntegrationMutation,
} = integrationsApi;
