import { SignupStage } from "./Signup";

export * from "./Campaign";
export * from "./Agent";
export * from "./Contact";
export * from "./Activity";
export * from "./Payment";
export * from "./WebhookIntegrations";
export * from "./Signup";
export { PropertyType } from "./Property";

export type SuccessManager = {
  name: string;
  phone: string;
  email: string;
  calendlyLink: string;
  imageLow: string;
  imageMedium: string;
  imageHigh: string;
};

export enum LeadType {
  Sellers = "Home Sellers",
  Buyers = "Home Buyers",
}
export type DollarString = string; // * NOTE: Use alias just to be clear that the string value needs to be parsed ex. 300.00
export type CampaignType = "bidding" | "subscription";
export type AgentFeatureSet =  // Notes: https://magnafilis.atlassian.net/wiki/spaces/AC/pages/1371701250/Agent+Monthly+Subscription+Campaign+s+Frontend+Notes
  | "bidding"
  | "subscription"
  | "not_yet_selected"
  | "signup_recommended_bid";
export type SignupFlowVersion = 1 | 2;
export type ISOString = string;

export type Zipcode = string; // * NOTE: use alias to denote 5 digit string of numbers
export interface GMapsQuery {
  id: string; // Google query id (place id?)
  query: string; // formatted name/address Ex. San Diego, CA, USA
  state: string; // Ex. CA
  codes: Zipcode[]; // Zipcodes list
}

export interface SignupStageMeta {
  name: SignupStage;
  step: number;
  path: string;
  page: number;
}

export interface Webhook {
  id: number;
  name: string;
  active: boolean;
  url: string;
}

// Maps to backend values
export enum ContactStage {
  New = "New",
  Contacted = "Contacted",
  MetWith = "Negotiation",
  DealLost = "Deal Lost - Other",
  Nurturing = "Nurture",
  DealMade = "Deal Won",
}

// Maps to customized names we want to display to the user
export const ContactStageName = {
  [ContactStage.New]: "New",
  [ContactStage.Contacted]: "Contacted",
  [ContactStage.MetWith]: "Met with",
  [ContactStage.DealLost]: "Deal lost",
  [ContactStage.Nurturing]: "Nurturing",
  [ContactStage.DealMade]: "Deal made",
};

export interface ReferralCode {
  code: string;
  referringAgentAmount: DollarString;
  referredAgentAmount: DollarString;
}
