import React, { FC, useState, useEffect } from "react";
import {
  Layout,
  Form,
  Input,
  Typography,
  Space,
  notification,
  Alert,
  InputNumber,
} from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";

import { BID_SIGNUP_STAGES } from "@constants";

import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { fetchCreditCards } from "../../../../api/creditcards";
import ContinueButtons from "../../../component/ContinueButtons";
import { CreditCardInfo } from "@components";
import { ASMPopUp, AutoDeposit } from "@containers";
import {
  useActiveCreditCardQuery,
  useAddPaymentInfoMutation,
  useMarkSkipMutation,
} from "@queries";

// Stripe
const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;
const stripePromise = loadStripe(STRIPE_PUB_KEY);

const { Text, Title, Paragraph } = Typography;
const { Content } = Layout;

const AddPayment: FC = () => (
  <Elements stripe={stripePromise}>
    <InjectedAddPayment />
  </Elements>
);

const InjectedAddPayment: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { state } = useLocation();
  const [markSkip] = useMarkSkipMutation();
  const [addPaymentInfo, { isLoading: isAddPaymentLoading }] =
    useAddPaymentInfoMutation();

  const {
    data: creditCard,
    isLoading,
    error: creditCardError,
  } = useActiveCreditCardQuery();

  const bidAmount = (state as { bidAmount: string })?.bidAmount;

  const [stripeIsLoading, setStripeIsLoading] = useState(false);
  const [error, setError] = useState("");

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setStripeIsLoading(true);
      try {
        // Use elements.getElement to get a reference to the mounted Element.
        const cardElement = elements && elements.getElement(CardElement);

        if (!stripe || !cardElement) throw new Error("Stripe Error");
        const card = await stripe.createToken(cardElement);
        if (card?.error) {
          if (card?.error?.message) {
            console.log("StripeError: ", card.error);
            setError(card.error.message);
          } else throw new Error("Stripe Error");
        }
        const { token } = card;

        if (!token) throw new Error("No Stripe token received");
        setError("");
        addPaymentInfo({ ...values, token: token?.id })
          .then((payload) => {
            navigate("/signup/activate", {
              state: { fromPreviousStage: true },
            });
          })
          .catch((error) => {
            notification.error({
              placement: "bottomLeft",
              message: "There was an error with your payment.",
            });
          });
        setStripeIsLoading(false);
      } catch (e) {
        setStripeIsLoading(false);
        console.log("Stripe error: ", (e as Error)?.message);
      }
    });
  };

  const handleSkip = () => {
    markSkip(BID_SIGNUP_STAGES.ADD_PAYMENT.name);
    navigate("/");
  };

  return (
    <>
      <OnboardingLayout step={6}>
        <Content className="space-y-4">
          <span>
            <div className="mt-8 panel-header">
              <h2>Add Funds</h2>
              <Text className="text-base">
                Our system auctions introductions the instant they are
                generated. Prepare your account to make your first bid.
              </Text>
            </div>
            <div className="px-8 panel-w-header">
              {error && (
                <Alert
                  className="mb-4"
                  closable
                  message={error}
                  type="error"
                  showIcon
                  afterClose={() => setError("")}
                />
              )}

              {creditCard && <CreditCardInfo key="card" {...creditCard} />}

              <Text strong style={{ fontSize: 16, color: "#000000D9" }}>
                Payment Method
              </Text>
              <CardElement
                className="py-4"
                options={{ style: { base: { fontSize: "18px" } } }}
              />
              {/* TODO: <ErrorAlert /> */}
              <Form
                form={form}
                {...formItemLayout}
                initialValues={{ amount: bidAmount }}
                className="pt-1"
                layout="vertical"
                size="large"
              >
                <Form.Item
                  name="amount"
                  label={
                    <span>
                      <Title level={5}>Amount</Title>
                      <Text>Add at least the amount of your first bid.</Text>
                    </span>
                  }
                  rules={[
                    {
                      validator: (_, value) =>
                        value >= 26.0
                          ? /^\d{1,6}(\.\d{1,2})?$/.test(value)
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Please enter a valid dollar amount")
                              )
                          : Promise.reject(
                              new Error("The minimum deposit amount is $26.00")
                            ),
                    },
                  ]}
                >
                  <InputNumber
                    className="w-40"
                    size="large"
                    prefix="$"
                    placeholder="26.00"
                    min={26}
                    max={5000}
                  />
                </Form.Item>
              </Form>
            </div>
          </span>

          <AutoDeposit />

          <ContinueButtons
            onSkip={handleSkip}
            submitTitle="Confirm and Deposit to Account"
            onSubmit={handleSubmit}
            isLoading={isAddPaymentLoading || stripeIsLoading}
          />
          <ASMPopUp />
        </Content>
      </OnboardingLayout>
    </>
  );
};

export default AddPayment;
