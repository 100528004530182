import React, { FC } from "react";
import { LoginForm } from "../forms/LoginForm/LoginForm";
import { Image, Layout } from "antd";
import { LoginRequest, useLoginMutation } from "@queries";
import { useNavigate } from "react-router-dom";
import { images } from "@constants";

import { ErrorAlert } from "@components";
import { extractListObjectError } from "@queries/helpers";

export const Login: FC = () => {
  const navigate = useNavigate();
  const [login, { isLoading: loginIsLoading, error }] = useLoginMutation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const loginErrorMsg = error?.data && extractListObjectError(error?.data);

  const handleLogin = async (values: LoginRequest) => {
    console.log("Received values from login form: ", values);
    try {
      await login(values).unwrap();

      navigate("/signup/redirect-to-stage");
    } catch (e) {
      console.log("loginError: ", e);
    }
  };

  return (
    <Layout className="items-center justify-center min-h-screen">
      <div className="max-w-sm">
        <Image
          src={images.AGENTS_CORNER_LOGO}
          preview={false}
          className="max-w-sm mb-8"
        />
        {loginErrorMsg && <ErrorAlert message={loginErrorMsg} />}
        <LoginForm onSubmit={handleLogin} isLoading={loginIsLoading} />
      </div>
    </Layout>
  );
};
