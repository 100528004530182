import { useState, useCallback, useEffect } from "react";
import { GMapsQuery, Campaign } from "@types";
import { fetchZipCodes } from "src/api/campaigns";
import {
  EST_HOME_VALUE_MAX_NOT_SET,
  EST_HOME_VALUE_MIN_NOT_SET,
} from "@constants";

export const setInitialZipCodes = (campaign: Campaign): GMapsQuery[] => {
  const campaignZipCodes = campaign?.zipCodes.split(",").filter((z) => !!z);
  let savedZipCodes: GMapsQuery[] = [];
  if (campaign?.cityStateToZipCodes?.length) {
    const parsedZipcodes = JSON.parse(campaign.cityStateToZipCodes);
    if (parsedZipcodes !== {}) savedZipCodes = parsedZipcodes;
  }

  if (campaignZipCodes?.length) {
    const notInList = campaignZipCodes.filter((campaignZipItem) => {
      let found = false;
      savedZipCodes?.forEach((item) => {
        if (item["codes"].includes(campaignZipItem)) {
          found = true;
        }
      });
      return !found;
    });
    if (notInList?.length) {
      const others = savedZipCodes?.find(
        (item) => item?.id === "OtherZipCodes"
      );
      savedZipCodes = savedZipCodes?.filter(
        (item) => item?.id !== "OtherZipCodes"
      );
      savedZipCodes?.push({
        id: "OtherZipCodes",
        codes: others != null ? [...others["codes"], ...notInList] : notInList,
        query: "",
        state: "",
      });
    }
  }
  return savedZipCodes;
};

export const useZipcodesEditor = (campaign?: Campaign | null) => {
  // useEffect(() => {
  //   if (campaign) setSelectedZipCodes(setInitialZipCodes(campaign));
  // }, [campaign]);

  const [selectedZipCodeGroups, setSelectedZipCodes] = useState<GMapsQuery[]>(
    campaign ? setInitialZipCodes(campaign) : []
  );

  const selectedZipCodesList = selectedZipCodeGroups.reduce(
    (acc: string[], curr) => acc.concat(curr.codes),
    []
  );

  const [placeQuery, setPlaceQuery] = useState<GMapsQuery>({
    id: "", // TODO: Potentially remove since this should be set by Places API call
    query: "",
    state: "",
    codes: [],
  });

  const currentQuerySelectedZipcodes =
    selectedZipCodeGroups.filter((group) => group.id === placeQuery.id)?.[0]
      ?.codes ?? [];

  const currentQueryZipcodes = placeQuery?.codes;

  // * Toggles zipcode selection from current place query
  const toggleZipcode = (zipcode: string) => {
    const zipCodeGroup = selectedZipCodeGroups.find(
      (g) => g.id === placeQuery.id
    );
    if (zipCodeGroup) {
      const zipCode = zipCodeGroup.codes.find((code) => code === zipcode);
      const changes = {
        ...zipCodeGroup,
        codes: zipCode
          ? zipCodeGroup.codes.filter((z) => z !== zipCode)
          : zipCodeGroup.codes.concat(zipcode),
      };
      const changedSelectedZipCodes = changes.codes.length
        ? selectedZipCodeGroups.map((s) =>
            s.id === placeQuery.id ? changes : s
          )
        : selectedZipCodeGroups.filter((s) => s.id !== placeQuery.id);
      setSelectedZipCodes(changedSelectedZipCodes);
    } else {
      setSelectedZipCodes(
        selectedZipCodeGroups.concat({
          id: placeQuery.id,
          query: placeQuery.query,
          state: placeQuery.state,
          codes: [zipcode],
        })
      );
    }
  };

  const manuallyAddZipcode = useCallback(
    (code) => {
      const othersKey = "OtherZipCodes";
      const zipCodeGroup = selectedZipCodeGroups.find(
        (g) => g.id === othersKey
      );
      if (zipCodeGroup) {
        const includesZip = zipCodeGroup.codes.includes(code);
        const changes = {
          ...zipCodeGroup,
          codes: includesZip
            ? zipCodeGroup.codes.filter((c) => c !== code)
            : zipCodeGroup.codes.concat(code),
        };
        const changedSelectedZipCodes = changes.codes.length
          ? selectedZipCodeGroups.map((s) => (s.id === othersKey ? changes : s))
          : selectedZipCodeGroups.filter((s) => s.id !== othersKey);
        setSelectedZipCodes(changedSelectedZipCodes);
      } else {
        setSelectedZipCodes([
          ...selectedZipCodeGroups,
          {
            id: othersKey,
            query: "",
            state: "",
            codes: [code],
          },
        ]);
      }
    },
    [selectedZipCodeGroups]
  );

  const deleteZipcode = (placeId: string, zipcode: string) => {
    const zipCodeGroup = selectedZipCodeGroups.find(
      (group) => group.id === placeId
    );
    const changedSelectedZipCodes =
      zipCodeGroup?.codes.length === 1
        ? selectedZipCodeGroups.filter((group) => group.id !== placeId)
        : selectedZipCodeGroups.map((group) =>
            group.id === placeId
              ? {
                  ...group,
                  codes: group.codes.filter((code) => code !== zipcode),
                }
              : group
          );
    setSelectedZipCodes(changedSelectedZipCodes);
  };

  const selectAll = () => {
    const zipCodeGroup = selectedZipCodeGroups.find(
      (g) => g.id === placeQuery.id
    );
    const changedSelectedZipCodes = zipCodeGroup
      ? selectedZipCodeGroups.map((g) =>
          g.id === zipCodeGroup.id ? placeQuery : g
        )
      : selectedZipCodeGroups
          .filter((g) => g.id !== placeQuery.id)
          .concat(placeQuery);
    setSelectedZipCodes(changedSelectedZipCodes);
  };

  const unselectAll = () => {
    setSelectedZipCodes(
      selectedZipCodeGroups.filter((g) => g.id !== placeQuery.id)
    );
  };

  async function getZipCodes(address: string) {
    try {
      const [city, state] = address.split(",").map((word) => word.trim());
      const response = await fetchZipCodes(city, state);
      return response.data.zipCodes;
    } catch (err) {
      console.error("getZipCodes error");
    }
  }

  // For refetching available zipcodes for an already selected query
  const setQueryByPlace = async (placeId: string) => {
    const selectedGroup = selectedZipCodeGroups.find(
      (group) => group.id === placeId
    );
    if (!selectedGroup?.query) return;
    const zipcodes = await getZipCodes(selectedGroup.query);
    if (zipcodes) setPlaceQuery({ ...selectedGroup, codes: zipcodes });
  };

  const clearCurrentQuery = () => {
    setPlaceQuery({
      id: "",
      query: "",
      state: "",
      codes: [],
    });
  };

  const removeSelectedPlace = (placeId: string) => {
    const filteredZipcodeGroups = selectedZipCodeGroups?.filter(
      (group) => group?.id !== placeId
    );
    setSelectedZipCodes(filteredZipcodeGroups);
  };

  return {
    selectedZipCodeGroups,
    selectedZipCodesList,
    placeQuery,
    currentQueryZipcodes,
    currentQuerySelectedZipcodes,
    setQueryByPlace,
    setPlaceQuery,
    manuallyAddZipcode,
    toggleZipcode,
    deleteZipcode,
    selectAll,
    unselectAll,
    clearCurrentQuery,
    removeSelectedPlace,
  };
};

export const getCityWithMostZipsFromCityState = (
  cityStateToZips?: string
): string | null => {
  if (!cityStateToZips) return null;
  const zips: GMapsQuery[] = JSON.parse(cityStateToZips);
  if (!zips) return null;
  const objWithMostZips = zips?.reduce((prev, current) =>
    current?.codes?.length > prev?.codes?.length ? current : prev
  );
  return objWithMostZips?.query?.split(",")?.[0];
};
export const getCitiesNames = ({
  cityStateToZipCodes,
  overflowAfter = 3,
}: {
  cityStateToZipCodes?: string;
  overflowAfter?: number | false;
}): string | null => {
  if (!cityStateToZipCodes) return null;
  const zips: GMapsQuery[] = JSON.parse(cityStateToZipCodes);
  if (!zips) return null;
  // Commas separated list of all cities
  const citiesList = zips
    ?.sort((a, b) => b?.codes?.length - a?.codes?.length)
    ?.map((zipsObj) => zipsObj?.query?.split(/\s*,\s*/)?.[0])
    .filter((city) => city);

  if (!overflowAfter) return citiesList?.join(", ");
  const numOverflow = citiesList?.length - overflowAfter;
  if (numOverflow > 0)
    return citiesList
      .slice(0, overflowAfter)
      ?.join(", ")
      .concat(`, and ${numOverflow} more`);
  return citiesList?.join(", ");
};

export const isEstHomeValueMinSet = (value?: number): boolean =>
  value !== EST_HOME_VALUE_MIN_NOT_SET;

export const isEstHomeValueMaxSet = (value?: number): boolean =>
  value !== EST_HOME_VALUE_MAX_NOT_SET;
