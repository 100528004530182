import React, { useMemo, useState } from "react";
import {
  Tag,
  PageHeader,
  Button,
  Row,
  Col,
  Switch,
  Modal,
  notification,
} from "antd";
import { LeadsChart } from "../../../../stories/LeadsChart/LeadsChart";
import { BidSuccessChart } from "../../../../stories/BidSuccessChart/BidSuccessChart";
import { BidOptimizationWidget } from "../../../../stories/BidOptimizationWidget/BidOptimizationWidget";
import { DashboardPanel } from "../../../dashboard/components/DashboardPanel/DashboardPanel";
import { BidCampaign, CampaignStatus, GMapsQuery } from "@types";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  CampaignEditor,
  CityZipcodesList,
  MaterialIcon,
  Stat,
  ZipcodeTags,
} from "@components";
import { useGetAgentQuery, useUpdateCampaignMutation } from "@queries";
import { isEstHomeValueMaxSet, isEstHomeValueMinSet } from "@utils/campaign";

interface CampaignDetailsProps {
  /**
   * Campaign to display
   */
  campaign: BidCampaign;
}

/**
 * Campaign metrics and settings for bid type campaign
 */
export const CampaignDetails: React.FC<CampaignDetailsProps> = ({
  campaign,
}) => {
  const {
    name,
    status,
    numLeadsWon,
    zipCodes,
    weeklyBudget,
    amountSpent,
    bidAmount,
    estimatedHomeValueMax,
    estimatedHomeValueMin,
    propertyType,
    cityStateToZipCodes,
  } = campaign;
  const { data: agent } = useGetAgentQuery();
  const isActive = status === "Active";
  const canEdit = !name?.toLowerCase()?.includes("do not edit");

  const formattedPropertyTypes = useMemo(
    () =>
      propertyType?.length > 0
        ? propertyType.reduce((a, b) => `${a}, ${b}`)
        : "All",
    [propertyType]
  );

  const [isEditing, setIsEditing] = useState(false);
  const zipCodesList: string[] = zipCodes?.split(",");
  const parsedCityZipcodes: GMapsQuery[] = useMemo(() => {
    try {
      return JSON.parse(cityStateToZipCodes);
    } catch (e) {
      // Fallback to zipCodes list if parsing error
      return [];
    }
  }, [cityStateToZipCodes]);

  const [updateCampaign, { isLoading: updateIsLoading }] =
    useUpdateCampaignMutation();

  const hasBudget = useMemo(() => parseFloat(weeklyBudget) > 0, [weeklyBudget]);
  const budgetValueOrText = useMemo(
    () => (hasBudget ? weeklyBudget : "None"),
    [weeklyBudget]
  );
  const budgetPrefixOrEmpty = useMemo(
    () => (hasBudget ? "$" : ""),
    [weeklyBudget]
  );

  const estimatedHomeValueMinOrText = useMemo(
    () =>
      isEstHomeValueMinSet(estimatedHomeValueMin)
        ? `$${estimatedHomeValueMin}`
        : "None",
    [estimatedHomeValueMin]
  );
  const estimatedHomeValueMaxOrText = useMemo(
    () =>
      isEstHomeValueMaxSet(estimatedHomeValueMax)
        ? `$${estimatedHomeValueMax}`
        : "None",
    [estimatedHomeValueMax]
  );

  const confirmStatus = () => {
    Modal.confirm({
      title: isActive ? "Deactivate Campaign" : "Activate Campaign",
      onOk: handleOk,
      okText: isActive ? "Deactivate" : "Activate",
    });
  };

  const handleOk = () => {
    updateCampaign({
      id: campaign?.id,
      status: isActive ? CampaignStatus.NotActive : CampaignStatus.Active,
    })
      .unwrap()
      .then(() => {
        if (isActive)
          notification.warning({
            placement: "topRight",
            message: "Your campaign has been deactivated.",
          });
        else
          notification.success({
            placement: "topRight",
            message: "Your campaign has been activated.",
          });
      })
      .catch(() => {
        notification.error({
          placement: "topRight",
          message: "Unable to update campaign status.",
        });
      });
  };

  // TODO: Campaign metrics charts: LeadsChart, BidOptimizationWidget, etc.

  return (
    <div className="max-w-4xl mx-auto mt-6">
      <Row
        justify="end"
        className="mr-6"
        style={{ color: updateIsLoading ? "black" : "transparent" }}
      >
        Updating...
        <LoadingOutlined
          className="ml-2"
          key="loading"
          style={{
            fontSize: 24,
          }}
          spin
        />
      </Row>
      <PageHeader
        className="px-0"
        backIcon={
          <div className="p-2 rounded-full hover:bg-grays-first">
            <Link to="/campaigns" className="hover:text-black">
              <MaterialIcon icon="arrow_back" className="align-middle" />
            </Link>
          </div>
        }
        onBack={() => {
          return; // Back navigation handled by link component
        }}
        title={<h1>{name}</h1>}
        tags={[
          <Tag key="activeTag" color={isActive ? "gold" : "default"}>
            {status}
          </Tag>,
          <Switch
            key="activeSwitch"
            checked={isActive}
            onClick={confirmStatus}
          />,
        ]}
        {...(agent?.isSignupFlowComplete && canEdit
          ? {
              extra: [
                <Button
                  key="edit"
                  type="primary"
                  className="px-8"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>,
              ],
            }
          : null)}
      />
      {isEditing && (
        <CampaignEditor
          visible={isEditing}
          onClose={() => setIsEditing(false)}
          campaign={campaign}
        />
      )}
      <div className="max-w-4xl mx-auto space-y-4">
        <Row className="px-4">
          <Stat
            className="border-green"
            title="Total Won"
            value={numLeadsWon}
            suffix="Leads"
          />
          <Stat
            className="border-green"
            title="Total Spent"
            value={amountSpent}
            prefix="$"
          />
          <Stat
            className="border-blue"
            title="Weekly Budget"
            value={budgetValueOrText}
            prefix={budgetPrefixOrEmpty}
          />
        </Row>

        <Col>
          <DashboardPanel title="Campaign Settings" className="text-base">
            <div className="w-full px-2 mt-2 space-y-2">
              <div className="flex px-4 bg-gray-100 rounded-lg">
                <span className="flex-1 font-bold">Bid</span>
                <span className="flex-1">${bidAmount}</span>
              </div>
              <div className="flex px-4">
                <span className="flex-1 font-bold">Budget</span>
                <span className="flex-1">
                  {hasBudget ? `$${budgetValueOrText}/week` : budgetValueOrText}
                </span>
              </div>
              <div className="flex px-4 bg-gray-100 rounded-lg ">
                <span className="flex-1 font-bold">Amount Spent</span>
                <span className="flex-1">${amountSpent}</span>
              </div>
              <div className="flex px-4">
                <span className="flex-1 font-bold">Est. Home Value Min</span>
                <span className="flex-1">{estimatedHomeValueMinOrText}</span>
              </div>
              <div className="flex px-4 bg-gray-100 rounded-lg ">
                <span className="flex-1 font-bold">Est. Home Value Max</span>
                <span className="flex-1">{estimatedHomeValueMaxOrText}</span>
              </div>
              <div className="flex px-4">
                <span className="flex-1 font-bold">Property Type</span>
                <span className="flex-1">{formattedPropertyTypes}</span>
              </div>
            </div>
          </DashboardPanel>
        </Col>
        <Col>
          <DashboardPanel title="Region" className="text-base">
            {parsedCityZipcodes?.length ? (
              <CityZipcodesList cityStateToZipCodes={parsedCityZipcodes} />
            ) : (
              <ZipcodeTags
                zipcodes={zipCodesList}
                zipcodesBeforeOverflow={1000}
              />
            )}
          </DashboardPanel>
        </Col>
      </div>
    </div>
  );
};
