import React from "react";
import { Form, Input, Checkbox, Button, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

interface LoginFormProps {
  onSubmit: (values: any) => void;
  isLoading?: boolean;
}

/**
 * Login Form
 */
export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  isLoading = false,
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      name="login"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your Username!" }]}
      >
        <Input prefix={<UserOutlined className="" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <Input
          prefix={<LockOutlined className="" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <div className="flex justify-between">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Link to="/forgot-password">Forgot password</Link>
        </div>
      </Form.Item>

      <Form.Item>
        <Spin spinning={isLoading}>
          <Button type="primary" htmlType="submit" className="" block>
            Log in
          </Button>
        </Spin>
        <div className="mt-4 text-center">
          Or <Link to="/signup">register now!</Link>
        </div>
      </Form.Item>
    </Form>
  );
};
