import { DollarString, ISOString } from "@types";

export interface Campaign {
  id: number;
  name: string;
  cityStateToZipCodes: string;
  zipCodes: string;
  status: CampaignStatus;
  states: string[];
  numLeadsWon: number;
}

export interface BidCampaign extends Campaign {
  bidAmount: string;
  dailyBudget: string;
  weeklyBudget: string;
  propertyType: string[];
  timeframe: string[];
  isArchived: boolean;
  isDemo: boolean;
  amountSpent: string;
  estimatedHomeValueMin: number;
  estimatedHomeValueMax: number;
  monthlyBudget: DollarString;
}

export interface SubscriptionCampaign extends Campaign {
  created: ISOString;
  monthlyExpectedNumLeads: number | null;
  monthlyBudget: DollarString | null;
}

export enum CampaignStatus {
  Active = "Active",
  NotActive = "Not Active",
}
