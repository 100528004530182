import React from "react";
import { Typography, Button, Avatar, Row, Col } from "antd";
import { SuccessManager } from "@types";
import { CopyableButton, MaterialIcon } from "..";

const { Text } = Typography;

export const ASMDashboardBlock: React.FC<SuccessManager> = ({
  imageLow,
  name,
  phone,
  calendlyLink,
  email,
}) => {
  if (!name || !phone) return null;

  return (
    <div className="p-4 bg-white border rounded-lg">
      <Row justify="start" gutter={{ xs: 16 }}>
        <Col xs={8} md={4}>
          <Avatar
            src={imageLow}
            size={80}
            alt="success manager photo"
            className="mx-auto sm:mx-4"
          />
        </Col>
        <Col xs={16} md={8}>
          <div className="text-base">
            Contact {name}, your success manager, to optimize your campaign.
          </div>
        </Col>
        <Col xs={24} md={12} className="flex items-center justify-center">
          <CopyableButton copyText={phone}>
            <MaterialIcon
              icon="phone"
              className="mr-1 align-middle text-blue"
            />
            Call
          </CopyableButton>
          <CopyableButton copyText={email}>
            <MaterialIcon
              icon="email"
              className="mr-1 align-middle text-blue"
            />
            Email
          </CopyableButton>
          <Button
            type="text"
            className=""
            href={calendlyLink}
            referrerPolicy="no-referrer"
            target="_blank"
          >
            <MaterialIcon
              icon="event_available"
              className="mr-1 align-middle text-blue"
            />
            Schedule a call
          </Button>
        </Col>
      </Row>
    </div>
  );
};
