import React from "react";
import { Result, Spin, Empty } from "antd";
import { useGetContactActivityQuery } from "@queries";
import {
  Activity,
  ActivityType,
  NoteActivity,
  StageChangeActivity,
} from "@types";
import { Loading, MaterialIcon } from "@components";
import { formatDateString } from "@utils/formatters";
import { ContactStage } from "@types";
import { UpdateContactBar } from "./UpdateContactBar";

export interface SelectedContact {
  contactId: number;
  currentContactStage: ContactStage;
}

export const SelectedContactActivity: React.FC<SelectedContact> = ({
  contactId,
  currentContactStage,
}) => {
  const { data, isLoading, isFetching, error } =
    useGetContactActivityQuery(contactId);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Result
        status="warning"
        title="We weren't able to get your leads. Please try refreshing the page or contacting your success manager."
      />
    );
  }

  const ActivityItem = ({ data, created, type }: Activity) => {
    if (type === ActivityType.Note) {
      const note = (data as NoteActivity["data"])?.note;
      return (
        <div className="flex justify-between p-4">
          <span>
            <MaterialIcon
              icon="sticky_note_2"
              className="mr-1 text-base align-bottom"
            />
            {note}
          </span>
          <div className="whitespace-nowrap">{formatDateString(created)}</div>
        </div>
      );
    }
    if (type === ActivityType.StageChanged) {
      const toStage = (data as StageChangeActivity["data"])?.to;
      return (
        <div className="flex justify-between p-4">
          <span>
            <MaterialIcon
              icon="person"
              className="mr-1 text-base align-bottom"
            />
            Stage changed to {/* <ContactStageTag subtle status={toStage} /> */}{" "}
            {toStage}
          </span>
          <div>{formatDateString(created)}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-4">
      <UpdateContactBar
        contactId={contactId}
        currentContactStage={currentContactStage}
      />
      <div className="px-4">
        <Spin spinning={isFetching}>
          {data && data?.length > 0 ? (
            data?.map((item) => <ActivityItem key={item.id} {...item} />)
          ) : (
            <Empty
              description={`No Activity Yet. Stay up to date with your contacts 
                              by adding a note or updating the contact stage.`}
            />
          )}
        </Spin>
      </div>
    </div>
  );
};
