import React, { FC } from "react";
import "./OnboardingLayout.less";
import { Layout } from "antd";
import OnboardingStepper from "../OnboardingStepper";

const { Content } = Layout;

interface IProps {
  children: React.ReactNode;
  step?: number;
  title?: string;
}

const OnboardingLayout: FC<IProps> = ({ children, step = 1, title }) => {
  return (
    <Layout className="layout">
      <OnboardingStepper step={step} totalSteps={7} title={title} />
      <Content className="layout-content">{children}</Content>
    </Layout>
  );
};

export default OnboardingLayout;
