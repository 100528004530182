import React from "react";
import { Tag, TagProps } from "antd";

interface ZipcodeTagProps {
  /**
   * Zipcode
   */
  zipcode: string;
}

/**
 * Primary UI component for user interaction
 */
export const ZipcodeTag: React.FC<ZipcodeTagProps & TagProps> = ({
  zipcode,
  ...props
}) => {
  return (
    <Tag
      {...props}
      className="px-4 text-base border-0 rounded-full bg-grays-first"
    >
      {zipcode}
    </Tag>
  );
};
