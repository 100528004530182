import { SignupResponse } from "@types";
import { appApi } from "./api";

export interface LoginRequest {
  email: string;
  password: string;
  remember?: boolean;
  agentSignupFlowVersion?: number;
}

export interface LoginResponse {
  access: string;
  emailVerified: boolean;
  fullyVerified: boolean;
  hasAgent: boolean;
  phonenumberVerified: boolean;
  refresh: string;
  verified: false;
}

export interface SignupRequest {
  firstName: string;
  lastName: string;
  phonenumber: string;
  email: string;
  password: string;
  referralCode: string;
  fromLink: boolean;

  // * NOTE: Fields below will be automatically added to signup request
  // getAuthToken: boolean,
  // agentSignupFlowVersion: 2;
}

const AUTH_API_BASE_PATH = "/auth";

export const authApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (data) => ({
        url: `${AUTH_API_BASE_PATH}/login/`,
        method: "POST",
        body: data,
      }),
    }),
    signup: build.mutation<SignupResponse, SignupRequest>({
      query: (data) => ({
        url: `${AUTH_API_BASE_PATH}/users/`,
        method: "POST",
        body: { ...data, agentSignupFlowVersion: 2, getAuthToken: true },
      }),
    }),
    forgotPassword: build.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: `${AUTH_API_BASE_PATH}/users/reset_password/`,
        method: "POST",
        body: { email },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useForgotPasswordMutation,
} = authApi;
