import React from "react";
import { useLocation } from "react-router-dom";
import { CampaignDetails } from "./components/CampaignDetails/CampaignDetails";
import { selectCampaignById } from "@queries/select";
import { Layout, Spin } from "antd";
import { GenericError, Loading } from "@components";

const { Content } = Layout;

export const CampaignDetailsPage: React.FC = () => {
  const { pathname } = useLocation();
  const campaignId = parseInt(pathname.split("/").pop() as string, 10);

  const { campaign, isLoading, error } = selectCampaignById(campaignId);

  if (error) {
    return <GenericError />;
  }

  if (isLoading || !campaign) {
    return <Loading />;
  }

  console.log("Campaign Id: ", campaignId);

  return (
    <Content className="container mx-auto">
      <CampaignDetails campaign={campaign} />
    </Content>
  );
};
