import React from "react";
import {
  Activity,
  ActivityType,
  NoteActivity,
  StageChangeActivity,
} from "@types";
import { Spin, Button, Result, BackTop, Row } from "antd";
import { Loading, MaterialIcon } from "@components";
import { formatDateString } from "@utils/formatters";
import {
  useGetContactActivityQuery,
  useLazyGetContactActivityQuery,
} from "@queries";
import { Link } from "react-router-dom";

export const AllActivity: React.FC = () => {
  const [getAllActivity] = useLazyGetContactActivityQuery();
  const { data, isLoading, isFetching, error } = useGetContactActivityQuery();

  if (isLoading) {
    return <Loading />;
  }

  const handleRetry = () => {
    getAllActivity();
  };

  if (error) {
    return (
      <Result
        status="warning"
        title="There was an error retrieving your activity."
        extra={
          <Spin spinning={isFetching}>
            <Button onClick={handleRetry}>Retry</Button>
          </Spin>
        }
      />
    );
  }
  const ActivityItem = ({
    data,
    created,
    type,
    lead: { firstName, lastName, id },
  }: Activity) => {
    if (type === ActivityType.Note) {
      const note = (data as NoteActivity["data"])?.note;
      return (
        <div className="pt-4">
          <Row justify="space-between">
            <Link
              to={`/contacts/${id}`}
              className="text-center hover:text-black"
            >
              <div className="text-sm font-bold">
                <MaterialIcon
                  icon="sticky_note_2"
                  className="mr-1 text-sm align-top"
                />
                {firstName} {lastName}
              </div>
            </Link>

            <div>{formatDateString(created)}</div>
          </Row>

          <p>{note}</p>
        </div>
      );
    }
    if (type === ActivityType.StageChanged) {
      const toStage = (data as StageChangeActivity["data"])?.to;
      return (
        <div className="pt-4">
          <Row justify="space-between">
            <Link
              to={`/contacts/${id}`}
              className="text-center hover:text-black"
            >
              <div className="text-sm font-bold">
                <MaterialIcon
                  icon="person"
                  className="mr-1 text-sm align-top"
                />
                {firstName} {lastName}
              </div>
            </Link>

            <div>{formatDateString(created)}</div>
          </Row>

          <p>
            Stage changed to {/* <ContactStageTag subtle status={toStage} /> */}{" "}
            {toStage}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <BackTop />
      <div className="px-8 py-4 space-y-4 divide-y-2">
        {data?.map((item) => (
          <ActivityItem key={item.id} {...item} />
        ))}
      </div>
    </>
  );
};
