import React, { FC } from "react";
import {
  Form,
  Button,
  Row,
  Input,
  Select,
  Layout,
  Spin,
  notification,
  Result,
} from "antd";
import { REFUND_REASONS } from "./constants";
import { useRefundLeadsQuery, useRefundRequestMutation } from "@queries";

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

export const RefundRequest: FC = () => {
  const [form] = Form.useForm();
  const { data: refundLeads, isLoading, error } = useRefundLeadsQuery();
  const [requestRefund, { isLoading: isRequesting }] =
    useRefundRequestMutation();

  const handleSubmit = async (refundDetails: {
    leadId: number;
    reason: string;
    comment: string;
  }) => {
    try {
      await requestRefund(refundDetails).unwrap();
      form.resetFields();

      notification.success({
        placement: "bottomLeft",
        type: "success",
        message: "Refund request submitted.",
      });
    } catch (error) {
      notification.error({
        placement: "bottomLeft",
        type: "error",
        message:
          (error as any)?.data?.detail || "Unable to send refund request.",
      });
    }
  };

  if (error)
    return (
      <Result
        status="warning"
        title="Unable to retrieve your contacts eligible for refund."
      />
    );

  return (
    <Content className="container pt-8 mx-auto">
      <h2 className="mb-4 text-center">Refund Request</h2>
      <Spin spinning={isLoading}>
        {refundLeads?.length ? (
          <Form
            form={form}
            className="max-w-2xl mx-auto"
            onFinish={handleSubmit}
            layout="vertical"
            requiredMark={false}
            initialValues={{ comment: "" }}
          >
            <hr />
            <Form.Item
              className=""
              name="leadId"
              label="Lead Name"
              rules={[{ required: true, message: "Select a lead." }]}
            >
              <Select>
                {refundLeads?.map(({ firstName, lastName, id }) => (
                  <Option key={id} value={id}>
                    {firstName} {lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className=""
              name="reason"
              label="Reason for request"
              rules={[{ required: true, message: "Reason is required" }]}
            >
              <Select>
                {REFUND_REASONS.map(({ value, label }) => (
                  <Option key={label} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className=""
              name="comment"
              label="Additional Comments"
              rules={[{ required: true, message: "Please provide details" }]}
            >
              <TextArea rows={5} maxLength={1000} />
            </Form.Item>
            <hr />
            <p className="mb-4 italic">
              You can only request a refund within 7 days of winning the lead.{" "}
              <span>
                For more information, click
                <a
                  href=""
                  target="https://agents-corner.helpscoutdocs.com/article/5-can-i-get-a-refund-for-a-lead"
                >
                  &nbsp;here
                </a>
              </span>
            </p>
            <Row justify="center">
              <Button
                className="px-10"
                htmlType="submit"
                loading={isLoading || isRequesting}
              >
                Submit
              </Button>
            </Row>
          </Form>
        ) : (
          <div className="max-w-2xl mx-auto space-y-4">
            <hr />
            <p>No leads eligible for refund.</p>
            <hr />
            <p className="mb-4 italic">
              You can only request a refund within 7 days of winning the lead.{" "}
              <span>
                For more information, click
                <a
                  href=""
                  target="https://agents-corner.helpscoutdocs.com/article/5-can-i-get-a-refund-for-a-lead"
                >
                  &nbsp;here
                </a>
              </span>
            </p>
          </div>
        )}
      </Spin>
    </Content>
  );
};
