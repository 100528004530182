import React from "react";
import PropTypes from "prop-types";
import { Table, Image, Tag, Tooltip } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { CREDIT_CARD_ICONS } from "@images";
import { CardData, CreditCard } from "@types";

export const StatusComponent: React.FC<Partial<CreditCard>> = ({
  lastChargedAt,
  lastChargeSucceeded,
  lastChargeError,
}) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {lastChargeError}
    </Tooltip>
  );

  const variantText =
    lastChargeSucceeded || lastChargeError === "" ? "ACTIVE" : "ERROR";

  if (lastChargeError) {
    return (
      //   <OverlayTrigger
      //     placement="right"
      //     delay={{ show: 250, hide: 400 }}
      //     overlay={renderTooltip}
      //   >
      <Tag icon={<CheckCircleOutlined />}>{variantText}</Tag>
      //   </OverlayTrigger>
    );
  }

  return (
    <Tag icon={<CheckCircleOutlined />} color="success">
      {variantText}
    </Tag>
  );
};

export const CreditCardInfo: React.FC<CreditCard> = ({
  cardData,
  lastChargedAt,
  lastChargeError,
  lastChargeSucceeded,
}) => {
  const { brand, last4, expMonth, expYear } = cardData;
  const cardBrandImg = brand && CREDIT_CARD_ICONS[brand.toUpperCase()];

  return (
    <div className="px-8 panel">
      <div className="flex justify-between">
        <div>
          <Image src={cardBrandImg} className="h-5 mr-4" preview={false} />
          {brand}
        </div>
        <div>•••• {last4}</div>
        <div>
          <small>
            <i>Exp </i>
          </small>
          {expMonth && expMonth.toString().padStart(2, "0")}/
          {expYear && expYear.toString().slice(2, 4)}
        </div>
        <div>
          <StatusComponent
            lastChargedAt={lastChargedAt}
            lastChargeError={lastChargeError}
            lastChargeSucceeded={lastChargeSucceeded}
          />
        </div>
      </div>
    </div>
  );
};
