import React, { FC } from "react";
import { Result } from "antd";

export const GenericError: FC = () => {
  return (
    <Result
      status="warning"
      title="That was unexpected!"
      subTitle="Please try refreshing the page."
    />
  );
};
