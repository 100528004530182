import React from "react";
import { Grid, Button, Avatar, Row } from "antd";
import { SuccessManager } from "@types";
import "./ASMWidget.less";
import { CopyableButton, MaterialIcon } from "..";

const { useBreakpoint } = Grid;

export const ASMWidgetCompact: React.FC<SuccessManager> = ({
  imageLow,
  name,
  phone,
  calendlyLink,
  email,
}) => {
  if (!name || !phone) return null;

  const { xs } = useBreakpoint();

  return (
    <div className="flex flex-col p-4 text-black bg-gray-100 rounded-lg sm:flex-row">
      <Avatar
        src={imageLow}
        size={90}
        alt="success manager photo"
        className="mx-auto sm:mx-4"
      />
      <div className="text-center sm:text-left">
        <div className="font-semibold">Your Agent Success Manager</div>
        <div className="text-lg font-bold">{name}</div>
        <Row className="flex-col items-center sm:space-x-4 sm:flex-row">
          <CopyableButton
            copyText={email}
            className="mt-2 bg-gray-200"
            {...(xs && { block: true })}
          >
            <MaterialIcon icon="email" className="mr-1 align-top" />
            Message
          </CopyableButton>
          <CopyableButton
            copyText={phone}
            className="mt-2 bg-gray-200"
            {...(xs && { block: true })}
          >
            <MaterialIcon icon="phone" className="mr-1 align-top" />
            Call
          </CopyableButton>
          <Button
            type="text"
            className="mt-2 bg-gray-200"
            href={calendlyLink}
            referrerPolicy="no-referrer"
            target="_blank"
            {...(xs && { block: true })}
          >
            Schedule a call
          </Button>
        </Row>
      </div>
    </div>
  );
};
