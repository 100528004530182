import React from "react";
import { Button } from "antd";

interface ButtonPairProps {
  cancelText?: string;
  onCancel?: () => void;
  submitText?: string;
  onSubmit: () => void;
}

export const ButtonPair: React.FC<ButtonPairProps> = ({
  cancelText = "Back",
  submitText = "Next",
  onCancel,
  onSubmit,
}) => {
  return (
    <div className="flex justify-center">
      {onCancel && (
        <Button type="text" className="mx-2 max-w-btn" block onClick={onCancel}>
          {cancelText}
        </Button>
      )}
      <Button
        type="primary"
        block
        className="mx-2 max-w-btn"
        onClick={onSubmit}
      >
        {submitText}
      </Button>
    </div>
  );
};
