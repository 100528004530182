import React, { FC, useState } from "react";
import {
  Button,
  Layout,
  Form,
  Input,
  Typography,
  Row,
  Image,
  Alert,
  Collapse,
  FormInstance,
  Slider,
  Checkbox,
  Col,
  notification,
} from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";
import IntroductionsInfoModal from "../../../component/IntroductionsInfoModal";

import "./SetCampaignBid.less";
import { useNavigate } from "react-router-dom";
import ContinueButtons from "../../../component/ContinueButtons";
import { ASMPopUp } from "@containers";
import { MINIMUM_BID_AMOUNT } from "@constants";
import {
  HOME_PRICE_MARKS,
  BID_SIGNUP_STAGES,
  PROPERTY_TYPES,
} from "@constants";
import {
  useMarkSkipMutation,
  useSetCampaignBidMutation,
  useV2Signup,
} from "@queries";
import { ZipcodeTags } from "@components";
import { BackToZipcodes } from "src/v2/component/BackToZipcodes";

const { Text, Title, Paragraph } = Typography;

const SetCampaignBid: FC = () => {
  const { Content } = Layout;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [markSkip] = useMarkSkipMutation();
  const [setCampaignBid, { isLoading, error }] = useSetCampaignBidMutation();

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      // If price set is $0 or $1M+, value is set to undefined to
      // effectively not set a min or max
      const estimatedHomeValueMin =
        values.homePrice && HOME_PRICE_MARKS[values.homePrice[0]].value;
      const estimatedHomeValueMax =
        values.homePrice && HOME_PRICE_MARKS[values.homePrice[1]].value;
      const data = { ...values, estimatedHomeValueMin, estimatedHomeValueMax };
      delete data.homePrice;
      if (data?.propertyType?.length === 4) delete data.propertyType;
      if (data?.weeklyBudget == "") delete data.weeklyBudget;

      setCampaignBid(data)
        .unwrap()
        .then((payload) => {
          navigate("/signup/payment/bid", {
            state: { fromPreviousStage: true, bidAmount: values?.bidAmount },
          });
        })
        .catch((error) => {
          notification.error({
            message: "There was a problem setting your bid.",
          });
        });
    });
  };

  const handleSkip = () => {
    markSkip(BID_SIGNUP_STAGES.CAMPAIGN_BID.name);
    navigate("/");
  };

  return (
    <>
      <OnboardingLayout title={"Set Your Bid"} step={5}>
        <Content>
          <IntroductionsInfoModal
            visible={showModal}
            closeModal={() => setShowModal(false)}
          />

          <div className="mt-8 panel-header">
            <h2>Set Your Bid</h2>
            <Text className="text-base">
              Your bid is the amount you are willing to spend on a lead in this
              area. Leads go to the highest bidder in that specified zip code.
            </Text>
          </div>

          <div className="space-y-4">
            <div className="py-8 panel">
              <div className="max-w-xl mx-auto">
                <CampaignBidForm form={form} />
              </div>
            </div>

            <Alert
              icon={
                <div>
                  <Image
                    src="/images/Graphics/Firework.svg"
                    preview={false}
                    width={90}
                  />
                </div>
              }
              className="bg-white border-white w-100"
              message={
                <Title style={{ fontSize: 16 }}>
                  We Deliver High-Quality Introductions
                </Title>
              }
              description={
                <div>
                  <Text style={{ fontSize: 16 }}>
                    We pride ourselves in delivering high-quality home-seller
                    introductions.
                  </Text>
                  <Button
                    type="text"
                    className="flex items-center float-right p-1 my-1 mr-2 ant-btn-secondary"
                    style={{ color: "#3d3d3d" }}
                    onClick={() => setShowModal(true)}
                    size="large"
                  >
                    Learn about our process&nbsp;
                    <Image
                      src="/images/Icons/Circle-Icons/SVGs/Arrow-Right.svg"
                      preview={false}
                    />
                  </Button>
                </div>
              }
              showIcon
            />
            <ContinueButtons
              onSkip={handleSkip}
              onSubmit={handleSubmit}
              isLoading={isLoading}
            />
          </div>

          <ASMPopUp />
        </Content>
      </OnboardingLayout>

      <BackToZipcodes />
    </>
  );
};

interface CampaignBidFormProps {
  form: FormInstance;
}

const { Panel } = Collapse;

const CampaignBidForm = ({ form }: CampaignBidFormProps) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };

  const minimumBid = MINIMUM_BID_AMOUNT;
  const minimumBidStr = minimumBid.toFixed(2);
  const { homeSellerCampaign } = useV2Signup();
  const zipcodes = homeSellerCampaign?.zipCodes?.split(",");

  return (
    <Form
      key="a"
      {...formItemLayout}
      form={form}
      layout="horizontal"
      name="campaignBidForm"
      requiredMark={"optional"}
      className="bg-white"
      size="large"
      colon={false}
      initialValues={{ propertyType: PROPERTY_TYPES }}
    >
      <Row justify="space-between">
        <Title level={5} style={{ marginBottom: 0, marginTop: 8 }}>
          Zip Code{zipcodes && zipcodes?.length > 1 && "s"}
        </Title>
        <span>
          {zipcodes && (
            <ZipcodeTags zipcodes={zipcodes} zipcodesBeforeOverflow={2} />
          )}
          {/* <Link to="/signup/campaign">
            <EditButton />
          </Link> */}
        </span>
      </Row>
      <hr className="my-4" />
      <Form.Item
        key="a"
        name="bidAmount"
        label={
          <Col>
            <Title level={5}>Bid Amount</Title>
            <Text>${minimumBid} is the minimum bid for a campaign.</Text>
          </Col>
        }
        style={{ minHeight: 60 }}
        labelAlign="left"
        dependencies={["weeklyBudget"]}
        rules={[
          {
            required: true,
            message: "",
          },
          {
            validator: (_, value) =>
              value >= minimumBid
                ? /^\d{1,6}(\.\d{1,2})?$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please enter a valid dollar amount")
                    )
                : Promise.reject(
                    new Error(`The minimum bid amount is $${minimumBidStr}`)
                  ),
          },
        ]}
      >
        <Input size="large" prefix="$" placeholder={minimumBidStr} />
      </Form.Item>
      <Form.Item
        key="b"
        name="weeklyBudget"
        className="remove-antd-optional-mark"
        tooltip="Leave blank if you don't want to set a limit on the amount you are eligible to win."
        label={
          <Col>
            <div className="flex">
              <Title level={5}>Weekly Budget</Title>
              <span className="ant-form-item-optional">(optional)</span>
            </div>
            <Paragraph>
              The maximum amount you want to spend per week.
            </Paragraph>
          </Col>
        }
        labelAlign="left"
        style={{ minHeight: 60 }}
        dependencies={["bidAmount"]}
        rules={[
          {
            validator: (_, value) => {
              if (value == undefined || value == "") return Promise.resolve();
              else {
                const bid = parseFloat(form.getFieldValue("bidAmount"));
                const budget = parseFloat(value);

                if (budget < minimumBid)
                  return Promise.reject(
                    new Error(`The minimum weekly budget is $${minimumBidStr}`)
                  );

                if (budget < bid) {
                  return Promise.reject(
                    new Error("Weekly budget must be greater than bid amount")
                  );
                }
                return /^\d{1,6}(\.\d{1,2})?$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please enter a valid dollar amount")
                    );
              }
            },
          },
        ]}
      >
        <Input size="large" prefix="$" placeholder={"0.00"} />
      </Form.Item>
      <hr />
      <Collapse ghost>
        <Panel header="Advanced Options" key="1" className="advanced-options">
          <Form.Item
            key="c"
            name="homePrice"
            label="Home Price"
            labelAlign="left"
            tooltip="Most of the homes we get fall between 100k and 700k"
            initialValue={[0, 11]}
            labelCol={{ xs: { span: 24 } }}
            wrapperCol={{ xs: { span: 24 } }}
            rules={[
              {
                validator: (_, value) =>
                  value[0] === value[1]
                    ? Promise.reject(new Error("Please expand your range"))
                    : Promise.resolve(),
              },
            ]}
          >
            <Slider
              marks={HOME_PRICE_MARKS}
              range
              min={0}
              max={11}
              tooltipVisible={false}
            />
          </Form.Item>
          <Form.Item
            name="propertyType"
            label="Property Type"
            labelAlign="left"
            rules={[
              {
                validator: (_, value) =>
                  value?.length
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("You must select at least one property type")
                      ),
              },
            ]}
          >
            <Checkbox.Group options={PROPERTY_TYPES} />
          </Form.Item>
        </Panel>
      </Collapse>
    </Form>
  );
};

export default SetCampaignBid;
