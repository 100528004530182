import React from "react";
import { Result, Spin } from "antd";
import { useGetContactQuery } from "@queries";
import { ContactDetails } from "./components/ContactDetails/ContactDetails";
import { useLocation } from "react-router-dom";
import { ContactDetail } from "@types";

export const ContactDetailsPage = () => {
  const { pathname } = useLocation();
  const contactId = parseInt(pathname.split("/").pop()!);

  const { data, isLoading, isFetching, error } = useGetContactQuery(contactId);

  if (error) {
    return (
      <Result
        title={
          <div>
            Sorry, there was a problem retrieving this contact.
            <br /> Please refresh the page.
          </div>
        }
        subTitle="If you need additional assistance, please reach out to your Success Manager."
      />
    );
  }

  return (
    <div className="max-w-4xl ml-0 md:ml-4">
      <Spin spinning={isLoading || isFetching}>
        <ContactDetails contact={data as ContactDetail} />
      </Spin>
    </div>
  );
};
