import React from "react";
import { Badge, Tag, TagProps } from "antd";
import { ContactStage, ContactStageName } from "@types";

interface ContactStageTagProps {
  /**
   * Status of contact stage - (New, Contacted, Deal made, Met with, Nurturing, Deal lost)
   */
  status: ContactStage;
  /**
   * Whether to show subtle version
   */
  subtle?: boolean;
}

export const statusToColor: { [key in ContactStage]: string } = {
  [ContactStage.New]: "#fcb116",
  [ContactStage.Contacted]: "#3688cb",
  [ContactStage.DealMade]: "#ED3C6F",
  [ContactStage.MetWith]: "#FF795C",
  [ContactStage.Nurturing]: "#66bb6a",
  [ContactStage.DealLost]: "#CAD0DC",
};

/**
 * Primary UI component for user interaction
 */
export const ContactStageTag: React.FC<ContactStageTagProps & TagProps> = ({
  status,
  subtle = false,
  ...props
}) => {
  const stageColor = statusToColor[status];

  if (subtle) {
    return (
      <Tag
        style={{
          color: stageColor,
          border: "none",
          background: "transparent",
          fontSize: 16,
          textAlign: "left",
          marginRight: 0,
        }}
        {...props}
      >
        <Badge color={stageColor} />
        {ContactStageName[status] ?? status}
      </Tag>
    );
  }

  return (
    <Tag
      className="w-full"
      style={{
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 32,
        backgroundColor: stageColor,
        fontSize: 16,
        color:
          stageColor === statusToColor[ContactStage.DealLost] ||
          stageColor === statusToColor[ContactStage.New] ||
          !stageColor
            ? ""
            : "whitesmoke",
        border: "none",
        textAlign: "center",
      }}
      {...props}
    >
      {ContactStageName[status] ?? status}
    </Tag>
  );
};
