import React, { FC } from "react";
import { Link } from "react-router-dom";
import {
  Image,
  Form,
  Alert,
  Input,
  Spin,
  Button,
  Layout,
  notification,
} from "antd";
import { AGENTS_CORNER_LOGO_PNG } from "@images";
import { useForgotPasswordMutation } from "@queries";

const { Content } = Layout;

export const ForgotPassword: FC = () => {
  const [form] = Form.useForm();
  const [submitForgotPassword, { isLoading, error }] =
    useForgotPasswordMutation();
  const handleSubmit = ({ email }: { email: string }) => {
    submitForgotPassword({ email })
      .unwrap()
      .then(() => {
        notification.success({ message: "Email sent!" });
      });
  };

  return (
    <Layout>
      <Content className="container flex items-center min-h-screen mx-auto">
        <Form
          form={form}
          onFinish={handleSubmit}
          className="max-w-sm mx-auto space-y-8"
          layout="vertical"
        >
          <div className="flex justify-center">
            <Image src={AGENTS_CORNER_LOGO_PNG} preview={false} />
          </div>
          <h2 className="text-center">Reset Your Password</h2>
          {error && (
            <Alert type="error" message="Unable to send email."></Alert>
          )}
          <Form.Item
            key="email"
            name="email"
            label="Email Address"
            rules={[
              { required: true, message: "Email is required." },
              { type: "email", message: "Not a valid email." },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <div>
            Enter the email associated with your Agent&apos;s Corner account and
            we&apos;ll send you instructions to reset your password.
          </div>
          <Spin spinning={isLoading}>
            <Button type="primary" htmlType="submit" size="large" block>
              Submit
            </Button>
          </Spin>
          <div className="text-center">
            <span>
              Go back to
              <Link className="text-primary" to="/login">
                &nbsp;&nbsp;Log in
              </Link>
            </span>
          </div>
        </Form>
      </Content>
    </Layout>
  );
};
