import { Layout } from "antd";
import React from "react";
import { Navbar } from "../Navbar/Navbar";
import { Outlet } from "react-router-dom";

const { Header } = Layout;

/**
 * Main app layout with navbar
 */
export const ACLayout: React.FC = () => {
  return (
    <Layout className="min-h-screen">
      <Header style={{ background: "white" }} className="shadow-sm">
        <Navbar />
      </Header>
      <Outlet />
    </Layout>
  );
};
