import React, { useMemo } from "react";
import { Row, Button, Spin } from "antd";
import { useAppSelector } from "../../store/hooks";
import { REQUEST } from "../../sagas/constants";

interface ContinueButtonsProps {
  onSkip: () => void;
  onSubmit: () => void;
  submitTitle?: string;
  isLoading?: boolean;
}

const ContinueButtons: React.FC<ContinueButtonsProps> = ({
  onSkip,
  onSubmit,
  submitTitle = "Continue",
  isLoading = false,
}) => {
  return (
    <Row justify="end" className="mr-1">
      <Button
        className="px-3 mt-1 mb-1"
        key="explore"
        type="text"
        size="large"
        onClick={onSkip}
      >
        Finish Later
      </Button>
      <Spin spinning={isLoading}>
        <Button
          className="px-3 mt-1 mb-1"
          type="primary"
          key="create"
          size="large"
          onClick={onSubmit}
        >
          {submitTitle}
        </Button>
      </Spin>
    </Row>
  );
};

export default ContinueButtons;
