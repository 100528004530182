import { ContactStage } from ".";
import { PropertyType } from "./Property";

export interface ContactDetail extends Contact {
  estatedRetrieved: boolean;
  estatedData: { v4Raw: { data: EstatedPropertyData } };
}

export type Contact = {
  id: number;
  created: string;
  isDemo: boolean;
  propertyType: PropertyType;
  verifiedPhoneNumber: "Yes" | "No";
  occupancy: string; // TODO: Add values for "Tenant Occupied" etc.
  ltv: string | null;
  qvm: number | null;
  reasonForSelling: ReasonForSelling;
  timeFrameToSell: TimeFrameToSell;
  behindOnMortgagePayments: "Yes" | "No";
  homeListed: "Yes" | "No";
  addressZip: string;
  googleMapsGeocodingRetrieved: any; // TODO
  googleMapsGeocodingData: any; // TODO
  tcpa: string;
  tcpaLanguage: string;
  firstName: string;
  lastName: string;
  propertyAddress: string;
  city: string;
  state: string;
  zipCode: string;
  primaryPhone: string;
  secondaryPhone: string;
  email: string;
  bestTimeToCall: string;
  leadStage: ContactStage;
  leadStatus: string; // TODO
  isArchived: boolean;
  phoneTwo: string;
  phoneThree: string;
  emailTwo: string;
  estimatedHomeValue: number | null;
  estimatedHomeValueAppend: number | null;
  askingPrice: number;
  bedrooms: number;
  bathrooms: number;
  sqft: number | null;
  yearBuilt: number | null; // ? Could be string?
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZip5: string;
  addressZip4: string;
  fullName?: string; // * NOTE: Potentially Unused
  cityState?: string; // * NOTE: Potentially Unused
  name?: string; // * NOTE: Potentially Unused
  created_at?: string; // * NOTE: Potentially Unused
  last_contacted?: string; // * NOTE: Potentially Unused
  credit_range?: string; // * NOTE: Potentially Unused
};

export enum ReasonForSelling {
  "Downsizing",
  //TODO: Add other reasons
}

export enum TimeFrameToSell {
  "ASAP",
  "3 Months",
  "6 Months",
  "No Rush",
}

export type EstatedPropertyData = {
  metadata: EstatedMetaData; // Metadata related to the request.
  address: EstatedAddressData; // Address details as provided by the assessor.
  parcel: EstatedParcelData; //	Parcel details as provided by the assessor.
  structure: EstatedStructureData; // Information about the building(s) on the parcel.
  taxes: EstatedTaxData[]; // Tax records as provided by the county.
  assessments: EstatedAssessmentData[]; // 	Tax assessment information as provided by the county assessor.
  marketAssessments: EstatedAssessmentData[]; // Market assessment data as determined by the assessor.
  valuation: EstatedValuationData; // Valuation details as provided by a proprietary valuation algorithm.
  owner: EstatedOwnerData; // Current owner details taken from the assessment.
  deeds: EstatedDeedData[]; // Up to 40 years of sale and mortgage data which constitute a deed record.
};

export type EstatedMetaData = {
  publishingDate?: string; // [YYYY-MM-DD]
};

export type EstatedAddressData = {
  streetNumber: string; //	Parsed street number. Eg. 3450
  streetPreDirection: string; //	Directional appearing before the street name. Eg. N
  streetName: string; //	Parsed street name. Numeric street names will include ordinal suffixes (st, nd, rd, th) Eg. MAIN
  streetSuffix: string; //	Standardized and parsed street suffix abbreviation. Eg. ST
  streetPostDirection?: string | null; //	Directional appearing after street suffix denoting quadrant. Eg. SW
  unitType?: string | null; //	Unit type abbreviation. Eg. APT
  unitNumber?: string | null; //	Unit number (may be alphanumeric). Eg. 101A
  formattedStreetAddress: string; //	Combined street address (including unit). Eg. 1650 N 16TH ST SW APT 101
  city: string; //	City name. Eg. CHICAGO
  state: string; //	State abbreviation. Eg. IL
  zipCode: string; //	Zip code. Eg. 60614
  zipPlusFourCode: string; //	Four digit postal zip extension. Eg. 5505
  carrierCode: string; //	USPS code for mail delivery services. Eg. R001
  latitude: number; //	Measured latitude for the property. Eg. 41.912406
  longitude: number; //	Measured longitude for the property. Eg. -87.649191
  geocodingAccuracy: string; //	Describes the level of geocoding match. Eg. PARCEL CENTROID
  censusTract: string; //	The census tract as designated by the Census Bureau. Eg. 10570200.002009
};

export type EstatedParcelData = {
  apnOriginal?: string | null; //	The formatted assessor's parcel number. Eg. 001-090-04-02
  apnUnformatted?: string | null; //	The unformatted assessor's parcel number. Eg. 0010900402
  apnPrevious?: string | null; //	A previous assessor's parcel number, formatted. Eg. 001-090-04-00
  fipsCode?: string | null; //	Unique County identifier, first 2 digits are state FIPS. Eg. 12086
  depthFt?: number | null; //	Depth measurement of the parcel in feet. Eg. 120.0
  frontageFt?: number | null; //	Frontage measurement of the parcel in feet. Eg. 260.0
  areaSqFt?: number | null; //	Total area of the parcel in square feet. Eg. 31200
  areaAcres?: number | null; //	Total area of the parcel in acres. Eg. 0.716
  countyName?: string | null; //	The name of the county. Eg. COOK
  countyLandUseCode?: string | null; //	The land use code as provided directly from the county, without interpretation. Eg. SFR01
  countyLandUseDescription?: string | null; //	The land use description as provided by directly from the county, without interpretation. Eg. RESIDENTIAL SINGLE DWELLING
  standardizedLandUseCategory?: string | null; //	The general land use category for the property, converted to a common set of values across all counties. Eg. MULTI-FAMILY RESIDENTIAL
  standardizedLandUseType?: string | null; //	Describes further granularity into the land use type, converted to a common set of values across all counties. Eg. TOWNHOUSE
  locationDescriptions?: string[]; //]	List describing the location and surrounding area. Eg. IN CUL-DE-SAC, HAS MOUNTAIN VIEW
  zoning?: string | null; //	City zoning designation, unique to each incorporated area. Eg. T/G
  buildingCount?: number | null; //	Count of all buildings on the property. Eg. 1
  taxAccountNumber?: string | null; //	Parcel identifier used by the tax assessor. Eg. 0134601209K
  legalDescription?: string | null; //	Legal description as provided by the assessor. Eg. LEGAL SUBDIVISION 4, SECTION 10, TOWNSHIP 82, WEST OF THE 6TH MERIDIAN, NEW YORK DISTRICT
  lotCode?: string | null; //	Indicates properties with multiple or partial lots. Eg. MORE THAN ONE LOT
  lotNumber?: string | null; //	Number(s) identifying individual lots making up a property, separated by a comma (,), or ampersand (&). Ranges are denoted by a hyphen (-). Eg. 1-13,15
  subdivision?: string | null; //	The name of the subdivision, plat, or tract in which the property is located Eg. CRYSTAL LAKE FOREST
  municipality?: string | null; //	The jurisdiction in which the property is located. Eg. FELL TOWNSHIP
  sectionTownshipRange?: string | null; //	Section township range meridian identifier. Eg. SEC 14 TWN 1N RNG 11E
};

export type EstatedStructureData = {
  yearBuilt?: number | null; //	The year the structure was built. Eg. 1923
  effectiveYearBuilt?: number | null; //	The year a structure was substantially updated or improved. Eg. 1996
  stories?: string | null; //	The number of stories comprising the structure (may include fractional stories and alphabetic codes). Eg. 2+A
  roomsCount?: number | null; //	The total number of rooms in the building (not just bedrooms). Eg. 4
  bedsCount?: number | null; //	The number of bedrooms in the building. Eg. 3
  baths?: number | null; //	Fractional number of bathrooms in the building, except when partial_baths_count is nonnull, see below. Eg. 2.5
  partialBathsCount?: number | null; //	The whole number of partial bathrooms. When this is nonnull, baths above will be a whole number indicating the number of full bathrooms. Eg. 2
  unitsCount?: number | null; //	Total number of units reported to the county. Eg. 1
  parkingType?: string | null; //	The type of parking available. Eg. DETACHED GARAGE
  parkingSpacesCount?: number | null; //	The total number of available parking spaces; including garage, carport, driveway. Eg. 2
  poolType?: string | null; //	Type of pool located on the property - shared or private. Eg. HEATED POOL
  architectureType?: string | null; //	Style or historical period of the primary structure. Eg. SPANISH
  constructionType?: string | null; //	Type of material used in construction of the building. Eg. WOOD
  exteriorWallType?: string | null; //	Material used for the exterior walls of the building. Eg. STUCCO
  foundationType?: string | null; //	The type of material used in the foundation. Eg. CONCRETE
  roofMaterialType?: string | null; //	The material used for the roof of the building. Eg. ASPHALT
  roofStyleType?: string | null; //	The architectural style for the roof of the building. Eg. GABLE
  heatingType?: string | null; //	Primary heating type for the building. Eg. BASEBOARD
  heatingFuelType?: string | null; //	Type of fuel used to heat the building. Eg. GAS
  airConditioningType?: string | null; //	Air conditioning type for the building. Eg. CENTRAL
  fireplaces?: string | null; //	Total number of fireplaces in the building (can also be "YES") Eg. 2
  basementType?: string | null; //	Basement type for the building. Eg. FULL BASEMENT
  quality?: string | null; //	The quality of the structure rated from A+ to E+. This grade is determined by the county and is based on numerous, non-standard factors. For example, a mobile home would likely have a lower score than a mansion as the mobile home uses cheaper, lower quality materials and has less features. Eg. A+
  condition?: string | null; //	Current condition of the structure provided by the county. This relates to things like whether or not there is visible wear on the structure (e.g. chipped paint, siding falling off). The method for determining this varies across counties. Eg. FAIR
  flooringTypes?: string[] | null; //	Type of flooring used in improvements in the building. Eg. CARPET, MARBLE
  plumbingFixturesCount?: number | null; //	Total number of all plumbing fixtures in the building. Eg. 1
  interiorWallType?: string | null; //	The type of material used for the interior walls. Eg. PLASTER
  waterType?: string | null; //	The water system for the property. Eg. MUNICIPAL
  sewerType?: string | null; //	The waste disposal/sewage system for the property. Eg. SEPTIC
  totalAreaSqFt?: number | null; //	Total livable square footage of the structure. Eg. 1692
  otherAreas?: {
    type: string;
    sqFt: string;
  }[];
  /*	List of objects describing areas within the building, and their corresponding size in sq ft. Size is a string holding an integer.
    {
        "type": "1ST FLOOR",
        "sq_ft": "1140"
    }
    */
  otherFeatures: {
    type: string;
    sqFt: string;
  }[];
  /*	List of objects describing features in or around the building, and their corresponding size in sq ft. Size is a string holding an integer, float, or rectangle (e.g. "24X16").
    {
        "type": "DRIVEWAY",
        "sq_ft": "240"
    }
    */
  otherImprovements: {
    type: string;
    sqFt: string;
  }[];
  /*	List of objects describing improvements to the property or building, and their corresponding size in sq ft. Size is a string holding an integer, float, or rectangle (e.g. "24X16").
    {
        "type": "GREENHOUSE",
        "sq_ft": "346"
    }
    */
  otherRooms: string[]; //	List of other rooms within the building. Eg. LAUNDRY ROOM, HOME OFFICE
  amenities: string[]; //	List of amenities included in the property. Eg. WINE CELLAR, TENNIS COURT
};

export interface EstatedTaxData {
  year?: number | null; // [YYYY]	The year the tax was levied. Eg. 2017
  amount?: number | null; //	The amount of tax on the property in dollars. Eg. 2247
  exemptions?: string[] | null; //	List of exemptions. Eg. AGRICULTURAL, LOW INCOME
  rateCodeArea?: string | null; //	Represents separate tax jurisdictions within the county as provided on the county tax/assessment roll. Eg. 01H
}

export interface EstatedAssessmentData {
  year?: number; // [YYYY]	The year the assessment was performed. Eg. 2018
  landValue?: number; //	The current assessed land value before any exemptions in dollars. Eg. 2580
  improvementValue?: number; //	The current assessed improvement value before any exemptions in dollars. Eg. 13300
  totalValue?: number; //	The total current assessed value of both land and improvements before any exemptions in dollars. Eg. 15880
}

export interface EstatedValuationData {
  value?: number | null; //	The current property value. Eg. 16430
  high?: number | null; //	The highest probable value. Eg. 17220
  low?: number | null; //	The lowest probable value. Eg. 15780
  forecastStandardDeviation?: number | null; // [percentage 0-100]	Forecast standard deviation. Eg. 55
  date?: string | null; // [YYYY-MM-DD]	The date the valuation was performed. Eg. 2019-10-24
}

export interface EstatedOwnerData {
  name?: string | null; //	Assessed owner names. Eg. "SMITH, JOHN; SMITH, JANE" or "SMITH JOHN & JANE"
  secondName?: string | null; //	As of March 1, 2020, this field has been deprecated and will return null. Any additional owner names have been merged with the primary owner name. Eg. NULL
  formattedStreetAddress?: string | null; //	The address where the current tax bill is mailed (not including unit). Eg. 123 MAIN ST
  unitType?: string | null; //	The unit type. Eg. APT
  unitNumber?: string | null; //	The unit number. Eg. 104
  city?: string | null; //	The city where the current tax bill is mailed. In the case it is out of USA, the county will also be located here. Eg. ATLANTA
  state?: string | null; //	The state abbreviation where the current tax bill is mailed; XX for out of country addresses. Eg. GA
  zipCode?: string | null; //	The zip code where the current tax bill is mailed. Eg. 30342
  zipPlusFourCode?: string | null; //	Four digit postal zip extension for where the tax bill is mailed. Eg. 3019
  ownerOccupied?: string | null; //	Description of the owner occupancy. Can be "YES" or "PROBABLE". Data not available if null. Eg. YES
}

export type EstatedDeedData = {
  documentType?: string | null; //	Type of deed document. Eg. WARRANTY DEED
  recordingDate?: string | null; // [YYYY-MM-DD]	The official date the document was recorded. Eg. 2011-12-28
  originalContractDate?: string | null; // [YYYY-MM-DD]	The date the original contract was signed by the relevant parties. In some scenarios this may be the date of notarization. Eg. 2011-12-26
  deedBook?: string | null; //	The physical book where the deed was recorded. Eg. 9056
  deedPage?: string | null; //	The physical page where the deed was recorded. Eg. 595
  documentId?: string | null; //	Identifier assigned to document at the recording date. Eg. 93154L
  salePrice?: number | null; //	The total sale price in dollars. Eg. 170000
  salePriceDescription?: string | null; //	A description of the sale. Eg. ESTIMATED
  transferTax?: number | null; //	The tax amount levied by the city, county, or a combination thereof. Eg. 142.03
  distressedSale?: boolean | null; //	An indicator to determine if the sale was deemed to be distressed. Eg. false
  realEstateOwned?: string | null; //	An indicator used to determine the status of the transfer. Eg. NO
  sellerFirstName?: string | null; //	Seller first name, or null if corporate owner. Eg. JOHN
  sellerLastName?: string | null; //	Seller last name, or corporate name. Eg. SMITH
  seller2FirstName?: string | null; //	The second seller first name, or null if corporate owner. Eg. JANE
  seller2LastName?: string | null; //	The second seller last name, or corporate name. Eg. SMITH
  sellerAddress?: string | null; //	The seller mailing address. Eg. 123 MAIN ST
  sellerUnitNumber?: string | null; //	The seller unit number. Eg. 1
  sellerCity?: string | null; //	The seller city. Eg. PHOENIX
  sellerState?: string | null; //	The seller mailing state. Eg. AZ
  sellerZipCode?: string | null; //	Seller zip code. Eg. 85024
  sellerZipPlusFourCode?: string | null; //	Seller four digit postal zip extension. Eg. 3019
  buyerFirstName?: string | null; //	Buyer first name, or null if corporate owner. Eg. JOHN
  buyerLastName?: string | null; //	Buyer last name, or corporate name. Eg. SMITH
  buyer2FirstName?: string | null; //	Second buyer first name, or null if corporate owner. Eg. JANE
  buyer2LastName?: string | null; //	Second buyer last name, or corporate name. Eg. SMITH
  buyerAddress?: string | null; //	Buyer mailing address. Eg. 123 MAIN ST
  buyerUnitType?: string | null; //	Buyer unit type. Eg. APT
  buyerUnitNumber?: string | null; //	Buyer unit number. Eg. 1A
  buyerCity?: string | null; //	Buyer mailing city. Eg. DENVER
  buyerState?: string | null; //	Buyer mailing state. Eg. CO
  buyerZipCode?: string | null; //	Buyer mailing zip code. Eg. 80222
  buyerZipPlusFourCode?: string | null; //	Buyer four digit postal zip extension. Eg. 3019
  lenderName?: string | null; //	Mortgage lender. Eg. CITIMORTGAGE INC
  lenderType?: string | null; //	The type of lender. Eg. BANK
  loanAmount?: number | null; //	Mortgage recorded in dollars. Eg. 74900
  loanType?: string | null; //	Type of loan security. Eg. CLOSED MORTGAGE
  loanDueDate?: string | null; // [YYYY-MM-DD]	The date the mortgage will be paid in full. Eg. 2029-10-22
  loanFinanceType?: string | null; //	The interest rate type on the loan. Eg. FIXED RATE
  loanInterestRate?: number | null; //	The interest rate of the loan. Eg. 2.97
};
