import React, { FC, useEffect, useState, useCallback } from "react";
import { Layout, Typography, Space, Image, Alert, notification } from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";

import { BID_SIGNUP_STAGES, MINIMUM_NUM_CAMPAIGN_ZIPS } from "@constants";

import { useNavigate, useLocation } from "react-router-dom";
import ContinueButtons from "../../../component/ContinueButtons";
import { ASMPopUp } from "@containers";

import { useZipcodesEditor } from "@utils/campaign";
import {
  CityZipcodesList,
  SearchLocationInput,
  ZipcodeSelect,
  ErrorAlert,
} from "@components";
import {
  useLazyGetZipCodesQuery,
  useMarkSkipMutation,
  useSetCampaignLocationMutation,
  useV2Signup,
} from "@queries";
import { CampaignTypePath, SignupStage } from "@types";

const { Text, Title } = Typography;

const CreateCampaign: FC = () => {
  const { Content } = Layout;
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    monthlyBudgetHomeSellerCampaign,
    campaignTypePath,
    homeSellerCampaign,
  } = useV2Signup();
  const isSubscriptionAgent =
    campaignTypePath === CampaignTypePath.MonthlyBudget;

  const {
    selectedZipCodeGroups,
    selectedZipCodesList,
    placeQuery: { query },
    currentQueryZipcodes,
    currentQuerySelectedZipcodes,
    setQueryByPlace,
    setPlaceQuery,
    manuallyAddZipcode,
    toggleZipcode,
    deleteZipcode,
    selectAll,
    unselectAll,
    clearCurrentQuery,
    removeSelectedPlace,
  } = useZipcodesEditor(
    isSubscriptionAgent ? monthlyBudgetHomeSellerCampaign : homeSellerCampaign
  );

  const [setCampaignLocation, { data: locationResponse, isLoading, error }] =
    useSetCampaignLocationMutation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const nonFieldErrorMsg = error?.data?.nonFieldErrors?.[0];

  const [markSkip] = useMarkSkipMutation();
  const [minNumZipsError, setMinNumZipsError] = useState(false);

  useEffect(() => {
    if (selectedZipCodesList.length >= MINIMUM_NUM_CAMPAIGN_ZIPS)
      setMinNumZipsError(false);
  }, [selectedZipCodesList]);

  const [getZipCodes] = useLazyGetZipCodesQuery();
  const fetchZipCodes = ({ city, state }: { city: string; state: string }) => {
    return getZipCodes({ city, state })
      .unwrap()
      .then((payload) => payload);
  };

  const handleSubmit = () => {
    if (selectedZipCodesList.length < MINIMUM_NUM_CAMPAIGN_ZIPS) {
      setMinNumZipsError(true);
      return;
    }

    setMinNumZipsError(false);

    setCampaignLocation({
      zipCodes: selectedZipCodesList.join(","),
      cityStateToZipCodes: JSON.stringify(selectedZipCodeGroups),
    })
      .unwrap()
      .then((payload) => {
        if (
          payload?.campaignTypePath === CampaignTypePath.MonthlyBudget ||
          payload?.stage === SignupStage.SetCampaignBudget
        ) {
          navigate("/signup/campaign/subscription", {
            state: { fromPreviousStage: true },
          });
        } else if (
          payload?.campaignTypePath === CampaignTypePath.Bidding ||
          payload?.stage === SignupStage.SetCampaignBid
        ) {
          if ((state as { fromActivate: boolean })?.fromActivate) {
            navigate("/signup/activate");
            return;
          } else {
            navigate("/signup/campaign/bid", {
              state: { fromPreviousStage: true },
            });
          }
        }
      })
      .catch((error) => {
        notification.error({
          placement: "topRight",
          message:
            "There was an error setting the campaign location, try adjusting your zipcodes",
        });
      });
  };

  const handleSkip = () => {
    markSkip(BID_SIGNUP_STAGES.CAMPAIGN_LOCATION.name);
    navigate("/");
  };

  return (
    <OnboardingLayout step={4}>
      <Content className="mt-8 space-y-4">
        <div>
          <div className="panel-header">
            <h2>Region</h2>
            <div className="">Add at least five zip codes</div>
          </div>
          <div className="panel-w-header">
            <div className="max-w-2xl p-4 mx-auto">
              <div className="space-y-2">
                <SearchLocationInput
                  onChange={setPlaceQuery}
                  onAddZipCode={manuallyAddZipcode}
                  fetchZipCodes={fetchZipCodes}
                />
                <ZipcodeSelect
                  query={query}
                  zipcodeOptions={currentQueryZipcodes}
                  selectedZipcodes={currentQuerySelectedZipcodes}
                  toggleZipcode={toggleZipcode}
                  selectAll={selectAll}
                  unselectAll={unselectAll}
                  clearCurrentQuery={clearCurrentQuery}
                />
                {error && nonFieldErrorMsg && (
                  <ErrorAlert message={nonFieldErrorMsg} />
                )}
                {minNumZipsError && (
                  <ErrorAlert
                    message={`Please select at least ${MINIMUM_NUM_CAMPAIGN_ZIPS} zip codes`}
                    afterClose={() => setMinNumZipsError(false)}
                  />
                )}
                <CityZipcodesList
                  cityStateToZipCodes={selectedZipCodeGroups}
                  onDelete={deleteZipcode}
                  onHeaderClick={setQueryByPlace}
                  onPlaceDelete={removeSelectedPlace}
                />
              </div>
            </div>
          </div>
        </div>
        <Alert
          icon={
            <div>
              <Image
                src="/images/svgs/Light-Bulb.svg"
                preview={false}
                width={90}
              />
            </div>
          }
          className="bg-white border-white align-items-center"
          message={
            <Title style={{ fontSize: 16 }}>
              For best results, broaden your reach.
            </Title>
          }
          description={
            <Text style={{ fontSize: 16 }}>
              We recommend using as many zip codes as possible to increase your
              chances of being matched with an introduction.
            </Text>
          }
          closable
          showIcon
        />
        <ContinueButtons
          onSkip={handleSkip}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
        <ASMPopUp defaultOpen={true} />
      </Content>
    </OnboardingLayout>
  );
};

export default CreateCampaign;
