export const HOME_SELLER = "/images/Graphics/Home-Seller.svg";
export const HOME_BLOG_THUMBNAIL = "/images/Photos/ex-2.jpg";
export const AUTO_DEPOSIT = "/images/svgs/auto-deposit.svg";
export const CAMPAIGN_LIVE = "/images/Graphics/Campaign-Live.svg";
export const AGENTS_CORNER_LOGO_PNG = "/images/agents-corner-logo.png";
export const COPY_ICON = "/images/Icons/SVGs/Copy.svg";
export const ACCOUNT_AVATAR = "/images/Icons/Account.png";

interface CardDictionary {
  [index: string]: string;
}

export const CREDIT_CARD_ICONS: CardDictionary = {
  VISA: "/images/svgs/visa.svg",
  "AMERICAN EXPRESS": "/images/svgs/american-express.svg",
  DISCOVER: "/images/svgs/discover.svg",
  MASTERCARD: "/images/svgs/master-card.svg",
  PAYPAL: "/images/svgs/paypal.svg",
  UNKNOWN: "/images/svgs/unknown-card.svg",
};

export const AGENTS_CORNER_LOGO = "/images/agents-corner-logo@3x.png";
