import React, { FC } from "react";
import { Layout, Space, Image, Spin } from "antd";
import { AGENTS_CORNER_LOGO_PNG } from "@images";

const { Content } = Layout;

export const LoadingScreen: FC = () => {
  return (
    <Layout className="layout">
      <Content className="flex items-center">
        <Space key="a" direction="vertical" size="large" align="center" wrap>
          <Image src={AGENTS_CORNER_LOGO_PNG} preview={false} />
          <Spin spinning />
        </Space>
      </Content>
    </Layout>
  );
};
