import React from "react";
import { Table, Alert } from "antd";
import { CreditCard } from "@types";
import { useBalanceHistoryQuery } from "@queries";

export const BillingHistory = () => {
  const { data, isLoading, error } = useBalanceHistoryQuery();
  const columns = [
    {
      title: "Date Billed",
      dataIndex: "timestamp",
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: "Payment Method",
      dataIndex: "creditCard",
      render: (card: CreditCard) =>
        card ? `${card?.cardData?.brand} ${card?.cardData?.last4}` : "-",
    },
    {
      title: "Amount Billed",
      dataIndex: "changeAmount",
      render: (amount: string) => {
        if (amount[0] === "-") return `-$${amount.slice(1)}`;
        return `+$${amount}`;
      },
    },
    {
      title: "Balance",
      dataIndex: "newBalance",
      render: (balance: string) => `$${balance}`,
    },
  ];

  return (
    <div>
      <h1 className="mb-4">Billing History</h1>
      {error && (
        <Alert
          type="error"
          message="There was a problem retrieving your billing history. Try refreshing the page."
          showIcon
          className="mb-4"
        />
      )}
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        rowKey={(record) => record.timestamp}
      />
    </div>
  );
};
