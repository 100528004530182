import React from "react";
import { useLocation } from "react-router-dom";

export const CampaignEditPage = () => {
  const { pathname, state } = useLocation();
  return (
    <div>
      {pathname}
      <br /> {state}
    </div>
  );
};
