import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { ACLayout } from "../stories/ACLayout/ACLayout";
import SignupRouter from "../v2/routes/routes";
import { RequireAuth } from "./RequireAuth";

import {
  CampaignsPage,
  CampaignDetailsPage,
  CampaignEditPage,
  SubscriptionCampaignPage,
} from "@pages/campaigns";
import { ForgotPasswordPage, LoginPage, LogoutPage } from "@pages/auth";
import {
  ContactsHomePage,
  ContactsPage,
  ContactDetailsPage,
} from "@pages/contacts";
import { DashboardPage } from "@pages/dashboard";
import { SettingsPage } from "@pages/settings";
import { TermsOfUse, PrivacyPolicy } from "@pages/legal";
import { Signup, VerificationSuccess } from "src/v2/page/onboarding";
import { DepositPage } from "@pages/deposit";
import { RefundRequest } from "@pages/refund-request";
import { useSelector } from "react-redux";
import { getCurrentToken } from "@store/authSlice";
import { RootState } from "@store/store";
import { LoginAsAgent } from "@pages/admin";

export const AppRouter: React.FC = () => {
  const token = useSelector((state: RootState) => getCurrentToken(state));
  const isLoggedIn = token;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="signup"
          element={
            isLoggedIn ? (
              <Navigate to="/signup/redirect-to-stage" />
            ) : (
              <Signup />
            )
          }
        />
        <Route path="verification-success" element={<VerificationSuccess />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="signup/*" element={<SignupRouter />} />
        <Route
          path="login"
          element={isLoggedIn ? <Navigate to="/" /> : <LoginPage />}
        />
        <Route path="logout" element={<LogoutPage />} />
        <Route
          // * The routes in this tree are all protected since we are wrapping
          // * our layout with a component that checks for auth <RequireAuth>
          path="/"
          element={
            <RequireAuth>
              <ACLayout />
            </RequireAuth>
          }
        >
          <Route index element={<DashboardPage />} />
          <Route path="contacts" element={<ContactsPage />}>
            <Route index element={<ContactsHomePage />} />
            <Route path=":contactId" element={<ContactDetailsPage />} />
          </Route>
          <Route path="campaigns">
            <Route index element={<CampaignsPage />} />
            <Route path=":campaignId" element={<CampaignDetailsPage />}>
              <Route path="edit" element={<CampaignEditPage />} />
            </Route>
          </Route>
          <Route path="deposit" element={<DepositPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route
            path="subscription-campaign"
            element={<SubscriptionCampaignPage />}
          />
          <Route path="request-refund" element={<RefundRequest />} />
        </Route>
        <Route path="asm-login" element={<LoginAsAgent />} />
        <Route path="*" element={<h1>NOT FOUND</h1>} />
      </Routes>
    </BrowserRouter>
  );
};
