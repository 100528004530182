import React from "react";
import { Drawer, Tabs } from "antd";
import { AllActivity } from "./AllActivity";
import { SelectedContactActivity } from "./SelectedContactActivity";
import { ContactStage } from "@types";
import { useScreenSize } from "@utils/hooks";
import "./ActivityDrawer.less";

const { TabPane } = Tabs;

interface ActivityDrawerProps {
  contactId: number;
  currentContactStage: ContactStage;
  visible: boolean;
  onClose: () => void;
}

export const ActivityDrawer: React.FC<ActivityDrawerProps> = ({
  contactId,
  currentContactStage,
  visible,
  onClose,
}) => {
  const { isSmallScreen } = useScreenSize();
  const responsiveWidth = isSmallScreen ? "100vw" : "50vh";

  return (
    <Drawer
      className="remove-body-padding"
      title={<div className="text-xl font-bold">Activity Log</div>}
      visible={visible}
      mask={false}
      width={responsiveWidth}
      onClose={onClose}
    >
      <Tabs defaultActiveKey="contact">
        <TabPane
          key="contact"
          tab={<div className="text-black">This contact</div>}
        >
          <SelectedContactActivity
            contactId={contactId}
            currentContactStage={currentContactStage}
          />
        </TabPane>
        <TabPane
          key="allActivity"
          tab={<div className="text-black">View all activity</div>}
        >
          <AllActivity />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
