import React from "react";
import { notification, Select } from "antd";
import { ContactStage } from "@types";
import {
  ContactStageTag,
  statusToColor,
} from "@components/ContactStageTag/ContactStageTag";
import { useUpdateContactStageMutation } from "@queries";
import "./ContactStageSelect.less";

const { Option } = Select;

interface ContactStageSelect {
  contactId: number;
  contactStage: ContactStage;
  subtle?: boolean;
}

export const ContactStageSelect: React.FC<ContactStageSelect> = ({
  contactId,
  contactStage,
  subtle = true,
}) => {
  const [updateContactStage, { isLoading, error }] =
    useUpdateContactStageMutation();

  if (error) {
    notification.error({
      message: "We weren't able to update your contact stage",
    });
  }

  const handleStageSelect = (value: any) => {
    updateContactStage({ contactId, contactStage: value });
  };

  return (
    <Select
      onChange={handleStageSelect}
      className={subtle ? "rounded-xl" : "custom-select rounded w-full"}
      style={subtle ? {} : { backgroundColor: statusToColor[contactStage] }}
      value={contactStage}
      loading={isLoading}
      bordered={subtle}
    >
      <Option value={ContactStage.New}>
        <ContactStageTag subtle={subtle} status={ContactStage.New} />
      </Option>
      <Option value={ContactStage.Contacted}>
        <ContactStageTag subtle={subtle} status={ContactStage.Contacted} />
      </Option>
      <Option value={ContactStage.Nurturing}>
        <ContactStageTag subtle={subtle} status={ContactStage.Nurturing} />
      </Option>
      <Option value={ContactStage.MetWith}>
        <ContactStageTag subtle={subtle} status={ContactStage.MetWith} />
      </Option>
      <Option value={ContactStage.DealMade}>
        <ContactStageTag subtle={subtle} status={ContactStage.DealMade} />
      </Option>
      <Option value={ContactStage.DealLost}>
        <ContactStageTag subtle={subtle} status={ContactStage.DealLost} />
      </Option>
    </Select>
  );
};
