import React, { useMemo } from "react";
import { Typography, Avatar } from "antd";
import { SuccessManager } from "@types";
import { formatPhonenumber } from "@utils/formatters";
import "./ASMWidget.less";
import { CopyableButton, MaterialIcon } from "..";

const { Text, Paragraph } = Typography;

export const ASMWidget: React.FC<SuccessManager> = ({
  imageLow,
  name,
  phone,
  calendlyLink,
  email,
}) => {
  const formattedPhonenumber = useMemo(() => formatPhonenumber(phone), [phone]);
  const firstName = useMemo(() => name.split(" ")[0], [phone]);

  if (!name || !phone) return null;
  return (
    <div className="flex flex-col text-center panel">
      <Text className="text-xl font-semibold text-left">
        Your Success Manager
      </Text>
      <Avatar
        src={imageLow}
        size={90}
        alt="success manager photo"
        className="mx-auto mt-6 mb-2"
      />
      <Text className="text-xl font-bold">{name}</Text>
      <div>
        <CopyableButton copyText={phone}>
          <MaterialIcon icon="phone" className="mr-1 align-middle text-blue" />
          <Text strong>{formattedPhonenumber}</Text>
        </CopyableButton>
        <CopyableButton copyText={email} className="mb-4">
          <MaterialIcon icon="mail" className="mr-1 align-middle text-blue" />
          <Text strong>{email}</Text>
        </CopyableButton>
        <div className="mb-8">
          <Paragraph className="text-lg">
            Reach out to {firstName} to optimize your campaigns.
          </Paragraph>
        </div>
        <a href={calendlyLink} target="_blank" rel="noreferrer">
          <div className="hover:bg-gray-50">
            <MaterialIcon
              icon="event_available"
              className="mr-1 align-middle text-blue"
            />
            <Text strong>or, schedule a call</Text>
          </div>
        </a>
      </div>
    </div>
  );
};
