import React, { FC, useState, useRef, useEffect } from "react";
import "./IntroductionsInfoModal.less";
import { Button, Carousel, Image, Modal, Row, Space, Typography } from "antd";
import { CarouselRef } from "antd/lib/carousel";

const { Title, Paragraph } = Typography;

const [firstSlide, lastSlide] = [0, 3];

type IntroductionInfoModalProps = {
  visible: boolean;
  closeModal: () => void;
};

const IntroductionsInfoModal: FC<IntroductionInfoModalProps> = ({
  visible,
  closeModal,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const carouselRef = useRef<CarouselRef>(null);

  const handleNext = () => {
    if (slideIndex == lastSlide) {
      closeModal();
      setSlideIndex(0);
    } else setSlideIndex(slideIndex + 1);
  };

  const handleBack = () => {
    if (slideIndex == firstSlide) closeModal();
    else setSlideIndex(slideIndex - 1);
  };

  useEffect(() => {
    carouselRef.current?.goTo(slideIndex);
  }, [carouselRef, slideIndex]);

  return (
    <Modal
      visible={visible}
      centered
      keyboard
      width={850}
      onCancel={closeModal}
      closable={true}
      footer={null}
    >
      <Carousel dots ref={carouselRef} className="mx-auto max-w-3xl">
        <Space direction="vertical" className="py-2">
          <Title className="text-center" level={4}>
            Highly Motivated
          </Title>
          <Paragraph style={{ fontSize: 18 }}>
            We have perfected the algorthm of determing home sellers with the
            highest motivation to sell, and give you only the best.
          </Paragraph>
          <div className="flex justify-center">
            <Image
              src="/images/Graphics/Highest-Motivation.svg"
              preview={false}
            />
          </div>
        </Space>
        <Space direction="vertical" className="py-2">
          <Title className="text-center" level={4}>
            100% Home Sellers
          </Title>
          <Paragraph style={{ fontSize: 18 }}>
            Home sellers do not need to be qualified or have a certain credit
            score, unlike home buyers. This means they can enter a deal
            immediately
          </Paragraph>
          <div className="flex justify-center">
            <Image src="/images/Graphics/Home-Seller.svg" preview={false} />
          </div>
        </Space>
        <Space direction="vertical" className="py-2">
          <Title className="text-center" level={4}>
            Phone Verified
          </Title>
          <Paragraph style={{ fontSize: 18 }}>
            Our team verifies each phone number to ensure it is an active,
            working phone.
          </Paragraph>
          <div className="flex justify-center">
            <Image src="/images/Graphics/Phone-Verified.svg" preview={false} />
          </div>
        </Space>
        <Space direction="vertical" className="py-2">
          <Title className="text-center" level={4}>
            100% Exclusive
          </Title>
          <Paragraph style={{ fontSize: 18 }}>
            With our sophisticated software, we make sure none of our home
            sellers are already on the market or have gone to another company.
          </Paragraph>
          <Paragraph style={{ fontSize: 18 }}>
            Our leads go to you and only you.
          </Paragraph>
          <div className="flex justify-center">
            <Image
              src="/images/Graphics/Exclusive-Seller.svg"
              preview={false}
            />
          </div>
        </Space>
      </Carousel>
      <Row justify="end" className="pt-1">
        <Button size="large" onClick={handleBack} className="mr-1">
          Back
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={handleNext}
          className="mr-1"
        >
          {slideIndex === lastSlide ? "Done" : "Next"}
        </Button>
      </Row>
    </Modal>
  );
};

export default IntroductionsInfoModal;
