import React, { FC, useCallback } from "react";
import { Row, Statistic, Form, Button, Input, Alert, notification } from "antd";
import { useGetAgentQuery, useSetPasswordMutation } from "@queries";
import { formatPhonenumber } from "@utils/formatters";
import { extractListObjectError } from "@queries/helpers";

export const Account: FC = () => {
  const { email, phonenumber, firstName, lastName } = useGetAgentQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        email: data?.user?.email,
        phonenumber: data?.user?.phonenumber,
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
      }),
    }
  );

  const [updatePassword, { isLoading, error: passwordError }] =
    useSetPasswordMutation();
  const error =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    passwordError && extractListObjectError(passwordError?.data);

  const [form] = Form.useForm();

  const handleUpdatePassword = useCallback(
    async (values) => {
      try {
        await updatePassword(values).unwrap();
        notification.success({
          placement: "bottomLeft",
          type: "success",
          message: "Password updated",
        });
        form.resetFields();
      } catch (err) {
        notification.error({
          placement: "bottomLeft",
          type: "error",
          message: "Unable to update password",
        });
      }
    },
    [updatePassword]
  );

  return (
    <div className="max-w-4xl p-4">
      <h1 className="mb-4">Account Info</h1>
      <div className="space-y-8">
        <div className="space-y-4 panel">
          <h2>
            <span className="mr-2 align-middle material-icons">person</span>
            User
          </h2>
          <Row>
            <Statistic
              valueStyle={{ fontSize: 16 }}
              title="Name"
              value={`${firstName} ${lastName}`}
            />
          </Row>
          <Row justify="space-between">
            <Statistic
              title="Email"
              value={email ?? ""}
              valueStyle={{ fontSize: 16 }}
            />
            <Statistic
              title="Phone"
              value={phonenumber ? formatPhonenumber(phonenumber) : ""}
              valueStyle={{ fontSize: 16 }}
            />
          </Row>
        </div>

        <div className="panel">
          <h2>
            <span className="mr-2 align-middle material-icons">lock</span>Login
          </h2>
          {error && (
            <Alert type="error" message={error} showIcon className="my-4" />
          )}
          <Form
            name="updatePassword"
            layout="vertical"
            form={form}
            className="max-w-sm"
            onFinish={handleUpdatePassword}
          >
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your current password",
                },
                { min: 8, message: "Password must be at least 8 characters" },
              ]}
            >
              <Input.Password placeholder="Old password" />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input a new password",
                },
                { min: 8, message: "Password must be at least 8 characters" },
              ]}
            >
              <Input.Password placeholder="Something secret..." />
            </Form.Item>
            <Button htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
