import { SignupStage, SignupStageMeta } from "@types";
import { string } from "prop-types";
import * as IMAGES from "./image";

const FAQ_URL = process.env.REACT_APP_HELPSCOUT_URL;

export const MINIMUM_BID_AMOUNT = process.env.REACT_APP_MINIMUM_BID_AMOUNT
  ? parseInt(process.env.REACT_APP_MINIMUM_BID_AMOUNT, 10)
  : 26;

export const MINIMUM_NUM_CAMPAIGN_ZIPS =
  process.env.REACT_APP_MINIMUM_NUM_CAMPAIGN_ZIPS || 5;

export const AGENTS_CORNER_PHONENUMBER =
  process.env.REACT_APP_AGENTS_CORNER_PHONENUMBER || "2142398114";

export const MINIMUM_INTRODUCTIONS = 10;
export const EST_HOME_VALUE_MIN_NOT_SET = 0;
export const EST_HOME_VALUE_MAX_NOT_SET = 100000000;

export const AUTO_DEPOSIT_HELP_URL =
  "https://agents-corner.helpscoutdocs.com/article/9-account-balance-auto-recharge-feature";

export const RECOMMENDED_ARTICLE_URL =
  process.env.REACT_APP_RECOMMENDED_ARTICLE_URL ||
  "https://agentscorner.com/homeowners-seeking-cash-offer/";

export const HOME_PRICE_MARKS: {
  [key: number]: { label: string; value: number | undefined };
} = {
  0: { label: "$0", value: EST_HOME_VALUE_MIN_NOT_SET },
  1: { label: "$100k", value: 100000 },
  2: { label: "$200k", value: 200000 },
  3: { label: "$300k", value: 300000 },
  4: { label: "$400k", value: 400000 },
  5: { label: "$500k", value: 500000 },
  6: { label: "$600k", value: 600000 },
  7: { label: "$700k", value: 700000 },
  8: { label: "$800k", value: 800000 },
  9: { label: "$900k", value: 900000 },
  10: { label: "$1M", value: 1000000 },
  11: { label: "$1.2M+", value: EST_HOME_VALUE_MAX_NOT_SET },
};

export const SIMPLE_HOME_PRICE_MARKS: {
  [key: number]: { label: string; value: number | undefined };
} = {
  0: { label: "None", value: EST_HOME_VALUE_MIN_NOT_SET },
  1: { label: "200k", value: 200000 },
  2: { label: "400k", value: 400000 },
  3: { label: "600k", value: 600000 },
  4: { label: "800k", value: 800000 },
  5: { label: "1M+", value: EST_HOME_VALUE_MAX_NOT_SET },
};

export const PROPERTY_TYPES = [
  "Single Family",
  "Multi Family",
  "Condo/Town Home",
  "Mobile Home",
];

export const NUM_SIGNUP_STAGES = 7;

export const VERIFICATION_SIGNUP_STAGES: {
  VERIFY_PHONE: SignupStageMeta;
  VERIFY_EMAIL: SignupStageMeta;
  VERIFIED: SignupStageMeta;
} = {
  VERIFY_PHONE: {
    name: SignupStage.VerifyPhone,
    page: 0,
    path: "/signup/verify-phonenumber",
    step: 2,
  },
  VERIFY_EMAIL: {
    name: SignupStage.VerifyEmail,
    page: 1,
    path: "/signup/verify-email",
    step: 3,
  },
  VERIFIED: {
    name: "Verified" as SignupStage, // Typecasting for matchstage function helper to still do route permissions
    page: 2,
    path: "/signup/verified",
    step: 4,
  },
};

export const CAMPAIGN_LOCATION_STAGE: { CAMPAIGN_LOCATION: SignupStageMeta } = {
  CAMPAIGN_LOCATION: {
    name: SignupStage.ChooseCampaignLocation,
    page: 2,
    path: "/signup/campaign",
    step: 4,
  },
};

const BID_SIGNUP_STAGES = {
  ...VERIFICATION_SIGNUP_STAGES,
  ...CAMPAIGN_LOCATION_STAGE,
  CAMPAIGN_BID: {
    name: SignupStage.SetCampaignBid,
    page: 3,
    path: "/signup/campaign/bid",
    step: 5,
  },
  ADD_PAYMENT: {
    name: SignupStage.AddPaymentInfo,
    page: 4,
    path: "/signup/payment/bid",
    step: 6,
  },
  ACTIVATE_CAMPAIGN: {
    name: SignupStage.ActivateCampaign,
    page: 5,
    path: "/signup/activate",
    step: 7,
  },
  COMPLETE: {
    name: SignupStage.Completed,
    page: 6,
    path: "/signup/complete",
    step: 9,
  },
};

export const SUBSCRIPTION_SIGNUP_STAGES = {
  ...VERIFICATION_SIGNUP_STAGES,
  ...CAMPAIGN_LOCATION_STAGE,
  CAMPAIGN_SUBSCRIPTION: {
    name: SignupStage.SetCampaignBudget,
    page: 3,
    path: "/signup/campaign/subscription",
    step: 5,
  },
  ADD_PAYMENT: {
    name: SignupStage.AddPaymentInfo,
    page: 4,
    path: "/signup/payment/subscription",
    step: 6,
  },
  COMPLETE: {
    name: SignupStage.Completed,
    page: 5,
    path: "/signup/complete",
    step: 9,
  },
};

const POST_ACTIVITY = {
  status: "Status Changed",
  notification: "NOTIFICATIONS",
  stage: "Stage Changed",
  call: "CALL",
  note: "Note",
};

const OPTIONS_CONTACT = {
  STAGE: [
    {
      label: "New",
      value: "1",
    },
    {
      label: "Contacted",
      value: "2",
    },
    {
      label: "Nurture",
      value: "3",
    },
    {
      label: "Appointment Set",
      value: "4",
    },
    {
      label: "Appointment Follow Up",
      value: "5",
    },
    {
      label: "Contract Sent",
      value: "6",
    },
    {
      label: "Negotiation",
      value: "7",
    },
    {
      label: "Deal Won",
      value: "8",
    },
    {
      label: "Deal Lost - Poor contact information",
      value: "9",
    },
    {
      label: "Deal Lost - Sold / Not for sale",
      value: "10",
    },
    {
      label: "Deal Lost - Lost to competitor",
      value: "11",
    },
    {
      label: "Deal Lost - No response",
      value: "12",
    },
    {
      label: "Deal Lost - Other",
      value: "13",
    },
  ],
  STATUS: [
    {
      label: "New",
      value: "1",
    },
    {
      label: "Contacted",
      value: "2",
    },
    {
      label: "Negotiation",
      value: "3",
    },
    {
      label: "Opportunity Won",
      value: "4",
    },
    {
      label: "Opportunity Lost",
      value: "5",
    },
  ],
};

export { IMAGES, POST_ACTIVITY, OPTIONS_CONTACT, BID_SIGNUP_STAGES, FAQ_URL };

export * as images from "./images";
