import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

interface DashboardPanelProps {
  /**
   * Header title of section
   */
  title?: string;
  /**
   * additional container classes
   */
  className?: string;
}

/**
 * Button
 */
export const DashboardPanel: React.FC<DashboardPanelProps> = ({
  title,
  className,
  children,
}) => {
  return (
    <div key={title} className={"panel " + className}>
      <h2 className="mb-6">{title}</h2>
      {children}
    </div>
  );
};
