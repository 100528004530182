import React from "react";
import { Typography, Progress, Row, Col, Button } from "antd";
import { SignupStageMeta } from "@types";
import { useNavigate } from "react-router";

const { Title, Text } = Typography;

interface IProps {
  totalSteps: number;
  stage: SignupStageMeta;
}

const SignupProgressBanner: React.FC<IProps> = ({ totalSteps, stage }) => {
  const navigate = useNavigate();
  const handleResume = () => {
    navigate(stage.path);
  };

  return (
    <Row className="py-4 bg-white border rounded" align="middle">
      <Col xs={24} md={{ span: 8, push: 1 }}>
        <Text strong>
          Onboarding Step {stage.step} of {totalSteps}
        </Text>
        <Progress
          className="stepper-progress"
          percent={(stage.step / totalSteps) * 100}
          status="active"
          showInfo={false}
        />
      </Col>
      <Col xs={{ span: 24, push: 0 }} md={{ span: 8, push: 2 }}>
        <Title level={5} style={{ marginBottom: 0 }}>
          You&apos;re almost there!
        </Title>
        <Text>Complete setting up your campaign to begin winning leads.</Text>
      </Col>
      <Col xs={{ span: 24, push: 0 }} md={{ span: 4, push: 3 }}>
        <Button type="primary" size="large" onClick={handleResume} block>
          Continue
        </Button>
      </Col>
    </Row>
  );
};

export default SignupProgressBanner;
