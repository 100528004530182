import React, { FC, useState } from "react";
import {
  Layout,
  Form,
  Space,
  FormInstance,
  Radio,
  Row,
  notification,
} from "antd";
import OnboardingLayout from "../../../component/OnboardingLayout";
import IntroductionsInfoModal from "../../../component/IntroductionsInfoModal";
import { ASMPopUp } from "@containers";

import "./SetSubscription.less";

import { useNavigate } from "react-router-dom";
import ContinueButtons from "../../../component/ContinueButtons";

import { SUBSCRIPTION_SIGNUP_STAGES } from "@constants";
import {
  useMarkSkipMutation,
  useSetCampaignSubscriptionMutation,
  useSignupSubscriptionOffers,
  useV2SignupSubscriptionCampaign,
} from "@queries";
import { SignupStage, SubscriptionOffer } from "@types";
import { LoadingScreen } from "@components";
import { getCityWithMostZipsFromCityState } from "@utils/campaign";
import { abbreviateCurrency } from "@utils/formatters";
import { BackToZipcodes } from "src/v2/component/BackToZipcodes";

const SetSubscription: FC = () => {
  const { Content } = Layout;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { offers, selected, averageEstimatedHomeValue } =
    useSignupSubscriptionOffers();
  const { zipCodes, cityStateToZipCodes } = useV2SignupSubscriptionCampaign();

  const [markSkip] = useMarkSkipMutation();
  const [setCampaignSubscription, { isLoading, error }] =
    useSetCampaignSubscriptionMutation();

  const [showModal, setShowModal] = useState(false);

  if (!offers) return <LoadingScreen />;

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setCampaignSubscription({ offerId: values?.offerId })
        .unwrap()
        .then((payload) => {
          if (
            payload?.stage !== SignupStage.AddPaymentInfo ||
            payload?.flashLevel ||
            payload?.flashMessage
          ) {
            notification.error({
              placement: "topRight",
              message: payload?.flashMessage,
            });
            setTimeout(() => window.location.reload(), 2000);
            window.location.reload();
            return;
          }
          navigate("/signup/payment/subscription", {
            state: { fromPreviousStage: true },
          });
        })
        .catch((error) => {
          if (error?.data?.offer?.[0].includes("Invalid pk")) {
            notification.error({
              placement: "topRight",
              message:
                "Your offer expired. Refreshing page to retrieve your new offers.",
            });
            setTimeout(() => window.location.reload(), 2000);
          } else {
            notification.error({
              placement: "topRight",
              message: "Sorry, there selecting your subscription.",
            });
          }
        });
    });
  };

  const handleSkip = () => {
    markSkip(SUBSCRIPTION_SIGNUP_STAGES.CAMPAIGN_SUBSCRIPTION.name);
    navigate("/");
  };

  const city =
    getCityWithMostZipsFromCityState(cityStateToZipCodes) ?? "Region";
  const numZipcodes = zipCodes?.split(",")?.length;

  return (
    <>
      <OnboardingLayout step={5}>
        <Content className="mt-8 space-y-4">
          <IntroductionsInfoModal
            visible={showModal}
            closeModal={() => setShowModal(false)}
          />

          <div>
            <div className="panel-header">
              <Row justify="space-between" className="px-4">
                <div className="flex flex-col">
                  <h2>{city || "Region"} </h2>
                  <div>
                    {numZipcodes} zip code
                    {numZipcodes && numZipcodes > 1 && "s"}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div>Avg. home value:</div>
                  <div className="text-blue">
                    {averageEstimatedHomeValue
                      ? abbreviateCurrency(averageEstimatedHomeValue)
                      : "N/A"}
                  </div>
                </div>
              </Row>
            </div>
            <div className="flex justify-center panel-w-header">
              <SubscriptionForm
                form={form}
                offers={offers}
                selectedOfferId={selected?.offerId}
              />
            </div>
          </div>

          <ContinueButtons
            onSkip={handleSkip}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
          <ASMPopUp />
        </Content>
      </OnboardingLayout>

      <BackToZipcodes />
    </>
  );
};

interface SubscriptionFormProps {
  form: FormInstance;
  offers: SubscriptionOffer[];
  selectedOfferId?: number | null;
}

const SubscriptionForm = ({
  form,
  offers,
  selectedOfferId,
}: SubscriptionFormProps) => {
  const initialSelectedOffer =
    selectedOfferId || offers?.find(({ available }) => available)?.offerId;
  return (
    <Form
      key="subscription"
      form={form}
      name="subscriptionForm"
      className="pt-4"
      size="large"
      colon={false}
      initialValues={{ offerId: initialSelectedOffer }}
    >
      <Form.Item key="a" name="offerId">
        <Radio.Group>
          <Space direction="vertical">
            {offers?.map(
              ({
                offerId,
                expectedNumLeads,
                available,
                monthlyBudget,
                reasons,
              }) => (
                <Radio
                  value={offerId}
                  key={expectedNumLeads}
                  className="w-full p-4 border rounded-xl"
                  disabled={!available}
                >
                  <Row justify="space-between">
                    <div>{expectedNumLeads} introductions per month</div>
                    {available && (
                      <div className="pl-8 sm:pl-44 md:pl-80">
                        ${monthlyBudget}/mo
                      </div>
                    )}
                    {!available && (
                      <div className="w-full text-xs text-gray-600">
                        {
                          Object.entries(
                            reasons ?? {}
                          )?.[0]?.[1] /* Grab first error message in reasons */
                        }
                      </div>
                    )}
                  </Row>
                </Radio>
              )
            )}
          </Space>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

export default SetSubscription;
