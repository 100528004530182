export const formatPhonenumber = (phonenumber: string): string => {
  if (phonenumber.length === 12)
    return `${phonenumber.slice(0, 2)} (${phonenumber.slice(
      2,
      5
    )}) ${phonenumber.slice(5, 8)}-${phonenumber.slice(8, 12)}`;
  else if (phonenumber.length === 13)
    return `(${phonenumber.slice(0, 3)}) ${phonenumber.slice(
      3,
      6
    )}-${phonenumber.slice(6, 10)}`;
  else if (phonenumber.length === 10)
    return `(${phonenumber.slice(0, 3)}) ${phonenumber.slice(
      3,
      6
    )}-${phonenumber.slice(6, 10)}`;
  return phonenumber;
};

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
});
export const formatDateString = (date: string): string => {
  return dateFormatter.format(new Date(date));
};

/**
 * Give it a date string and it returns how many days, weeks, months, or years ago
 */
export const howLongAgo = (date: string): string => {
  const today = Date.now();
  const dateReceived = new Date(date).getTime();
  const timeAgo = new Date(today - dateReceived);

  const yearsAgo = timeAgo.getFullYear() - 1970;
  if (yearsAgo > 0) return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;

  const monthsAgo = timeAgo.getMonth();
  if (monthsAgo > 0) return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;

  const daysAgo = timeAgo.getDate();

  const weeksAgo = Math.floor(daysAgo / 7);
  if (weeksAgo > 0) return `${weeksAgo} week${weeksAgo > 1 ? "s" : ""} ago`;
  if (daysAgo > 3) return `${daysAgo} days ago`;

  return "New!";
};

const currencyAbbreviator = Intl.NumberFormat("en", { notation: "compact" });
export const abbreviateCurrency = (num: number): string => {
  return currencyAbbreviator.format(num);
};

const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatCurrency = (amount: number): string =>
  currencyFormat.format(amount);

export const stripeRowClasses = (
  index: number,
  baseClasses?: string
): string => {
  const isEven = index % 2 === 0;
  if (baseClasses)
    return isEven
      ? baseClasses + " bg-striped"
      : baseClasses + " bg-transparent";
  return isEven ? "bg-striped" : "bg-transparent";
};
