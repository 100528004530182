import { appApi } from "./api";
import { BalanceHistory, CreditCard, DollarString } from "@types";

export const paymentApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    activeCreditCard: build.query<CreditCard | null, void>({
      query: () => "/credit-cards/",
      transformResponse: (data: CreditCard[]) => {
        return data?.reduce((acc, curr) => (acc.id > curr.id ? acc : curr));
      },
      providesTags: ["CreditCard"],
    }),
    addCreditCard: build.mutation<unknown, { token: { id: string } }>({
      query: ({ token }) => ({
        url: "/credit-cards/",
        method: "POST",
        body: { token: token?.id },
      }),
      invalidatesTags: ["CreditCard"],
    }),
    addBalance: build.mutation<
      unknown,
      { amount: DollarString; creditCard: number }
    >({
      query: (data) => ({
        url: "/balance/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Agent"],
    }),
    balanceHistory: build.query<BalanceHistory[], void>({
      query: () => "/balance/",
    }),
  }),
});

export const {
  useActiveCreditCardQuery,
  useAddCreditCardMutation,
  useAddBalanceMutation,
  useBalanceHistoryQuery,
} = paymentApi;
