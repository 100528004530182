import React, { FC } from "react";
import "./OnboardingStepper.less";
import { Typography, Progress, Space } from "antd";

const { Title, Text } = Typography;

interface IProps {
  children?: React.ReactNode;
  step: number;
  totalSteps: number;
  title?: string;
}

const OnboardingStepper: FC<IProps> = ({ totalSteps, step, title }) => {
  return (
    <Space direction="vertical" size="small" className="stepper-container">
      <Text strong>
        Step {step} of {totalSteps}
      </Text>
      <Progress
        className="stepper-progress"
        percent={(step / totalSteps) * 100}
        status="active"
        showInfo={false}
      />
      {title && (
        <Title level={4} className="stepper-title">
          {title}
        </Title>
      )}
    </Space>
  );
};

export default OnboardingStepper;
