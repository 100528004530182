import { BID_SIGNUP_STAGES, SUBSCRIPTION_SIGNUP_STAGES } from "../constants";
import { SignupStageMeta, CampaignTypePath, SignupStage } from "@types";

interface matchStageParams {
  stageName?: string; // Stage name from backend
  path?: string; // URL Path of signup stage
  campaignTypePath?: CampaignTypePath;
}

export const matchStage = ({
  stageName,
  path,
  campaignTypePath,
}: matchStageParams): SignupStageMeta => {
  if (campaignTypePath === "Monthly Budget") {
    if (path) {
      return Object.entries(SUBSCRIPTION_SIGNUP_STAGES).reduce(
        (acc, [, stage]) => (stage.path == path ? stage : acc),
        <SignupStageMeta>{}
      );
    }
    return Object.entries(SUBSCRIPTION_SIGNUP_STAGES).reduce(
      (acc, [, stage]) => (stage.name == stageName ? stage : acc),
      <SignupStageMeta>{}
    );
  }
  if (path) {
    return Object.entries(BID_SIGNUP_STAGES).reduce(
      (acc, [, stage]) => (stage.path == path ? stage : acc),
      <SignupStageMeta>{}
    );
  }
  return Object.entries(BID_SIGNUP_STAGES).reduce(
    (acc, [, stage]) => (stage.name == stageName ? stage : acc),
    <SignupStageMeta>{}
  );
};

export const navigateToCurrentStage = ({
  stage,
  campaignTypePath,
  navigate,
}: {
  stage?: SignupStage;
  campaignTypePath?: CampaignTypePath;
  navigate?: (path: string) => void;
}): void => {
  if (!stage || !campaignTypePath || !navigate) return;
  const current = matchStage({ stageName: stage, campaignTypePath });
  console.log("Navigating to agent's current stage: ", current?.name);
  navigate(current?.path);
};
