import React, { FC } from "react";
import { Button, ButtonProps, Image } from "antd";

export const EditButton: FC<ButtonProps> = (buttonProps) => {
  return (
    <Button type="text" {...buttonProps}>
      <Image
        src="/images/Icons/SVGs/Edit-Gray.svg"
        alt=""
        preview={false}
        height={12}
        width={12}
      />
      &nbsp;&nbsp;Edit
    </Button>
  );
};
