import React from "react";
import { resetApiState } from "@queries";
// import { removeToken } from "@utils/auth";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeToken } from "@store/authSlice";

export const Logout = () => {
  const dispatch = useDispatch();

  localStorage.clear();
  dispatch(removeToken());
  dispatch(resetApiState());

  return <Navigate to="/login" />;
};
