/* eslint-disable react/display-name */
import React, { useMemo, useState, useCallback, useEffect } from "react";
import {
  Table,
  Pagination,
  Tooltip,
  Button,
  BackTop,
  Grid,
  Empty,
  Row,
} from "antd";
import { ContactStageTag } from "../ContactStageTag/ContactStageTag";
import { ContactStage, Contact } from "@types";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ContactsFilter, MaterialIcon } from "@components";
import { formatDistanceToNow } from "date-fns";
import { formatPhonenumber } from "@utils/formatters";

import "./ContactsList.less";
import { setIsActivityOpen } from "@store/contactsPaginationSlice";
import { useBoolean } from "ahooks";
import { useAppDispatch } from "@store/hooks";

const { useBreakpoint } = Grid;

interface ContactsListProps {
  /**
   * List of labels and values to display
   */
  data: Contact[] | undefined;
  /**
   * Whether to show loading spinner
   */
  isLoading: boolean;
  /**
   * Handler for a contact's onClick
   */
  onContactClick: (contactId: number) => void;
  /**
   * Total number of contacts (used for pagination)
   */
  totalContacts: number | undefined;
  /**
   * Current selected page
   */
  currentPage: number;
  /**
   * Handler for page change
   */
  onPageChange: (page: number) => void;
}

const nameColumn = {
  title: "Name",
  dataIndex: "firstName",
};

const contactInfoColumns = [
  {
    title: "Address",
    dataIndex: "propertyAddress",
    ellipsis: {
      showTitle: false,
    },
    render: (address: string) => (
      <Tooltip placement="topLeft" title={address}>
        {address}
      </Tooltip>
    ),
  },
  {
    title: "Phone",
    dataIndex: "primaryPhone",
    ellipsis: {
      showTitle: false,
    },
    render: (phone: string) => (
      <Tooltip placement="topLeft" title={phone}>
        {formatPhonenumber(phone)}
      </Tooltip>
    ),
    responsive: ["lg", "xl", "xxl"],
  },
  {
    title: "Email",
    dataIndex: "email",
    ellipsis: {
      showTitle: false,
    },
    render: (email: string) => (
      <Tooltip placement="topLeft" title={email}>
        {email}
      </Tooltip>
    ),
    responsive: ["lg", "xl", "xxl"],
  },
];

const dateColumn = {
  title: "Date Acquired",
  dataIndex: "created",
  responsive: ["md", "lg", "xl", "xxl"],
  render: (date: string) => new Date(date).toDateString(),
};

const contactStageColumn = {
  title: "Contact Stage",
  dataIndex: "leadStage",
  filters: [
    { text: ContactStage.New, value: ContactStage.New },
    { text: ContactStage.Nurturing, value: ContactStage.Nurturing },
    { text: ContactStage.Contacted, value: ContactStage.Contacted },
    { text: ContactStage.MetWith, value: ContactStage.MetWith },
    { text: ContactStage.DealMade, value: ContactStage.DealMade },
    { text: ContactStage.DealLost, value: ContactStage.DealLost },
  ],
  render: (stage: ContactStage) => (
    <ContactStageTag status={stage} className="w-full text-center" />
  ),
  responsive: ["md", "lg", "xl", "xxl"],
};

const collapsedColumns = [
  {
    title: "Name and Address",
    width: "100%",
    render: (_: unknown, row: Contact) => {
      const howLongAgo = useMemo(
        () => formatDistanceToNow(new Date(row?.created)),
        [row?.created]
      );
      return {
        children: (
          <div className="overflow-ellipsis min-w-min">
            <Row justify="space-between" align="middle">
              <div className="text-base font-bold text-black">
                {row?.firstName}
              </div>
              <ContactStageTag
                // subtle
                status={row?.leadStage}
                className="w-24"
              />
            </Row>
            <div className="text-black">{row?.propertyAddress}</div>
            <Row className="text-grays-third" align="middle">
              <MaterialIcon
                icon="schedule"
                className="mr-1 text-base align-middle"
              />
              {howLongAgo}
            </Row>
          </div>
        ),
      };
    },
  },
];

/**
 * Contacts Table with collapsed and expanded views. Meant to be displayed as a sidebar
 */
export const ContactsList: React.FC<ContactsListProps> = ({
  data,
  isLoading,
  onContactClick,
  totalContacts,
  currentPage,
  onPageChange,
}) => {
  const dispatch = useAppDispatch();
  const [expanded, { toggle, setFalse: closeExpanded }] = useBoolean(false);

  const allColumns = [
    nameColumn,
    ...contactInfoColumns,
    dateColumn,
    contactStageColumn,
  ];

  const toggleExpand = useCallback(() => {
    toggle();
    if (!expanded) dispatch(setIsActivityOpen(false));
  }, [expanded, toggle, dispatch, setIsActivityOpen]);

  const tableElement = () => {
    const table = document.getElementsByClassName("ant-table-body")[0];
    return table as HTMLElement;
  };

  const [selectedContact, setSelectedContact] = useState<number>(
    data?.[0]?.id ?? -1
  );

  const rowSelection = {
    selectedRowKeys: [selectedContact],
    onChange: (selectedRowKeys: React.Key[], selectedRows: Contact[]) => {
      console.log(
        `SelectedRowKeys: ${selectedRowKeys}, selectedRows: ${selectedRows}`
      );
    },
  };

  const handleRowClick = useCallback(
    (record) => {
      onContactClick(record.id);
      setSelectedContact(record.id);
      closeExpanded();
    },
    [onContactClick]
  );

  const { lg, xl } = useBreakpoint();
  const isSmallScreen = !xl && !lg;

  useEffect(() => {
    if (isSmallScreen) closeExpanded();
  }, [isSmallScreen]);

  return (
    <div
      className="sticky top-0 z-50 p-0 bg-white"
      style={expanded ? { width: "80vw" } : {}}
    >
      {data?.length !== 0 && (
        <>
          <BackTop
            className="absolute left-64 bottom-16"
            target={tableElement}
          />
          <BackTop />
        </>
      )}
      <div className="flex justify-between pt-8 pb-4 bg-white">
        <div className="ml-4 text-3xl">Contacts</div>
        {!isSmallScreen && (
          <Button
            type="text"
            onClick={toggleExpand}
            // TODO: blur and offset close button style={{ right: expanded ? -80 : 0 }}
          >
            {expanded ? (
              <CloseCircleOutlined className="text-4xl" />
            ) : (
              <span className="text-xl font-thin text-grays-second">
                Expand
                <MaterialIcon
                  icon="arrow_forward_ios"
                  className="align-middle"
                />
              </span>
            )}
          </Button>
        )}
      </div>

      {/* <div className="px-4 pb-1">
        <ContactsFilter />
      </div> */}
      <Row
        justify="end"
        className={`px-4 bg-white ${
          expanded ? "" : "border-b-2 border-gray-100"
        }`}
      >
        <div>{totalContacts} contacts</div>
      </Row>
      {data?.length !== 0 ? (
        <Table
          className="hide-selection transparent-headers"
          columns={expanded ? allColumns : collapsedColumns}
          dataSource={data}
          rowKey={(row) => row.id}
          rowClassName="whitespace-nowrap cursor-pointer text-base"
          tableLayout="fixed"
          sticky
          showHeader={expanded ? true : false}
          {...(!expanded && { scroll: { y: "80vh" } })}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
          pagination={false}
          rowSelection={rowSelection}
          loading={isLoading}
        />
      ) : (
        <Empty className="p-16" description="No contacts" />
      )}

      <div className="flex justify-center p-4 bg-white">
        <Pagination
          current={currentPage}
          onChange={(page) => {
            onPageChange(page);
            console.log("changing page first contact: ", data?.[0]?.id);
            if (!data?.[0]?.id) return;
            setSelectedContact(data?.[0]?.id);
          }}
          total={totalContacts}
          size="small"
          showLessItems
          showSizeChanger={false}
          pageSize={20}
        />
      </div>
    </div>
  );
};
