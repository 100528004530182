import React, { FC } from "react";
import "./ContactSection.less";

interface ContactSectionProps {
  /**
   * Detail Section Header TItle
   */
  title: string;
  /**
   * Detail Section Header Icon
   */
  icon?: React.ReactElement;
  // /**
  //  * List of labels and values to display
  //  */
  // data: { label: string; value: string; tooltip?: string }[] | [];
  /**
   * List of labels and values to display
   */
  children: React.ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const ContactSection: FC<ContactSectionProps> = ({
  title,
  icon,
  children,
}) => {
  return (
    <div className="w-full">
      <div className="my-2 text-xl text-black">
        <span className="mx-1">{icon}</span>
        {title}
      </div>
      {children}
    </div>
  );
};
