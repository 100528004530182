import React, { useCallback, useMemo } from "react";
import { Tag, Button, Row } from "antd";
import { Zipcode } from "@types";

interface ZipcodeSelectProps {
  /**
   * City and State of place
   */
  query?: string;
  /**
   * All selectable zipcodes from google query/city state query
   */
  zipcodeOptions: Zipcode[];
  /**
   * Zipcode
   */
  selectedZipcodes: Zipcode[];
  /**
   * handleTagChange
   */
  toggleZipcode: (zipcode: Zipcode) => void;
  /**
   * select all zipcodes from current query (zipcode options)
   */
  selectAll: () => void;
  /**
   * unselect all zipcodes from current query (zipcode options)
   */
  unselectAll: () => void;
  /**
   * unselect query
   */
  clearCurrentQuery: () => void;
}

const { CheckableTag } = Tag;

/**
 * Select zipcodes tags from a list
 */
export const ZipcodeSelect: React.FC<ZipcodeSelectProps> = ({
  query,
  zipcodeOptions,
  selectedZipcodes,
  toggleZipcode,
  selectAll,
  unselectAll,
  clearCurrentQuery,
}) => {
  const handleTagOnChange = useCallback(
    (zipcode: string, checked: any) => {
      toggleZipcode(zipcode);
    },
    [toggleZipcode]
  );

  const isOptionsEmpty = useMemo(
    () => zipcodeOptions?.length <= 0,
    [zipcodeOptions]
  );

  return (
    <div className="">
      <Row justify="space-between">
        <p className="text-lg font-semibold">{query}</p>
        {!isOptionsEmpty && (
          <Button type="text" onClick={clearCurrentQuery}>
            Done
          </Button>
        )}
      </Row>
      <div
        className={`p-4 my-2 border-2 rounded-lg space-y-4 ${
          isOptionsEmpty && "bg-gray-200"
        }`}
      >
        <div className="flex justify-center">
          <Button
            className="mx-2"
            onClick={unselectAll}
            disabled={isOptionsEmpty}
          >
            Unselect All
          </Button>
          <Button
            className="mx-2"
            onClick={selectAll}
            disabled={isOptionsEmpty}
          >
            Select All
          </Button>
        </div>
        <div className={"flex flex-wrap gap-2 justify-center"}>
          {isOptionsEmpty && (
            <div className="mx-auto">
              Enter the city where you want to receive introductions.
            </div>
          )}
          {zipcodeOptions.map((zipcode) => {
            const isChecked = selectedZipcodes.includes(zipcode);
            return (
              <CheckableTag
                className="px-2 py-1 text-black bg-transparent border-2 border-gray-200"
                style={{ borderColor: isChecked ? "#fab115" : "" }}
                key={zipcode}
                checked={isChecked}
                onChange={(checked) => handleTagOnChange(zipcode, checked)}
              >
                {zipcode}
              </CheckableTag>
            );
          })}
        </div>
      </div>
    </div>
  );
};
